import { useEffect, useRef, useState } from "react";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import FieldsButton from "../../../controls/fieldsButton.control";
import LoadTemplateModal from "./loadTemplate.modal";
import TextInput from "../../../controls/text.input";
import TextareaInput from "../../../controls/textarea.input";
import SelectInput from "../../../controls/select.input";
import MultiselectInput from "../../../controls/multiselect.input";
import FileInput from "../../../controls/file.input";
import { useMessage } from "../../../contexts/message.context";

export default () => {
  const userCtx = useUser();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [templates, setTemplates] = useState([]);
  const [showLoadTemplateModal, setShowLoadTemplateModal] = useState(false);
  const [houses, setHouses] = useState([]);
  const [isWorking, setIsWorking] = useState(false);
  const [attachmentTypes, setAttachmentTypes] = useState([]);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [houseIds, setHouseIds] = useState([]);
  const [attachmentTypeId, setAttachmentTypeId] = useState("");
  const [file, setFile] = useState();
  const [queueCount, setQueueCount] = useState(0);
  const subjectRef = useRef();
  const bodyRef = useRef();

  const refreshTemplates = async () => {
    try {
      const templates = await userCtx.webserviceClient.templates.getByColony(
        userCtx.user.colony._id
      );
      templates.sort((a, b) => a.name.localeCompare(b.name));
      setTemplates(templates);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const updateEmailQueueCount = async () => {
    const count = await userCtx.webserviceClient.emails.getQueueCount(
      userCtx.user.colony._id
    );
    setQueueCount(count);
  };

  useEffect(() => {
    const refresh = async () => {
      try {
        const houses = await userCtx.webserviceClient.houses.getByColony(
          userCtx.user.colony._id
        );
        houses.sort((a, b) => a.address.localeCompare(b.address));
        setHouses(houses);

        await updateEmailQueueCount();

        const attachmentTypes =
          await userCtx.webserviceClient.emails.getAttachmentTypes();
        setAttachmentTypes(attachmentTypes);
        if (attachmentTypes.length > 0) {
          setAttachmentTypeId(attachmentTypes[0].id);
        }
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };

    refreshTemplates();
    refresh();
  }, [userCtx.user]);

  const attachmentType = attachmentTypes.find((t) => t.id === attachmentTypeId);

  const handleSendClicked = async () => {
    try {
      setIsWorking(true);
      const attachmentType = attachmentTypes.find(
        (t) => t.id === attachmentTypeId
      );

      const count = await userCtx.webserviceClient.emails.send(
        userCtx.user.colony._id,
        houseIds,
        subject,
        body,
        attachmentTypeId,
        attachmentType?.showFilePicker ? file : null
      );

      setIsWorking(false);
      messageCtx.showSuccess(
        count === 1
          ? `${count} correo está siendo enviado`
          : `${count} correos están siendo enviados`
      );

      updateEmailQueueCount();
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const isSendFormOk = () => {
    if (subject.length === 0) return false;
    if (body.length === 0) return false;
    if (houseIds.length === 0) return false;

    if (attachmentType?.showFilePicker) {
      if (!file) return false;
      if (file.size > 10000000) return false;
    }

    return true;
  };

  return (
    <div>
      <h1>Correos</h1>
      <div>
        <button
          className="btn btn-primary"
          onClick={() => setShowLoadTemplateModal(true)}
        >
          Cargar
        </button>
      </div>

      <div className="mt-2">
        <label className="form-label">
          Asunto
          <FieldsButton
            element={subjectRef}
            setter={setSubject}
            disabled={isWorking}
          />
        </label>
        <TextInput
          ref={subjectRef}
          value={subject}
          disabled={isWorking}
          onChange={setSubject}
        />
      </div>

      <div className="mt-2">
        <label className="form-label">
          Mensaje
          <FieldsButton
            element={bodyRef}
            setter={setBody}
            disabled={isWorking}
          />
        </label>
        <TextareaInput
          ref={bodyRef}
          rows={6}
          value={body}
          disabled={isWorking}
          onChange={setBody}
        />
      </div>

      <div className="mt-2">
        <label className="form-label">Adjuntar archivo</label>
        <SelectInput
          items={attachmentTypes}
          valueExtractor={(t) => t.id}
          nameExtractor={(t) => t.name}
          value={attachmentTypeId}
          onChange={setAttachmentTypeId}
        />
      </div>

      {attachmentType?.showFilePicker && (
        <div className="mt-2">
          <label className="form-label">Archivo</label>
          <FileInput
            value={file}
            onChange={setFile}
            maxSize={5000000}
            accept=".xlsx,.pdf"
            allowEmpty={true}
            showSeeImageButton={false}
            disabled={isWorking}
          />
        </div>
      )}

      <div className="mt-2">
        <label className="form-label">
          Casas ({houseIds.length} / {houses.length})
        </label>
        <MultiselectInput
          items={houses}
          nameExtractor={(h) => h.address}
          valueExtractor={(h) => h._id}
          disabled={isWorking}
          values={houseIds}
          onChange={setHouseIds}
        />
      </div>

      <div className="mt-2">
        <button
          className="btn btn-primary"
          onClick={handleSendClicked}
          disabled={!isSendFormOk() || isWorking}
        >
          Enviar
        </button>
        <span className="mx-2">Correos en fila: {queueCount}</span>
      </div>
      <LoadTemplateModal
        templates={templates}
        setTemplates={setTemplates}
        show={showLoadTemplateModal}
        setShow={setShowLoadTemplateModal}
        onSelect={(template) => {
          setSubject(template.subject);
          setBody(template.body);
          if (attachmentTypes.find((t) => t.id === template.attachmentTypeId)) {
            setAttachmentTypeId(template.attachmentTypeId);
          } else {
            setAttachmentTypeId(attachmentTypes[0].id);
          }
        }}
      />
    </div>
  );
};
