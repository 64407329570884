import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import TableControl from "../../../controls/table.control";

const UsersPage = () => {
  const navigate = useNavigate();

  const userCtx = useUser(); // user
  const exceptionCtx = useException();

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const refresh = async () => {
      try {
        const roles = await userCtx.webserviceClient.roles.getByColony(
          userCtx.user.colony._id
        );
        setRoles(roles);
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };

    refresh();
  }, [userCtx.user]);

  const handleRowPressed = (role) => {
    navigate(role._id.toString());
  };

  const handleNewRoleClicked = () => {
    navigate("./nuevo"); // , { replace: true }};};
  };

  return (
    <div>
      <h1>Roles</h1>
      {userCtx.user.role.permissions.includes("roles.edit") && (
        <div>
          <button className="btn btn-primary" onClick={handleNewRoleClicked}>
            Nuevo
          </button>
        </div>
      )}
      <TableControl
        rows={roles}
        columns={[{ header: "Nombre", valueExtractor: (r) => r.name }]}
        keyExtractor={(r) => r._id}
        onRowClicked={handleRowPressed}
      />
    </div>
  );
};

export default UsersPage;
