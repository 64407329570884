export default ({
  readonly,
  values,
  disabled,
  onChange,
  items,
  valueExtractor,
  nameExtractor,
}) => {
  function getSelectValues(select) {
    var result = [];
    var options = select && select.options;
    var opt;

    for (var i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        result.push(opt.value || opt.text);
      }
    }
    return result;
  }

  if (readonly) {
    return (
      <label className="form-control-plaintext">
        {values.map((v) => (
          <div>{nameExtractor(items.find((i) => valueExtractor(i) === v))}</div>
        ))}
      </label>
    );
  } else {
    return (
      <select
        className="form-select"
        disabled={disabled}
        onChange={(e) => onChange(getSelectValues(e.target), e)}
        multiple
      >
        {items.map((i) => (
          <option
            key={valueExtractor(i)}
            value={valueExtractor(i)}
            selected={values.includes(valueExtractor(i))}
          >
            {nameExtractor(i)}
          </option>
        ))}
      </select>
    );
  }
};
