import Modal from "react-bootstrap/Modal";

export default ({ show, setShow, images, index = 0 }) => {
  const getFileViewer = () => {
    if (show && images.length && images[index]) {
      const file = images[index];

      if (file.name.toLowerCase().endsWith(".pdf")) {
        return <iframe className="w-100 h-100" src={file.url}></iframe>;
        // return (
        //   <div className="w-100 h-100 d-flex justify-content-center align-items-center flex-column">
        //     <div>No se puede visualizar archivo</div>
        //     <div>
        //       <a href={file.url}>Ver de otro manera</a>
        //     </div>
        //   </div>
        // );
      } else {
        return (
          <img
            src={file.url}
            alt={file.name}
            className="w-100 h-100"
            style={{ objectFit: "contain" }}
          />
        );
      }
    }

    return null;
  };
  return (
    <Modal fullscreen show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Ver</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-fullscreen">
        {getFileViewer()}
      </Modal.Body>
    </Modal>
  );
};
