export default ({ readonly, value, disabled, onChange }) => {
  if (readonly) {
    return (
      <label className="form-control-plaintext">
        <div
          style={{
            backgroundColor: value,
            width: 20,
            height: 20,
            borderRadius: 5,
          }}
        ></div>
      </label>
    );
  } else {
    return (
      <input
        type="color"
        className="form-control form-control-color"
        value={value}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value, e)}
      />
    );
  }
};
