import { createContext, useContext, useEffect, useState } from "react";
import localStorageUtil from "../../utils/localStorage.util";
import WebserviceClient from "../../utils/webservice.client";
import { useMessage } from "./message.context";

const webserviceClient = new WebserviceClient();
const userContext = createContext();

export const useUser = () => {
  return useContext(userContext);
};

export const UserContext = ({ children }) => {
  const messageCtx = useMessage();

  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const refresh = async () => {
      try {
        const token = localStorageUtil.get("token");

        if (token) {
          const auth = await webserviceClient.auth.loginByToken(token);

          if (auth) {
            setUser(auth);
            setToken(auth.token);
          }
        }
      } catch (ex) {}

      setIsLoading(false);
    };

    refresh();
  }, []);

  const logout = async () => {
    await webserviceClient.auth.logout();

    setUser();
    setToken();
  };

  const loginByCredentials = async (email, password) => {
    const auth = await webserviceClient.auth.loginByCredentials(
      email,
      password
    );

    setUser(auth);
    setToken(auth.token);
  };

  const setToken = (token, remember) => {
    if (token) {
      if (remember) {
        localStorageUtil.set("token", token, Infinity);
      } else {
        localStorageUtil.set("token", token);
      }
    } else {
      localStorageUtil.remove("token");
    }
  };

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  return (
    <userContext.Provider
      value={{ user, setUser, logout, loginByCredentials, webserviceClient }}
    >
      {children}
    </userContext.Provider>
  );
};
