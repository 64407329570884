import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import TableControl from "../controls/table.control";
import SelectInput from "../controls/select.input";

const fieldCategories = [
  {
    header: "General",
    fields: [
      { name: "Mes", id: "Mes" },
      { name: "Mes pasado", id: "Mes pasado" },
      { name: "Fecha", id: "Fecha" },
      { name: "Año", id: "Año" },
      { name: "Pagina", id: "Pagina" },
    ],
  },
  {
    header: "Casa",
    fields: [
      { name: "Direccion", id: "Direccion" },
      { name: "Referencia bancaria", id: "Referencia bancaria" },
      { name: "Mantenimiento", id: "Mantenimiento" },
      { name: "Saldo", id: "Saldo" },
    ],
  },
  {
    header: "Colonia",
    fields: [{ name: "Correo", id: "Correo" }],
  },
  {
    header: "Contacto",
    fields: [
      { name: "Nombre", id: "Contacto nombre" },
      { name: "Correo", id: "Contacto correo" },
      { name: "Contraseña", id: "Contacto contraseña" },
    ],
  },
];

const FieldsButton = ({ element, setter, disabled }) => {
  const [showFieldsModal, setShowFieldsModal] = useState(false);
  const [fieldCategoryHeader, setFieldCategoryHeader] = useState(
    fieldCategories[0].header
  );

  const insertAtCursor = (textToInsert) => {
    const input = element.current;

    // get current text of the input
    const value = input.value;

    // save selection start and end position
    const start = input.selectionStart;
    const end = input.selectionEnd;

    // update the value with our text inserted
    input.value = value.slice(0, start) + textToInsert + value.slice(end);
    input.disabled = disabled;
    // update cursor to be at the end of insertion
    input.selectionStart = input.selectionEnd = start + textToInsert.length;
    setter(input.value);
  };

  const handleFieldClicked = (field) => {
    insertAtCursor("[" + field.id + "]");
    setShowFieldsModal(false);
  };

  const fieldCategory = fieldCategories.find(
    (c) => c.header === fieldCategoryHeader
  );

  return (
    <>
      <button
        className="btn btn-link btn-sm"
        style={{ lineHeight: 1 }}
        onClick={() => setShowFieldsModal(true)}
      >
        <FontAwesomeIcon icon={faPlusCircle} className="color-primary" />
      </button>
      <Modal show={showFieldsModal} onHide={() => setShowFieldsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Insertar campo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label">Categoria</label>
          <SelectInput
            items={fieldCategories}
            valueExtractor={(c) => c.header}
            nameExtractor={(c) => c.header}
            value={fieldCategoryHeader}
            onChange={setFieldCategoryHeader}
          />
          <div className="mt-3">
            <TableControl
              rows={fieldCategory.fields}
              columns={[{ header: "Campo", valueExtractor: (f) => f.name }]}
              keyExtractor={(c) => c.id}
              onRowClicked={handleFieldClicked}
            />
          </div>
        </Modal.Body>
      </Modal>
      {/* <ul className="dropdown-menu">
        {items.map((i) => {
          return (
            <li key={i.id}>
              <button
                type="button"
                className={`dropdown-item ${i.header ? "disabled" : ""}`}
                onClick={() => insertAtCursor("[" + i.id + "]")}
              >
                {i.name}
              </button>
            </li>
          );
        })}
      </ul> */}
    </>
  );
};

export default FieldsButton;
