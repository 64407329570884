import React, { useState } from "react";
import FileViewerModal from "../controls/fileViewer.modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faImage,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useException } from "../contexts/exception.context";

export default ({
  value,
  onChange,
  readonly,
  disabled,
  accept,
  allowEmpty,
  maxSize,
  showSeeImageButton,
}) => {
  const { showError } = useException();
  const [showImageViewerModal, setShowImageViewerModal] = useState(false);

  const handleFileChanged = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      if (maxSize && file.size > maxSize) {
        showError("Tamaño de archivo demasiado grande");
        return;
      }
      onChange(file);
    }
  };

  const handleDeleteFileClicked = () => {
    onChange();
  };

  const handleShowImageClicked = () => {
    setShowImageViewerModal(true);
  };

  if (readonly) {
    return (
      <>
        <div className="form-control-plaintext">
          <button className="btn btn-link p-0" onClick={handleShowImageClicked}>
            Ver
          </button>
        </div>
        <FileViewerModal
          show={showImageViewerModal}
          setShow={setShowImageViewerModal}
          images={[value]}
        />
      </>
    );
  } else {
    return (
      <>
        <div className="input-group">
          <input
            className="form-control"
            value={value?.name || ""}
            disabled={true}
          ></input>
          <label className="btn btn-primary">
            <input
              disabled={disabled}
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChanged}
              accept={accept}
              onClick={(e) => (e.target.value = "")}
            />
            <FontAwesomeIcon icon={faEllipsisH} />
          </label>
          {value && showSeeImageButton && (
            <button
              className="btn btn-success"
              onClick={handleShowImageClicked}
            >
              <FontAwesomeIcon icon={faImage} />
            </button>
          )}
          {value && allowEmpty && (
            <button
              className="btn btn-danger"
              onClick={handleDeleteFileClicked}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          )}
        </div>
        <FileViewerModal
          show={showImageViewerModal}
          setShow={setShowImageViewerModal}
          images={[value]}
        />
      </>
    );
  }
};
