import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

import validateUtil from "../../../../utils/validate.util";
import arrayUtil from "../../../../utils/array.util";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import DateInput from "../../../controls/date.input";
import SelectInput from "../../../controls/select.input";
import TextInput from "../../../controls/text.input";
import NumberInput from "../../../controls/number.input";
import { useMessage } from "../../../contexts/message.context";

const NewTransactionColonyAdjustmentControl = ({
  colony,
  accounts,
  isWorking,
  setIsWorking,
}) => {
  const navigate = useNavigate();

  const userCtx = useUser();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [colonyAccounts, setColonyAccounts] = useState([]);
  const [colonyAccountId, setColonyAccountId] = useState();
  const [description, setDescription] = useState("");
  const [date, setDate] = useState(DateTime.now().toISODate());
  const [amount, setAmount] = useState("0");
  const [verifyResult, setVerifyResult] = useState();

  useEffect(() => {
    const refresh = async () => {
      try {
        const colonyAccounts = accounts
          .filter((a) => a.type === "Colonia")
          .sort((a, b) => a.name.localeCompare(b.name));

        setColonyAccounts(colonyAccounts);
        if (colonyAccounts.length > 0) {
          const latest = colonyAccounts.reduce((l, e) =>
            e.transactionCount > l.transactionCount ? e : l
          );
          setColonyAccountId(latest._id);
        }
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };

    refresh();
  }, [accounts]);

  const handleCreateClicked = async () => {
    try {
      setIsWorking(true);
      await userCtx.webserviceClient.colonyTransactions.insertColonyAdjustment(
        userCtx.user.colony._id,
        colonyAccountId,
        date,
        description,
        amount
      );
      messageCtx.showSuccess("Movimiento guardado");
      navigate("./..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const handleVerifyClicked = async () => {
    try {
      setVerifyResult();
      const result =
        await userCtx.webserviceClient.colonyTransactions.verifyColonyAdjustment(
          userCtx.user.colony._id,
          colonyAccountId,
          date,
          description,
          amount
        );
      setVerifyResult(result);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const isFormOk = () => {
    if (!description) return false;
    if (!validateUtil.number.isCurrency(amount, 2)) return false;
    if (parseFloat(amount) === 0) return false;
    if (!colonyAccountId) return false;

    return true;
  };

  const lastEvent = arrayUtil.last(colony.events);
  const minDate = lastEvent ? lastEvent.date : "";

  return (
    <div>
      <div className="mb-2">
        <div className="form-label">Fecha</div>
        <DateInput
          max={DateTime.now().toISODate()}
          min={minDate}
          value={date}
          disabled={isWorking}
          onChange={setDate}
        />
      </div>

      <div className="mb-2">
        <div className="form-label">Cuenta de colonia</div>
        <SelectInput
          items={colonyAccounts}
          nameExtractor={(a) => a.name}
          valueExtractor={(a) => a._id}
          value={colonyAccountId}
          disabled={isWorking}
          onChange={setColonyAccountId}
        />
      </div>

      <div className="mb-2">
        <div className="form-label">Concepto</div>
        <TextInput
          value={description}
          disabled={isWorking}
          onChange={setDescription}
        />
      </div>

      <div className="mb-2">
        <div className="form-label">Cantidad</div>
        <NumberInput value={amount} onChange={setAmount} disabled={isWorking} />
      </div>

      <div className="mb-2">
        <button
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          onClick={handleVerifyClicked}
          disabled={!isFormOk() || isWorking}
        >
          Verificar
        </button>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Verificar
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                disabled={isWorking}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {verifyResult && (
                <div>
                  <div>Antes: {verifyResult.before.toFixed(2)}</div>
                  <div>Despues: {verifyResult.after.toFixed(2)}</div>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                disabled={isWorking}
                onClick={handleCreateClicked}
              >
                Crear
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                disabled={isWorking}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewTransactionColonyAdjustmentControl;
