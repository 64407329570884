import { DateTime } from "luxon";

export class ServerError extends Error {
  constructor(message, data) {
    super(message);
    this.data = data;
  }
}

class WebserviceClient {
  #token = null;

  #handleResponse = async (response) => {
    if (response.ok) {
      const response2 = await response.json();
      return response2;
    } else {
      const response2 = await response.json();

      throw new ServerError(response2.error, response2.data);
    }
  };

  #post = async (url, authenticate, data) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (authenticate) {
      options.headers.Authorization = "Bearer " + this.#token;
    }

    if (data) {
      options.body = JSON.stringify(data);
    }

    const response = await fetch(`/api/v1${url}`, options);

    const result = await this.#handleResponse(response);

    return result;
  };

  #postFormData = async (url, authenticate, file, data) => {
    var payload = new FormData();

    if (file) {
      payload.append("file", file);
    }

    if (data) {
      payload.append("data", JSON.stringify(data));
    }

    const options = {
      method: "POST",
      body: payload,
    };

    if (authenticate) {
      options.headers = {
        Authorization: "Bearer " + this.#token,
      };
    }

    const response = await fetch(`/api/v1${url}`, options);

    const data2 = await this.#handleResponse(response);

    return data2;
  };

  getToken = () => {
    return this.#token;
  };

  jobs = {
    getById: async (jobId) => {
      const job = await this.#post("/jobs/getById", true, {
        jobId,
      });

      return job;
    },
    updateEnabled: async (jobId, enabled) => {
      await this.#post("/jobs/updateEnabled", true, {
        jobId,
        enabled,
      });
    },
    getLatest: async (amount) => {
      const jobs = await this.#post("/jobs/getLatest", true, {
        amount,
      });

      return jobs;
    },
    runNowById: async (jobId) => {
      const job = await this.#post("/jobs/runNowById", true, {
        jobId,
      });

      return job;
    },
  };

  auth = {
    loginByCredentials: async (email, password) => {
      const auth = await this.#post("/auth/loginByCredentials", false, {
        email,
        password,
      });

      this.#token = auth.token;

      return auth;
    },
    loginByToken: async (token) => {
      const auth = await this.#post("/auth/loginByToken", false, { token });

      this.#token = token;

      return auth;
    },
    logout: async () => {
      this.#token = undefined;
    },
  };

  roles = {
    getPermissions: async () => {
      const permissions = await this.#post("/roles/getPermissions", true);

      return permissions;
    },
    getByColony: async (colonyId) => {
      const roles = await this.#post("/roles/getByColony", true, {
        colonyId,
      });

      return roles;
    },
    getByColonyAndLevel: async (colonyId, level) => {
      const roles = await this.#post("/roles/getByColonyAndLevel", true, {
        colonyId,
        level,
      });

      return roles;
    },
    getById: async (colonyId, roleId) => {
      const role = await this.#post("/roles/getById", true, {
        colonyId,
        roleId,
      });

      return role;
    },
    insert: async (colonyId, name, permissions) => {
      const role = await this.#post("/roles/insert", true, {
        colonyId,
        name,
        permissions,
      });

      return role;
    },
    delete: async (colonyId, roleId) => {
      await this.#post("/roles/delete", true, {
        colonyId,
        roleId,
      });
    },
    update: async (colonyId, roleId, name, permissions) => {
      const role = await this.#post("/roles/update", true, {
        colonyId,
        roleId,
        name,
        permissions,
      });

      return role;
    },
  };

  clientAccounts = {
    getTypes: async () => {
      const types = await this.#post("/clientAccounts/getTypes", true);

      return types;
    },
    getById: async (colonyId, accountId) => {
      const account = await this.#post("/clientAccounts/getById", true, {
        colonyId,
        accountId,
      });

      return account;
    },
    getByColony: async (colonyId) => {
      const accounts = await this.#post("/clientAccounts/getByColony", true, {
        colonyId,
      });

      return accounts;
    },
    getByColonyAndType: async (colonyId, type) => {
      const accounts = await this.#post(
        "/clientAccounts/getByColonyAndType",
        true,
        {
          colonyId,
          type,
        }
      );

      return accounts;
    },
    insert: async (colonyId, type, name) => {
      const account = await this.#post("/clientAccounts/insert", true, {
        colonyId,
        type,
        name,
      });

      return account;
    },
    delete: async (colonyId, accountId) => {
      const account = await this.#post("/clientAccounts/delete", true, {
        colonyId,
        accountId,
      });

      return account;
    },
    getBalance: async (colonyId, accountId, date) => {
      const info = await this.#post("/clientAccounts/getBalance", true, {
        colonyId,
        accountId,
        date,
      });

      return info;
    },
    getBalanceByTypeAndDate: async (colonyId, accountType, date) => {
      const info = await this.#post(
        "/clientAccounts/getBalanceByTypeAndDate",
        true,
        {
          colonyId,
          accountType,
          date,
        }
      );

      return info;
    },
    getBalanceByType: async (colonyId, accountType) => {
      const info = await this.#post("/clientAccounts/getBalanceByType", true, {
        colonyId,
        accountType,
      });

      return info;
    },
  };

  colonyAccounts = {
    getTypes: async () => {
      const types = await this.#post("/colonyAccounts/getTypes", true);

      return types;
    },
    getById: async (colonyId, accountId) => {
      const account = await this.#post("/colonyAccounts/getById", true, {
        colonyId,
        accountId,
      });

      return account;
    },
    getByColony: async (colonyId) => {
      const accounts = await this.#post("/colonyAccounts/getByColony", true, {
        colonyId,
      });

      return accounts;
    },
    getByColonyAndType: async (colonyId, type) => {
      const accounts = await this.#post(
        "/colonyAccounts/getByColonyAndType",
        true,
        {
          colonyId,
          type,
        }
      );

      return accounts;
    },
    insert: async (colonyId, type, name) => {
      const account = await this.#post("/colonyAccounts/insert", true, {
        colonyId,
        type,
        name,
      });

      return account;
    },
    update: async (accountId, name) => {
      const account = await this.#post("/colonyAccounts/update", true, {
        accountId,
        name,
      });

      return account;
    },
    delete: async (colonyId, accountId) => {
      const account = await this.#post("/colonyAccounts/delete", true, {
        colonyId,
        accountId,
      });

      return account;
    },
    getBalance: async (colonyId, accountId, date) => {
      const info = await this.#post("/colonyAccounts/getBalance", true, {
        colonyId,
        accountId,
        date,
      });

      return info;
    },
    getBalanceByTypeAndDate: async (colonyId, accountType, date) => {
      const info = await this.#post(
        "/colonyAccounts/getBalanceByTypeAndDate",
        true,
        {
          colonyId,
          accountType,
          date,
        }
      );

      return info;
    },
    getBalanceByType: async (colonyId, accountType) => {
      const info = await this.#post("/colonyAccounts/getBalanceByType", true, {
        colonyId,
        accountType,
      });

      return info;
    },
  };

  houses = {
    getById: async (colonyId, houseId) => {
      const house = await this.#post("/houses/getById", true, {
        colonyId,
        houseId,
      });

      return house;
    },

    getByColony: async (colonyId) => {
      const houses = await this.#post("/houses/getByColony", true, {
        colonyId,
      });

      return houses;
    },

    update: async (
      colonyId,
      houseId,
      maintenance,
      bankReference,
      habitantType,
      note,
      contacts
    ) => {
      const house = await this.#post("/houses/update", true, {
        colonyId,
        houseId,
        maintenance,
        bankReference,
        habitantType,
        note,
        contacts,
      });

      return house;
    },

    getHabitantTypes: async () => {
      const habitantTypes = await this.#post("/houses/getHabitantTypes", true);

      return habitantTypes;
    },

    updateContactPassword: async (colonyId, houseId, contactId) => {
      await this.#post("/houses/updateContactPassword", true, {
        colonyId,
        houseId,
        contactId,
      });
    },

    getEventTypes: async () => {
      const types = await this.#post("/houses/getEventTypes", true);

      return types;
    },
    getEvents: async (colonyId, houseId) => {
      const events = await this.#post("/houses/getEvents", true, {
        colonyId,
        houseId,
      });

      return events;
    },
    getEventById: async (colonyId, houseId, eventId) => {
      const event = await this.#post("/houses/getEventById", true, {
        colonyId,
        houseId,
        eventId,
      });

      return event;
    },
    updateEvent: async (
      colonyId,
      houseId,
      eventId,
      type,
      date,
      description
    ) => {
      const event = await this.#post("/houses/updateEvent", true, {
        colonyId,
        houseId,
        eventId,
        type,
        date,
        description,
      });

      return event;
    },
    insertEvent: async (colonyId, houseId, type, date, description) => {
      const event = await this.#post("/houses/insertEvent", true, {
        colonyId,
        houseId,
        type,
        date,
        description,
      });

      return event;
    },
    // deleteEvent: async (colonyId, houseId, eventId) => {
    //   await this.#post("/houses/deleteEvent", true, {
    //     colonyId,
    //     houseId,
    //     eventId,
    //   });
    // },

    // insertEvent: async (colonyId, houseId, type, date, description) => {
    //   await this.#post("/houses/insertEvent", true, {
    //     colonyId,
    //     houseId,
    //     type,
    //     date,
    //     description,
    //   });
    // },

    deleteEvent: async (colonyId, houseId, eventId) => {
      await this.#post("/houses/deleteEvent", true, {
        colonyId,
        houseId,
        eventId,
      });
    },
    getContactById: async (colonyId, houseId, contactId) => {
      const contact = await this.#post("/houses/getContactById", true, {
        colonyId,
        houseId,
        contactId,
      });

      return contact;
    },
    insertContact: async (colonyId, houseId, name, emails, phones, note) => {
      const contact = await this.#post("/houses/insertContact", true, {
        colonyId,
        houseId,
        name,
        emails,
        phones,
        note,
      });

      return contact;
    },
    updateContact: async (
      colonyId,
      houseId,
      contactId,
      name,
      emails,
      phones,
      note
    ) => {
      const contact = await this.#post("/houses/updateContact", true, {
        colonyId,
        houseId,
        contactId,
        name,
        emails,
        phones,
        note,
      });

      return contact;
    },

    deleteContact: async (colonyId, houseId, contactId) => {
      await this.#post("/houses/deleteContact", true, {
        colonyId,
        houseId,
        contactId,
      });
    },
  };

  colonyTransactions = {
    getTypes: async () => {
      const types = await this.#post("/colonyTransactions/getTypes", true);

      return types;
    },
    getById: async (colonyId, transactionId) => {
      const account = await this.#post("/colonyTransactions/getById", true, {
        colonyId,
        transactionId,
      });

      return account;
    },
    getByColony: async (colonyId, month) => {
      const transactions = await this.#post(
        "/colonyTransactions/getByColony",
        true,
        {
          colonyId,
          month,
        }
      );

      return transactions;
    },
    insertLoan: async (
      colonyId,
      houseAccountId,
      colonyAccountId,
      payDate,
      description,
      amount
    ) => {
      const transaction = await this.#post(
        "/colonyTransactions/insertLoan",
        true,
        {
          colonyId,
          houseAccountId,
          colonyAccountId,
          payDate,
          description,
          amount,
        }
      );
      return transaction;
    },
    insertPayment: async (
      colonyId,
      houseAccountId,
      colonyAccountId,
      payDate,
      description,
      amount
    ) => {
      const transaction = await this.#post(
        "/colonyTransactions/insertPayment",
        true,
        {
          colonyId,
          houseAccountId,
          colonyAccountId,
          payDate,
          description,
          amount,
        }
      );
      return transaction;
    },
    verifyHouseAdjustment: async (
      colonyId,
      houseAccountId,
      payDate,
      description,
      amount
    ) => {
      const result = await this.#post(
        "/colonyTransactions/verifyHouseAdjustment",
        true,
        {
          colonyId,
          houseAccountId,
          payDate,
          description,
          amount,
        }
      );
      return result;
    },
    insertHouseAdjustment: async (
      colonyId,
      houseAccountId,
      payDate,
      description,
      amount
    ) => {
      const transaction = await this.#post(
        "/colonyTransactions/insertHouseAdjustment",
        true,
        {
          colonyId,
          houseAccountId,
          payDate,
          description,
          amount,
        }
      );
      return transaction;
    },
    verifyColonyAdjustment: async (
      colonyId,
      colonyAccountId,
      payDate,
      description,
      amount
    ) => {
      const result = await this.#post(
        "/colonyTransactions/verifyColonyAdjustment",
        true,
        {
          colonyId,
          colonyAccountId,
          payDate,
          description,
          amount,
        }
      );
      return result;
    },
    insertColonyAdjustment: async (
      colonyId,
      colonyAccountId,
      payDate,
      description,
      amount
    ) => {
      const transaction = await this.#post(
        "/colonyTransactions/insertColonyAdjustment",
        true,
        {
          colonyId,
          colonyAccountId,
          payDate,
          description,
          amount,
        }
      );
      return transaction;
    },
    insertCharge: async (
      colonyId,
      houseAccountId,
      chargeAccountId,
      payDate,
      description,
      amount
    ) => {
      const transaction = await this.#post(
        "/colonyTransactions/insertCharge",
        true,
        {
          colonyId,
          houseAccountId,
          chargeAccountId,
          payDate,
          description,
          amount,
        }
      );
      return transaction;
    },
    insertTransfer: async (
      colonyId,
      fromColonyAccountId,
      toColonyAccountId,
      payDate,
      description,
      amount
    ) => {
      const transaction = await this.#post(
        "/colonyTransactions/insertTransfer",
        true,
        {
          colonyId,
          fromColonyAccountId,
          toColonyAccountId,
          payDate,
          description,
          amount,
        }
      );
      return transaction;
    },
    insertChargesByArchive: async (
      colonyId,
      chargeAccountId,
      payDate,
      description,
      file
    ) => {
      const data = { colonyId, chargeAccountId, payDate, description };

      const result = await this.#postFormData(
        "/colonyTransactions/insertChargesByArchive",
        true,
        file,
        data
      );

      return result;
    },
    insertChargesByGui: async (
      colonyId,
      houseAccountIds,
      chargeAccountId,
      payDate,
      description,
      amount
    ) => {
      const result = await this.#post(
        "/colonyTransactions/insertChargesByGui",
        true,
        {
          colonyId,
          houseAccountIds,
          chargeAccountId,
          payDate,
          description,
          amount,
        }
      );

      return result;
    },
    insertIncome: async (
      colonyId,
      incomeAccountId,
      colonyAccountId,
      payDate,
      description,
      amount
    ) => {
      const transaction = await this.#post(
        "/colonyTransactions/insertIncome",
        true,
        {
          colonyId,
          incomeAccountId,
          colonyAccountId,
          payDate,
          description,
          amount,
        }
      );
      return transaction;
    },
    insertExpense: async (
      colonyId,
      expenseAccountId,
      colonyAccountId,
      payDate,
      description,
      amount,
      receipt
    ) => {
      var data = {
        colonyId,
        expenseAccountId,
        colonyAccountId,
        payDate,
        description,
        amount,
      };

      const transaction = await this.#postFormData(
        "/colonyTransactions/insertExpense",
        true,
        receipt,
        data
      );
      return transaction;
    },
    insertPayments: async (colonyId, colonyAccountId, typeId, file) => {
      const data = { colonyId, colonyAccountId, typeId };

      const result = await this.#postFormData(
        "/colonyTransactions/insertPayments",
        true,
        file,
        data
      );

      return result;
    },
    insertMaintenances: async (colonyId, houseAccountIds, month) => {
      const result = await this.#post(
        "/colonyTransactions/insertMaintenances",
        true,
        {
          colonyId,
          houseAccountIds,
          month,
        }
      );

      return result;
    },
    delete: async (colonyId, transactionId) => {
      await this.#post("/colonyTransactions/delete", true, {
        colonyId,
        transactionId,
      });
    },
  };

  clientTransactions = {
    getTypes: async () => {
      const types = await this.#post("/clientTransactions/getTypes", true);

      return types;
    },
    getById: async (colonyId, clientTransactionId) => {
      const transaction = await this.#post(
        "/clientTransactions/getById",
        true,
        {
          colonyId,
          clientTransactionId,
        }
      );

      return transaction;
    },
    getByColony: async (colonyId, year) => {
      const transactions = await this.#post(
        "/clientTransactions/getByColony",
        true,
        {
          colonyId,
          year,
        }
      );

      return transactions;
    },
    insertPayment: async (colonyId, date, description, amount) => {
      const transaction = await this.#post(
        "/clientTransactions/insertPayment",
        true,
        {
          colonyId,
          date,
          description,
          amount,
        }
      );
      return transaction;
    },
    sendPaymentReceipt: async (colonyId, transactionId) => {
      await this.#post("/clientTransactions/sendPaymentReceipt", true, {
        colonyId,
        transactionId,
      });
    },
    insertCharge: async (colonyId, date, description, amount) => {
      const transaction = await this.#post(
        "/clientTransactions/insertCharge",
        true,
        {
          colonyId,
          date,
          description,
          amount,
        }
      );
      return transaction;
    },

    delete: async (colonyId, clientTransactionId) => {
      await this.#post("/clientTransactions/delete", true, {
        colonyId,
        clientTransactionId,
      });
    },
  };

  colonies = {
    getClientById: async (colonyId) => {
      const client = await this.#post("/colonies/getClientById", true, {
        colonyId,
      });

      return client;
    },
    updateClient: async (colonyId, price) => {
      const colony = await this.#post("/colonies/updateClient", true, {
        colonyId,
        price,
      });

      return colony;
    },
    getAll: async () => {
      const colonies = await this.#post("/colonies/getAll", true);

      return colonies;
    },
    getById: async (colonyId) => {
      const colony = await this.#post("/colonies/getById", true, { colonyId });

      return colony;
    },
    delete: async (colonyId) => {
      await this.#post("/colonies/delete", true, { colonyId });
    },
    // update: async (colonyId, email) => {
    //   await this.#post("/colonies/update", true, { colonyId, email });
    // },
    updateConfiguration: async (
      colonyId,
      email,
      generalAllowHouseLogin,
      reportsBannerFile,
      reportsAccountStatusFormat
    ) => {
      const colony = await this.#postFormData(
        "/colonies/updateConfiguration",
        true,
        reportsBannerFile && !reportsBannerFile.uri
          ? reportsBannerFile
          : undefined,
        {
          colonyId,
          email,
          generalAllowHouseLogin,
          reportsBannerFile: reportsBannerFile?.url
            ? reportsBannerFile
            : undefined,
          reportsAccountStatusFormat,
        }
      );

      return colony;
    },
    insert: async (file) => {
      const colony = await this.#postFormData("/colonies/insert", true, file);

      return colony;
    },
    insertDemo: async (name, email) => {
      const colony = await this.#post("/colonies/insertDemo", true, {
        name,
        email,
      });

      return colony;
    },
    // insert: async (name, email, houses) => {
    //   const colony = await this.#post("/colonies/insert", true, {
    //     name,
    //     email,
    //     houses,
    //   });

    //   return colony;
    // },
    getEventTypes: async () => {
      const types = await this.#post("/colonies/getEventTypes", true);

      return types;
    },
    getEvents: async (colonyId) => {
      const events = await this.#post("/colonies/getEvents", true, {
        colonyId,
      });

      return events;
    },
    getEventById: async (colonyId, eventId) => {
      const event = await this.#post("/colonies/getEventById", true, {
        colonyId,
        eventId,
      });

      return event;
    },
    updateEvent: async (colonyId, eventId, type, date, description) => {
      const event = await this.#post("/colonies/updateEvent", true, {
        colonyId,
        eventId,
        type,
        date,
        description,
      });

      return event;
    },
    insertEvent: async (colonyId, type, date, description) => {
      const event = await this.#post("/colonies/insertEvent", true, {
        colonyId,
        type,
        date,
        description,
      });

      return event;
    },
    deleteEvent: async (colonyId, eventId) => {
      await this.#post("/colonies/deleteEvent", true, { colonyId, eventId });
    },
    getUsers: async (colonyId) => {
      const users = await this.#post("/colonies/getUsers", true, {
        colonyId,
      });

      return users;
    },
    getUserById: async (colonyId, userId) => {
      const user = await this.#post("/colonies/getUserById", true, {
        colonyId,
        userId,
      });

      return user;
    },
    insertUser: async (colonyId, name, email, roleId) => {
      const user = await this.#post("/colonies/insertUser", true, {
        colonyId,
        name,
        email,
        roleId,
      });

      return user;
    },
    deleteUser: async (colonyId, userId) => {
      const user = await this.#post("/colonies/deleteUser", true, {
        colonyId,
        userId,
      });

      return user;
    },
    updateUser: async (colonyId, userId, name, email, roleId) => {
      const user = await this.#post("/colonies/updateUser", true, {
        colonyId,
        userId,
        name,
        email,
        roleId,
      });

      return user;
    },
    updateUserPassword: async (colonyId, userId, sendByEmail) => {
      const user = await this.#post("/colonies/updateUserPassword", true, {
        colonyId,
        userId,
        sendByEmail,
      });

      return user;
    },
    getDocuments: async (colonyId) => {
      const documents = await this.#post("/colonies/getDocuments", true, {
        colonyId,
      });

      return documents;
    },
    getDocumentById: async (colonyId, documentId) => {
      const document = await this.#post("/colonies/getDocumentById", true, {
        colonyId,
        documentId,
      });

      return document;
    },
    insertDocument: async (colonyId, name, file) => {
      const document = await this.#postFormData(
        "/colonies/insertDocument",
        true,
        file,
        {
          colonyId,
          name,
        }
      );

      return document;
    },
    updateDocument: async (colonyId, documentId, name, file) => {
      const document = await this.#postFormData(
        "/colonies/updateDocument",
        true,
        file && !file.url ? file : undefined,
        {
          colonyId,
          documentId,
          name,
          fileUrl: file?.url ? file.url : undefined,
        }
      );

      return document;
    },
    deleteDocument: async (colonyId, documentId) => {
      await this.#post("/colonies/deleteDocument", true, {
        colonyId,
        documentId,
      });
    },
  };

  emails = {
    getAttachmentTypes: async () => {
      const attachmentTypes = await this.#post(
        "/emails/getAttachmentTypes",
        true
      );

      return attachmentTypes;
    },

    send: async (colonyId, houseIds, subject, body, attachmentTypeId, file) => {
      const data = {
        colonyId,
        houseIds,
        subject,
        body,
        attachmentTypeId,
        clientDate: DateTime.now().toISODate(),
      };

      const json = await this.#postFormData("/emails/send", true, file, data);

      return json.count;
    },

    getQueueCount: async (colonyId) => {
      const info = await this.#post("/emails/getQueueCount", true, {
        colonyId,
      });

      return info.count;
    },

    getTemplatesByColony: async (colonyId) => {
      const templates = await this.#post("/emails/getTemplatesByColony", true, {
        colonyId,
      });

      return templates;
    },

    insertTemplate: async (colonyId, name, subject, body, attachmentTypeId) => {
      const template = await this.#post("/emails/insertTemplate", true, {
        colonyId,
        name,
        subject,
        body,
        attachmentTypeId,
      });

      return template;
    },

    updateTemplate: async (colonyId, id, subject, body, attachmentTypeId) => {
      const template = await this.#post("/emails/updateTemplate", true, {
        colonyId,
        id,
        subject,
        body,
        attachmentTypeId,
      });

      return template;
    },

    deleteTemplate: async (colonyId, id) => {
      await this.#post("/emails/deleteTemplate", true, { colonyId, id });
    },

    updateTemplateName: async (colonyId, id, name) => {
      await this.#post("/emails/updateTemplateName", true, {
        colonyId,
        id,
        name,
      });
    },
  };

  reports = {
    getAccountStatusTypes: async () => {
      const types = await this.#post("/reports/getAccountStatusTypes", true);

      return types;
    },
    getEventsByAccount: async (colonyId, accountId) => {
      const events = await this.#post("/reports/getEventsByAccount", true, {
        colonyId,
        accountId,
      });

      return events;
    },
    getEventsByAccountType: async (colonyId, accountType) => {
      const events = await this.#post("/reports/getEventsByAccountType", true, {
        colonyId,
        accountType,
      });

      return events;
    },
    getAccounts: async (colonyId) => {
      const accounts = await this.#post("/reports/getAccounts", true, {
        colonyId,
      });

      return accounts;
    },
    generate: async (
      reportTypeId,
      colonyId,
      accountStatusType,
      accountId,
      accountType,
      houseAccountId,
      startDate,
      endDate,
      joinHouseTransactions
    ) => {
      const report = await this.#post("/reports/generate", true, {
        reportTypeId,
        colonyId,
        accountStatusType,
        accountId,
        accountType,
        houseAccountId,
        startDate,
        endDate,
        joinHouseTransactions,
      });

      return report;
    },
    getTypes: async () => {
      const types = await this.#post("/reports/getTypes", true);

      return types;
    },
  };

  admin = {
    getEntities: async () => {
      const entities = await this.#post("/admin/getEntities", true);

      return entities;
    },
    filter: async (entity, filter) => {
      const info = await this.#post("/admin/filter", true, { entity, filter });

      return info.count;
    },
    update: async (entity, filter, update) => {
      const info = await this.#post("/admin/update", true, {
        entity,
        filter,
        update,
      });

      return info.count;
    },
    deleteTransactions: async (filter, count) => {
      const info = await this.#post("/admin/deleteTransactions", true, {
        filter,
        count,
      });

      return info.count;
    },
  };

  reservations = {
    getAreas: async (colonyId) => {
      const areas = await this.#post("/reservations/getAreas", true, {
        colonyId,
      });

      return areas;
    },
    getByMonth: async (colonyId, month) => {
      const events = await this.#post("/reservations/getByMonth", true, {
        colonyId,
        month,
      });

      return events;
    },
    insert: async (colonyId, areaId, title, description, start, end) => {
      const event = await this.#post("/reservations/insert", true, {
        colonyId,
        areaId,
        title,
        description,
        start,
        end,
      });

      return event;
    },
    update: async (
      reservationId,
      colonyId,
      areaId,
      title,
      description,
      start,
      end
    ) => {
      const event = await this.#post("/reservations/update", true, {
        reservationId,
        colonyId,
        areaId,
        title,
        description,
        start,
        end,
      });

      return event;
    },
    delete: async (colonyId, reservationId) => {
      await this.#post("/reservations/delete", true, {
        colonyId,
        reservationId,
      });
    },
    getById: async (colonyId, reservationId) => {
      const event = await this.#post("/reservations/getById", true, {
        colonyId,
        reservationId,
      });

      return event;
    },
  };

  areas = {
    getAll: async (colonyId) => {
      const areas = await this.#post("/areas/getAll", true, { colonyId });

      return areas;
    },
    getById: async (colonyId, areaId) => {
      const event = await this.#post("/areas/getById", true, {
        colonyId,
        areaId,
      });

      return event;
    },
    insert: async (colonyId, name, color) => {
      const area = await this.#post("/areas/insert", true, {
        colonyId,
        name,
        color,
      });

      return area;
    },
    update: async (areaId, colonyId, name, color) => {
      const area = await this.#post("/areas/update", true, {
        areaId,
        colonyId,
        name,
        color,
      });

      return area;
    },
    delete: async (areaId, colonyId) => {
      const area = await this.#post("/areas/delete", true, {
        areaId,
        colonyId,
      });

      return area;
    },
  };

  templates = {
    getAttachmentTypes: async () => {
      const attachmentTypes = await this.#post(
        "/templates/getAttachmentTypes",
        true
      );

      return attachmentTypes;
    },
    getByColony: async (colonyId) => {
      const templates = await this.#post("/templates/getByColony", true, {
        colonyId,
      });

      return templates;
    },
    getById: async (colonyId, templateId) => {
      const template = await this.#post("/templates/getById", true, {
        colonyId,
        templateId,
      });

      return template;
    },
    insert: async (colonyId, name, subject, body, attachmentTypeId) => {
      const template = await this.#post("/templates/insert", true, {
        colonyId,
        name,
        subject,
        body,
        attachmentTypeId,
      });

      return template;
    },
    update: async (
      colonyId,
      templateId,
      name,
      subject,
      body,
      attachmentTypeId
    ) => {
      const template = await this.#post("/templates/update", true, {
        colonyId,
        templateId,
        name,
        subject,
        body,
        attachmentTypeId,
      });

      return template;
    },

    delete: async (colonyId, templateId) => {
      await this.#post("/templates/delete", true, { colonyId, templateId });
    },
  };
}

export default WebserviceClient;
