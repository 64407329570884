import * as React from "react";
import { useNavigate } from "react-router-dom";

import errorUtil from "../../utils/error.util";
import errorbaseUtil from "../../utils/errorbase.util";

import { useUser } from "./user.context";
import { useMessage } from "./message.context";

const exceptionContext = React.createContext();

export const useException = () => {
  return React.useContext(exceptionContext);
};

export const ExceptionContext = ({ children }) => {
  const navigate = useNavigate();

  const userCtx = useUser();
  const messageCtx = useMessage();

  const handleException = (ex) => {
    if (errorUtil.isServerError(ex)) {
      if (ex.message === "Usuario no autorizado") {
        userCtx.logout();
        navigate("/");
      } else {
        messageCtx.showError(ex.message);
      }
    } else {
      errorbaseUtil.insertException(ex);

      if (errorUtil.isError(ex)) {
        messageCtx.showError(`Error: ${ex}`);
      } else if (ex instanceof String) {
        messageCtx.showError(`${ex}`);
      } else if (ex instanceof Object) {
        messageCtx.showError(`${JSON.stringify(ex)}`);
      } else {
        messageCtx.showError(`${ex}`);
      }
    }
  };

  return (
    <exceptionContext.Provider
      value={{
        handleException,
      }}
    >
      {children}
    </exceptionContext.Provider>
  );
};
