import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { faFilter, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import localStorageUtil from "../../../../utils/localStorage.util";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import TextInput from "../../../controls/text.input";
import TableControl from "../../../controls/table.control";

const getDefaultFilter = () => {
  return {
    address: "",
  };
};

const HousesPage = () => {
  const navigate = useNavigate();

  const userCtx = useUser(); // { user
  const exceptionCtx = useException();

  const [houses, setHouses] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState();

  const handleAddressChanged = (address) => {
    const filter2 = { ...filter, address: address.toLowerCase() };
    saveFilter(filter2);
  };

  const saveFilter = (filter) => {
    localStorageUtil.set("housesFilter", JSON.stringify(filter));
    setFilter(filter);
  };

  const loadFilter = () => {
    try {
      const json = localStorageUtil.get("housesFilter");
      if (!json) throw "";
      return JSON.parse(json);
    } catch {
      return getDefaultFilter();
    }
  };

  const refresh = async () => {
    try {
      const filter = loadFilter();
      setFilter(filter);
      const houses = await userCtx.webserviceClient.houses.getByColony(
        userCtx.user.colony._id
      );
      houses.sort((a, b) => a.address.localeCompare(b.address));
      setHouses(houses);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, [userCtx.user]);

  const handleToggleFilterClicked = () => {
    setShowFilter(!showFilter);
  };

  const handleResetFilterClicked = () => {
    saveFilter(getDefaultFilter());
  };

  const handleHousePressed = (house) => {
    navigate(`./${house._id}`);
  };

  if (!filter) return null;

  const filteredHouses =
    filter.address !== ""
      ? houses.filter((h) => h.address.toLowerCase().includes(filter.address))
      : houses;

  return (
    <div>
      <h1>Casas</h1>

      <div className="d-flex justify-content-end">
        <div className="btn-group">
          <button
            className="btn btn-primary"
            onClick={handleToggleFilterClicked}
          >
            <FontAwesomeIcon icon={faFilter} />
          </button>
          {JSON.stringify(getDefaultFilter()) !== JSON.stringify(filter) && (
            <button
              className="btn btn-danger"
              onClick={handleResetFilterClicked}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          )}
        </div>
      </div>

      <div style={{ display: showFilter ? "block" : "none" }}>
        <div className="mb-2">
          <label className="form-label">Dirección</label>
          <TextInput value={filter.address} onChange={handleAddressChanged} />
        </div>
      </div>

      <TableControl
        columns={[
          { header: "Dirección", valueExtractor: (h) => h.address },
          {
            header: "Mantenimiento",
            valueExtractor: (h) => h.maintenance.toFixed(2),
            className: "text-end",
          },
          {
            header: "Saldo",
            valueExtractor: (h) => h.account.typeBalance.toFixed(2),
            className: "text-end",
          },
        ]}
        rows={filteredHouses}
        onRowClicked={handleHousePressed}
        keyExtractor={(h) => h._id}
      />
    </div>
  );
};

export default HousesPage;
