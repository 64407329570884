import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useException } from "../../../contexts/exception.context";
import { useUser } from "../../../contexts/user.context";
import TextInput from "../../../controls/text.input";
import ColorInput from "../../../controls/color.input";
import ConfirmModal from "../../../controls/confirm.modal";
import { useMessage } from "../../../contexts/message.context";

export default () => {
  const navigate = useNavigate();
  const params = useParams();

  const userCtx = useUser();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [oldArea, setOldArea] = useState();
  const [newArea, setNewArea] = useState();
  const [isWorking, setIsWorking] = useState(false);

  const refresh = async () => {
    if (params.areaId) {
      try {
        const area = await userCtx.webserviceClient.areas.getById(
          userCtx.user.colony._id,
          params.areaId
        );
        setOldArea(area);
        setNewArea({ ...area });
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    } else {
      setNewArea({ name: "", color: "#000000" });
    }
  };

  useEffect(() => {
    refresh();
  }, [userCtx.user, params]);

  const handleCreateClicked = async () => {
    try {
      setIsWorking(true);
      await userCtx.webserviceClient.areas.insert(
        userCtx.user.colony._id,
        newArea.name,
        newArea.color
      );
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
      setIsWorking(false);
    }
  };

  const handleUpdateClicked = async () => {
    try {
      setIsWorking(true);
      await userCtx.webserviceClient.areas.update(
        params.areaId,
        userCtx.user.colony._id,
        newArea.name,
        newArea.color
      );
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
      setIsWorking(false);
    }
  };

  const handleConfirmDeleteModalClose = async (confirm) => {
    setShowConfirmDeleteModal(false);
    if (!confirm) return;

    try {
      setIsWorking(true);
      await userCtx.webserviceClient.areas.delete(
        params.areaId,
        userCtx.user.colony._id
      );
      messageCtx.showSuccess("Area borrado");
      navigate("./..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const isFormOk = () => {
    if (!newArea.name) return false;
    if (!newArea.color) return false;

    return true;
  };

  const hasFormChanged = () => {
    return JSON.stringify(oldArea) !== JSON.stringify(newArea);
  };

  if (!newArea) return null;

  return (
    <div>
      <h1>{oldArea ? "Area" : "Nueva area"}</h1>
      <div className="mb-2">
        <label className="form-label">Nombre</label>
        <TextInput
          readonly={!userCtx.user.role.permissions.includes("areas.edit")}
          disabled={isWorking}
          value={newArea.name}
          onChange={(value) => setNewArea({ ...newArea, name: value })}
        />
      </div>
      <div className="mb-2">
        <label className="form-label">Color</label>
        <ColorInput
          readonly={!userCtx.user.role.permissions.includes("areas.edit")}
          disabled={isWorking}
          value={newArea.color}
          onChange={(value) => setNewArea({ ...newArea, color: value })}
        />
      </div>
      {userCtx.user.role.permissions.includes("areas.edit") && (
        <div className="mb-2">
          {!oldArea && (
            <button
              className="btn btn-primary me-2"
              onClick={handleCreateClicked}
              disabled={!isFormOk() || isWorking}
            >
              Crear
            </button>
          )}
          {oldArea && (
            <button
              className="btn btn-primary me-2"
              onClick={handleUpdateClicked}
              disabled={!isFormOk() || !hasFormChanged() || isWorking}
            >
              Guardar
            </button>
          )}
          {oldArea && (
            <button
              className="btn btn-danger"
              onClick={() => setShowConfirmDeleteModal(true)}
              disabled={isWorking}
            >
              Borrar
            </button>
          )}
        </div>
      )}

      <ConfirmModal
        cancelButtonText="No"
        confirmButtonText="Sí"
        titleText="Borrar area"
        questionText="Seguro que quieres borrar area?"
        show={showConfirmDeleteModal}
        onClose={handleConfirmDeleteModalClose}
      />
    </div>
  );
};
