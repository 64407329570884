import { useState } from "react";
import Modal from "react-bootstrap/Modal";

export default ({
  show,
  onClose,
  titleText,
  questionText,
  confirmButtonText,
  cancelButtonText,
}) => {
  const [confirm, setConfirm] = useState(false);

  return (
    <Modal show={show} onHide={() => onClose(false)}>
      <Modal.Header>
        <Modal.Title>{titleText}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{questionText}</p>

        <div className="mb-2">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={confirm}
              onChange={() => setConfirm(true)}
            />
            <label className="form-check-label">Si</label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={!confirm}
              onChange={() => setConfirm(false)}
            />
            <label className="form-check-label">No</label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-danger"
          onClick={() => onClose(true)}
          disabled={!confirm}
        >
          {confirmButtonText}
        </button>
        <button className="btn btn-secondary" onClick={() => onClose(false)}>
          {cancelButtonText}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
