import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

import { useUser } from "../../../contexts/user.context";
import validateUtil from "../../../../utils/validate.util";
import { useException } from "../../../contexts/exception.context";
import TextInput from "../../../controls/text.input";
import { useMessage } from "../../../contexts/message.context";

export default ({ isWorking, setIsWorking }) => {
  const navigate = useNavigate();

  const userCtx = useUser();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [transaction, setTransaction] = useState({
    date: DateTime.now().toISODate(),
    description: "",
    amount: "0",
  });

  const handleCreateClicked = async () => {
    try {
      setIsWorking(true);
      await userCtx.webserviceClient.clientTransaction.insertCharge(
        userCtx.user.colony._id,
        transaction.date,
        transaction.description,
        transaction.amount
      );
      messageCtx.showSuccess("Cargo guardada");
      navigate("./..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const isFormOk = () => {
    if (!transaction.description) return false;
    if (!validateUtil.number.isCurrency(transaction.amount, 2)) return false;
    if (parseFloat(transaction.amount) <= 0) return false;

    return true;
  };

  return (
    <div>
      <div className="mb-2">
        <div className="form-label">Fecha</div>
        <TextInput
          type="date"
          value={transaction.date}
          max={DateTime.now().toISODate()}
          min={DateTime.now().minus({ years: 1 }).toISODate()}
          disabled={isWorking}
          readonly={
            !userCtx.user.role.permissions.includes("clientTransactions.edit")
          }
          onChange={(value) => setTransaction({ ...transaction, date: value })}
        />
      </div>

      <div className="mb-2">
        <div className="form-label">Concepto</div>

        <div className="input-group">
          <TextInput
            type="text"
            value={transaction.description}
            disabled={isWorking}
            readonly={
              !userCtx.user.role.permissions.includes("clientTransactions.edit")
            }
            onChange={(value) =>
              setTransaction({ ...transaction, description: value })
            }
          />
        </div>
      </div>

      <div className="mb-2">
        <div className="form-label">Cantidad</div>
        <TextInput
          type="number"
          value={transaction.amount}
          disabled={isWorking}
          readonly={
            !userCtx.user.role.permissions.includes("clientTransactions.edit")
          }
          onChange={(value) =>
            setTransaction({ ...transaction, amount: value })
          }
        />
      </div>

      <div className="mb-2">
        <button
          className="btn btn-primary"
          onClick={handleCreateClicked}
          disabled={!isFormOk() || isWorking}
        >
          Crear
        </button>
      </div>
    </div>
  );
};
