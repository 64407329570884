import { ServerError } from "./webservice.client";

const util = {
  isServerError: (ex) => {
    return ex instanceof ServerError;
  },
  isError: (ex) => {
    return ex instanceof Error;
  },
};

export default util;
