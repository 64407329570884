import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import TableControl from "../../../controls/table.control";

const Page = () => {
  const navigate = useNavigate();

  const userCtx = useUser();
  const exceptionCtx = useException();

  const [templates, setTemplates] = useState([]);

  const refresh = async () => {
    setTemplates([]);

    try {
      const templates = await userCtx.webserviceClient.templates.getByColony(
        userCtx.user.colony._id
      );
      setTemplates(templates);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleTemplateClicked = async (template) => {
    navigate(`./${template._id}`);
  };

  const handleNewClicked = () => {
    navigate("./nueva");
  };

  return (
    <div>
      <h1>Plantillas</h1>
      <div className="d-flex justify-content-between">
        <div>
          {userCtx.user.role.permissions.includes("templates.edit") && (
            <button className="btn btn-primary" onClick={handleNewClicked}>
              Nueva
            </button>
          )}
        </div>
        <div></div>
      </div>
      <div className="mt-2">
        <TableControl
          columns={[{ header: "Nombre", valueExtractor: (t) => t.name }]}
          rows={templates}
          onRowClicked={handleTemplateClicked}
          keyExtractor={(t) => t._id}
        />
      </div>
    </div>
  );
};

export default Page;
