import { BrowserRouter } from "react-router-dom";

import "./App.css";
import Nav from "./components/pages/nav";
import { UserContext } from "./components/contexts/user.context";
import { ExceptionContext } from "./components/contexts/exception.context";
import { MessageContext } from "./components/contexts/message.context";

function App() {
  return (
    <BrowserRouter>
      <MessageContext>
        <UserContext>
          <ExceptionContext>
            <Nav />
          </ExceptionContext>
        </UserContext>
      </MessageContext>
    </BrowserRouter>
  );
}

export default App;
