import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import TableControl from "../../../controls/table.control";

const ColonyEventsPage = () => {
  const navigate = useNavigate();

  const userCtx = useUser();
  const exceptionCtx = useException();

  const [documents, setDocuments] = useState([]);

  const refresh = async () => {
    try {
      const colony = await userCtx.webserviceClient.colonies.getById(
        userCtx.user.colony._id
      );
      setDocuments(colony.documents);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleDocumentClicked = (document) => {
    navigate(`./${document._id}`);
  };

  const handleNewClicked = () => {
    navigate("./nuevo");
  };

  return (
    <div>
      <h1>Documentos</h1>
      <div>
        <div>
          {userCtx.user.role.permissions.includes("documents.edit") && (
            <button className="btn btn-primary" onClick={handleNewClicked}>
              Nuevo
            </button>
          )}
        </div>
      </div>

      <TableControl
        columns={[{ header: "Name", valueExtractor: (d) => d.name }]}
        rows={documents}
        onRowClicked={handleDocumentClicked}
        keyExtractor={(d) => d._id}
      />
    </div>
  );
};

export default ColonyEventsPage;
