import { useState } from "react";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";

export default ({ title, subjects }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <FontAwesomeIcon
        icon={faQuestionCircle}
        className="clickable"
        onClick={() => setShowModal(true)}
      />
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-no-footer">
          {subjects.map((s, index) => (
            <div className="mb-3" key={index}>
              <div className="fw-bold">{s.title}</div>
              <div>{s.description}</div>
            </div>
          ))}
        </Modal.Body>
      </Modal>
    </>
  );
};
