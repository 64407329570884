import jsPDF from "jspdf";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import numeral from "numeral";
import "jspdf-autotable";

const textToHtml = (text, containsNewLine) => {
  if (typeof text !== "string") return text;

  return text.replaceAll("\r\n", "<br/>");
};

const format = (value, numberFormat) => {
  if (value === null || value === undefined) return "";
  // (12345.67).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');  // 12,345.67
  if (numberFormat) {
    return numeral(value).format(numberFormat);
  }
  return value;
};

const extractValue = (path, o) => {
  if (!path) return "";

  let value;
  if (path instanceof Function) {
    value = path();
  } else {
    const elements = path.split(".");

    value = o;
    for (var element of elements) {
      if (value === undefined) return "";
      value = value[element];
    }
  }

  return value;
};

const util = {
  generateExcel: async (report, fileName) => {
    const book = new ExcelJS.Workbook();
    book.creator = "VecinosMX";
    book.created = new Date();

    for (var index in report.documents) {
      const document = report.documents[index];

      if (document.type === "table") {
        const sheet = book.addWorksheet(
          `${parseInt(index) + 1}-${document.name.substr(0, 29)}`
        );

        const headerColumns = document.columns[document.defaultColumnsName];

        {
          const row = sheet.addRow();
          let offset = 0;
          headerColumns.forEach((c, cIndex) => {
            const cell = row.getCell(cIndex + 1 + offset);
            cell.value = c.header;
            cell.alignment = { horizontal: c.halign };
            cell.font = { bold: true };

            offset += c.colspan || 0;
          });
        }

        // eslint-disable-next-line no-loop-func
        document.rows.forEach((r, rIndex) => {
          const columns =
            document.columns[r._columnsName || document.defaultColumnsName];

          let offset = 0;
          const row = sheet.addRow();
          columns.forEach((c, cIndex) => {
            const cell = row.getCell(cIndex + 1 + offset);
            cell.value = extractValue(c.dataKey, r);
            cell.numFmt = c.numberFormat;
            cell.alignment = { horizontal: c.halign };
            if (c.fontStyle === "bold") {
              cell.font = { bold: true };
            }

            if (c.colspan !== undefined) {
              sheet.mergeCells(
                rIndex + 2,
                cIndex + 1 + offset - 1,
                rIndex + 2,
                cIndex + 1 + c.colspan + offset - 1
              );
              offset += c.colspan - 1;
            }
          });
        });
      }
    }

    async function saveFile(fileName, workbook) {
      const xls64 = await workbook.xlsx.writeBuffer({ base64: true });
      saveAs(
        new Blob([xls64], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        fileName
      );
    }

    saveFile(fileName, book);
  },
  generatePdf: async (report, fileName) => {
    function getDataUri(url) {
      return new Promise((resolve) => {
        var image = new Image();
        //image.setAttribute("crossOrigin", "anonymous"); //getting images from external domain

        image.onload = function () {
          var canvas = document.createElement("canvas");
          canvas.width = this.naturalWidth;
          canvas.height = this.naturalHeight;

          //next three lines for white background in case png has a transparent background
          var ctx = canvas.getContext("2d");
          ctx.fillStyle = "#fff"; /// set white fill style
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          canvas.getContext("2d").drawImage(this, 0, 0);

          resolve(canvas.toDataURL("image/jpeg"));
        };

        image.src = url;
      });
    }

    const doc = new jsPDF();

    const margin = 10;
    let offset = margin;

    if (report.header?.image) {
      const logo = await getDataUri(report.header.image.url);
      const imageRatio = report.header.image.width / report.header.image.height;
      var maxImageHolderWidth = doc.internal.pageSize.getWidth() - 2 * margin;
      let imageHolderHeight = 40;
      let imageHolderWidth = imageRatio * imageHolderHeight;
      if (imageHolderWidth > maxImageHolderWidth) {
        imageHolderWidth = maxImageHolderWidth;
        imageHolderHeight = imageHolderWidth / imageRatio;
      }

      doc.addImage(
        logo,
        "JPG",
        margin,
        offset,
        imageHolderWidth,
        imageHolderHeight
      );

      offset += imageHolderHeight + 6;
    }

    doc.setFontSize(16);
    doc.text(report.name, 10, offset, { baseline: "top" });
    offset += 10;

    for (var index in report.documents) {
      const document = report.documents[index];

      if (index > 0 && document.forceNewPage) {
        doc.addPage();
      }

      if (document.type === "table") {
        const defaultColumns = document.columns[document.defaultColumnsName];

        const head = [
          defaultColumns.map((c) => ({
            content: c.header,
            styles: { halign: c.halign },
          })),
        ];

        const body = document.rows.map((r) => {
          const columns =
            document.columns[r._columnsName || document.defaultColumnsName];

          return columns.map((c) => ({
            content: format(extractValue(c.dataKey, r), c.numberFormat),
            colSpan: c.colspan || 1,
            styles: {
              halign: c.halign,
              fontStyle: c.fontStyle === "bold" ? "bold" : "normal",
            },
          }));
        });

        doc.setFontSize(14);
        doc.text(document.name, 10, offset, { baseline: "top" });
        offset += 10;

        doc.autoTable({
          startY: offset,
          head,
          body,
        });
        offset = doc.lastAutoTable.finalY + 5;
      }
    }

    // const name = `${DateTime.now().toFormat("yyyy-MM-dd")} - ${report.name}`;
    // const fileName = `${name}.pdf`;

    doc.save(fileName);
  },
  generateHtml: (report) => {
    const list = report.documents.map((document, tIndex) => {
      if (document.type === "table") {
        const defaultColumns = document.columns[document.defaultColumnsName];
        return (
          <div key={tIndex}>
            <h4>{document.name}</h4>
            <table className="table table-striped">
              <thead>
                <tr>
                  {defaultColumns.map((c, cIndex) => (
                    <th key={cIndex} style={{ textAlign: c.halign }}>
                      {c.header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {document.rows.map((r, rIndex) => {
                  const columns =
                    document.columns[
                      r._columnsName || document.defaultColumnsName
                    ];
                  return (
                    <tr key={rIndex}>
                      {columns.map((c, cIndex) => (
                        <td
                          colSpan={c.colspan || 1}
                          key={cIndex}
                          style={{
                            textAlign: c.halign,
                            color: c.textColor,
                            fontWeight:
                              c.fontStyle === "bold" ? "bold" : "normal",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: textToHtml(
                              format(extractValue(c.dataKey, r), c.numberFormat)
                            ),
                          }}
                        >
                          {}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      } else if (document.type === "lines") {
        return (
          <div className="my-2">
            {document.lines.map((l) => (
              <div>{l}</div>
            ))}
          </div>
        );
      }

      return null;
    });

    return list;
  },
};

export default util;
