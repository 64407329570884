import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import TableControl from "../../../controls/table.control";

const UsersPage = () => {
  const navigate = useNavigate();

  const userCtx = useUser();
  const exceptionCtx = useException();

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const refresh = async () => {
      try {
        const users = await userCtx.webserviceClient.colonies.getUsers(
          userCtx.user.colony._id
        );
        setUsers(users);
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };

    refresh();
  }, [userCtx.user]);

  const handleUserPressed = (user) => {
    navigate(user._id.toString());
  };

  const handleNewUserClicked = () => {
    navigate("./nuevo"); // , { replace: true }};};
  };

  return (
    <div>
      <h1>Usuarios</h1>
      {userCtx.user.role.permissions.includes("users.edit") && (
        <div>
          <button className="btn btn-primary" onClick={handleNewUserClicked}>
            Nuevo
          </button>
        </div>
      )}

      <TableControl
        columns={[
          { header: "Nombre", valueExtractor: (u) => u.name },
          { header: "Correo", valueExtractor: (u) => u.email },
        ]}
        rows={users}
        onRowClicked={handleUserPressed}
        keyExtractor={(u) => u._id}
      />
    </div>
  );
};

export default UsersPage;
