import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

import validateUtil from "../../../../utils/validate.util";
import arrayUtil from "../../../../utils/array.util";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import { useMessage } from "../../../contexts/message.context";
import DateInput from "../../../controls/date.input";
import SelectInput from "../../../controls/select.input";
import TextInput from "../../../controls/text.input";
import NumberInput from "../../../controls/number.input";
import FileInput from "../../../controls/file.input";

export default ({ colony, accounts, isWorking, setIsWorking }) => {
  const navigate = useNavigate();

  const userCtx = useUser(); // user
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [receipt, setReceipt] = useState();
  const [expenseAccounts, setExpenseAccounts] = useState([]);
  const [expenseAccountId, setExpenseAccountId] = useState();
  const [colonyAccounts, setColonyAccounts] = useState([]);
  const [colonyAccountId, setColonyAccountId] = useState();
  const [description, setDescription] = useState("");
  const [date, setDate] = useState(DateTime.now().toISODate());
  const [amount, setAmount] = useState("0");

  useEffect(() => {
    const refresh = async () => {
      try {
        const conceptAccounts = accounts
          .filter((a) => a.type === "Gasto")
          .sort((a, b) => a.name.localeCompare(b.name));

        const colonyAccounts = accounts
          .filter((a) => a.type === "Colonia")
          .sort((a, b) => a.name.localeCompare(b.name));

        setExpenseAccounts(conceptAccounts);
        setColonyAccounts(colonyAccounts);

        if (conceptAccounts.length > 0) {
          const latest = conceptAccounts.reduce((l, e) =>
            e.transactionCount > l.transactionCount ? e : l
          );
          setExpenseAccountId(latest._id);
        }
        if (colonyAccounts.length > 0) {
          const latest = colonyAccounts.reduce((l, e) =>
            e.transactionCount > l.transactionCount ? e : l
          );
          setColonyAccountId(latest._id);
        }
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };

    refresh();
  }, [accounts]);

  const handleCreateClicked = async () => {
    try {
      setIsWorking(true);

      // let resizedReceipt;
      // if (receipt) {
      //   resizedReceipt = await resizeImage(receipt);
      // }

      await userCtx.webserviceClient.colonyTransactions.insertExpense(
        userCtx.user.colony._id,
        expenseAccountId,
        colonyAccountId,
        date,
        description,
        amount,
        receipt
      );
      messageCtx.showSuccess("Movimiento guardado");
      navigate("./..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const isFormOk = () => {
    if (!description) return false;
    if (!validateUtil.number.isCurrency(amount, 2)) return false;
    if (parseFloat(amount) <= 0) return false;
    if (!expenseAccountId) return false;
    if (!colonyAccountId) return false;

    return true;
  };

  const lastEvent = arrayUtil.last(colony.events);
  const minDate = lastEvent ? lastEvent.date : "";

  return (
    <div>
      <div className="mb-2">
        <div className="form-label">Fecha</div>
        <DateInput
          max={DateTime.now().toISODate()}
          min={minDate}
          value={date}
          disabled={isWorking}
          onChange={setDate}
        />
      </div>
      <div className="mb-2">
        <div className="form-label">Cuenta de gasto</div>
        <SelectInput
          items={expenseAccounts}
          nameExtractor={(a) => a.name}
          valueExtractor={(a) => a._id}
          value={expenseAccountId}
          disabled={isWorking}
          onChange={setExpenseAccountId}
        />
      </div>
      <div className="mb-2">
        <div className="form-label">Cuenta de colonia</div>
        <SelectInput
          items={colonyAccounts}
          nameExtractor={(a) => a.name}
          valueExtractor={(a) => a._id}
          value={colonyAccountId}
          disabled={isWorking}
          onChange={setColonyAccountId}
        />
      </div>
      <div className="mb-2">
        <div className="form-label">Concepto</div>
        <TextInput
          value={description}
          disabled={isWorking}
          onChange={setDescription}
        />
      </div>
      <div className="mb-2">
        <div className="form-label">Cantidad</div>
        <NumberInput value={amount} onChange={setAmount} disabled={isWorking} />
      </div>
      <div className="mb-2">
        <div className="form-label">Recibo</div>
        <FileInput
          value={receipt}
          onChange={setReceipt}
          disabled={isWorking}
          accept=".jpg,.png,.pdf,.jpeg"
          allowEmpty
          maxSize={5000000}
        />
      </div>
      .
      <div className="mb-2">
        <button
          className="btn btn-primary"
          onClick={handleCreateClicked}
          disabled={!isFormOk() || isWorking}
        >
          Crear
        </button>
      </div>
    </div>
  );
};
