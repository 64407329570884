import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

import { useUser } from "../../../contexts/user.context";
import validateUtil from "../../../../utils/validate.util";
import { useException } from "../../../contexts/exception.context";
import TextInput from "../../../controls/text.input";
import { useMessage } from "../../../contexts/message.context";

const NewTransactionPaymentControl = ({ isWorking, setIsWorking }) => {
  const navigate = useNavigate();

  const userCtx = useUser();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [clientTransaction, setClientTransaction] = useState({
    date: DateTime.now().toISODate(),
    description: "",
    amount: "0",
  });

  const handleCreateClicked = async () => {
    try {
      setIsWorking(true);
      await userCtx.webserviceClient.clientTransactions.insertPayment(
        userCtx.user.colony._id,
        clientTransaction.date,
        clientTransaction.description,
        clientTransaction.amount
      );
      messageCtx.showSuccess("Pago guardado");
      navigate("./..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const isFormOk = () => {
    if (!clientTransaction.description) return false;
    if (!validateUtil.number.isCurrency(clientTransaction.amount, 2))
      return false;
    if (parseFloat(clientTransaction.amount) <= 0) return false;

    return true;
  };

  return (
    <div>
      <div className="mb-2">
        <div className="form-label">Fecha</div>
        <TextInput
          type="date"
          value={clientTransaction.date}
          max={DateTime.now().toISODate()}
          min={DateTime.now().minus({ years: 1 }).toISODate()}
          disabled={isWorking}
          readonly={
            !userCtx.user.role.permissions.includes("clientTransactions.edit")
          }
          onChange={(value) =>
            setClientTransaction({ ...clientTransaction, date: value })
          }
        />
      </div>

      <div className="mb-2">
        <div className="form-label">Concepto</div>

        <TextInput
          type="text"
          value={clientTransaction.description}
          disabled={isWorking}
          readonly={
            !userCtx.user.role.permissions.includes("clientTransactions.edit")
          }
          onChange={(value) =>
            setClientTransaction({ ...clientTransaction, description: value })
          }
        />
      </div>

      <div className="mb-2">
        <div className="form-label">Cantidad</div>

        <TextInput
          type="number"
          value={clientTransaction.amount}
          disabled={isWorking}
          readonly={
            !userCtx.user.role.permissions.includes("clientTransactions.edit")
          }
          onChange={(value) =>
            setClientTransaction({ ...clientTransaction, amount: value })
          }
        />
      </div>

      <div className="mb-2">
        <button
          className="btn btn-primary"
          onClick={handleCreateClicked}
          disabled={!isFormOk() || isWorking}
        >
          Crear
        </button>
      </div>
    </div>
  );
};

export default NewTransactionPaymentControl;
