import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DateTime } from "luxon";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import FileInput from "../../../controls/file.input";
import TextInput from "../../../controls/text.input";
import ConfirmModal from "../../../controls/confirm.modal";
import { useMessage } from "../../../contexts/message.context";

export default () => {
  const navigate = useNavigate();
  const params = useParams();

  const userCtx = useUser();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [oldDocument, setOldDocument] = useState();
  const [newDocument, setNewDocument] = useState();

  const [isWorking, setIsWorking] = useState(false);

  const isReadonly = !userCtx.user.role.permissions.includes("documents.edit");

  useEffect(() => {
    const refresh = async () => {
      if (params.documentId) {
        const document =
          await userCtx.webserviceClient.colonies.getDocumentById(
            userCtx.user.colony._id,
            params.documentId
          );

        setOldDocument(document);
        setNewDocument({ ...document });
      } else {
        setNewDocument({
          name: "",
        });
      }
    };

    refresh();
  }, []);

  const isFormOk = () => {
    if (!newDocument.name) return false;
    if (!newDocument.file) return false;

    return true;
  };

  const hasFormChanged = () => {
    return JSON.stringify(oldDocument) !== JSON.stringify(newDocument);
  };

  const handleUpdateClicked = async () => {
    try {
      setIsWorking(true);
      await userCtx.webserviceClient.colonies.updateDocument(
        userCtx.user.colony._id,
        newDocument._id,
        newDocument.name,
        newDocument.file
      );
      messageCtx.showSuccess("Documento guardado");
      navigate("./..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const handleCreateClicked = async () => {
    try {
      setIsWorking(true);
      await userCtx.webserviceClient.colonies.insertDocument(
        userCtx.user.colony._id,
        newDocument.name,
        newDocument.file
      );
      messageCtx.showSuccess("Documento guardado");
      navigate("./..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const handleConfirmDeleteModalClose = async (confirm) => {
    setShowConfirmDeleteModal(false);
    if (!confirm) return;

    try {
      setIsWorking(true);
      await userCtx.webserviceClient.colonies.deleteDocument(
        userCtx.user.colony._id,
        params.documentId
      );
      messageCtx.showSuccess("Documento borrado");
      navigate("./..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  if (!newDocument) return null;

  return (
    <div>
      <h1>{oldDocument ? "Documento" : "Nuevo documento"}</h1>
      <div>
        <div className="mt-2">
          <label className="form-label">Nombre</label>
          <TextInput
            value={newDocument.name}
            onChange={(value) =>
              setNewDocument({ ...newDocument, name: value })
            }
            disabled={isWorking}
            readonly={isReadonly}
          />
        </div>
        <div className="mt-2">
          <label className="form-label">Archivo</label>
          <FileInput
            value={newDocument.file}
            onChange={(value) =>
              setNewDocument({ ...newDocument, file: value })
            }
            accept=".pdf"
            disabled={isWorking}
            readonly={isReadonly}
          />
        </div>

        <div className="mt-2">
          {!oldDocument && (
            <button
              className="btn btn-primary me-2"
              onClick={handleCreateClicked}
              disabled={!isFormOk() || isWorking}
            >
              Guardar
            </button>
          )}
          {!isReadonly && oldDocument && (
            <button
              className="btn btn-primary me-2"
              disabled={isWorking || !isFormOk() || !hasFormChanged()}
              onClick={handleUpdateClicked}
            >
              Guardar
            </button>
          )}
          {!isReadonly && oldDocument && (
            <button
              className="btn btn-danger"
              disabled={isWorking}
              onClick={() => setShowConfirmDeleteModal(true)}
            >
              Borrar
            </button>
          )}
        </div>
      </div>
      <ConfirmModal
        cancelButtonText="No"
        confirmButtonText="Sí"
        titleText="Borrar documento"
        questionText="Seguro que quieres borrar documento?"
        show={showConfirmDeleteModal}
        onClose={handleConfirmDeleteModalClose}
      />
    </div>
  );
};
