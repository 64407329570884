import environmentUtil from "./environment.util";

import ErrorbaseClient, { getMessage, getStackTrace } from "./errorbase.client";
import errorUtil from "./error.util";
const isDevelopment = environmentUtil.isEnvironment("development");

const errorbaseClient = new ErrorbaseClient(!isDevelopment);

const util = {
  insertException: (ex) => {
    if (!errorUtil.isServerError(ex) && errorUtil.isError(ex)) {
      errorbaseClient.events.insert(
        "Vecinos",
        "Web>App",
        getMessage(ex),
        getStackTrace(ex),
        window.location.href
      );
    }
  },
  insertError: (error) => {
    // error boundary
    errorbaseClient.events.insert(
      "Vecinos",
      "Web>App",
      error.message,
      error.stack,
      window.location.href
    );
  },
};

export default util;
