import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useException } from "../../../contexts/exception.context";
import { useUser } from "../../../contexts/user.context";
import SelectInput from "../../../controls/select.input";
import TextInput from "../../../controls/text.input";
import DateTimeInput, { isDateTime } from "../../../controls/datetime.input";
import TextareaInput from "../../../controls/textarea.input";
import ConfirmModal from "../../../controls/confirm.modal";
import { useMessage } from "../../../contexts/message.context";

export default () => {
  const navigate = useNavigate();
  const params = useParams();

  const userCtx = useUser();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [areas, setAreas] = useState([]);
  const [oldReservation, setOldReservation] = useState();
  const [newReservation, setNewReservation] = useState();
  const [isWorking, setIsWorking] = useState(false);

  const refresh = async () => {
    try {
      const areas = await userCtx.webserviceClient.reservations.getAreas(
        userCtx.user.colony._id
      );
      setAreas(areas);

      if (params.reservationId) {
        const reservation = await userCtx.webserviceClient.reservations.getById(
          userCtx.user.colony._id,
          params.reservationId
        );

        setOldReservation(reservation);
        setNewReservation({ ...reservation });
      } else {
        setNewReservation({
          areaId: areas.length > 0 ? areas[0]._id : "",
          title: "",
          start: "",
          end: "",
          description: "",
        });
      }
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleConfirmDeleteModalClose = async (confirm) => {
    setShowConfirmDeleteModal(false);
    if (!confirm) return;

    try {
      setIsWorking(true);
      await userCtx.webserviceClient.reservations.delete(
        userCtx.user.colony._id,
        params.reservationId
      );
      messageCtx.showSuccess("Reservación borrado");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
      setIsWorking(false);
    }
  };

  const handleCreateClicked = async () => {
    try {
      setIsWorking(true);

      await userCtx.webserviceClient.reservations.insert(
        userCtx.user.colony._id,
        newReservation.areaId,
        newReservation.title,
        newReservation.description,
        newReservation.start,
        newReservation.end
      );
      messageCtx.showSuccess("Reservación creado");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
      setIsWorking(false);
    }
  };

  const handleUpdateClicked = async () => {
    try {
      setIsWorking(true);

      await userCtx.webserviceClient.reservations.update(
        params.reservationId,
        userCtx.user.colony._id,
        newReservation.areaId,
        newReservation.title,
        newReservation.description,
        newReservation.start,
        newReservation.end
      );

      messageCtx.showSuccess("Reservación actualizado");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
      setIsWorking(false);
    }
  };

  const isFormOk = () => {
    if (!newReservation.areaId) return false;
    if (!isDateTime(newReservation.start)) return false;
    if (!isDateTime(newReservation.end)) return false;
    if (newReservation.start >= newReservation.end) return false;
    if (newReservation.start < DateTime.now().toFormat("yyyy-MM-dd'T'HH-mm"))
      return false;
    if (!newReservation.title) return false;

    return true;
  };

  if (!newReservation) return null;

  return (
    <div>
      <h1>{params.reservationId ? "Reservación" : "Nueva reservación"}</h1>
      <div className="mb-2">
        <label className="form-label">Area</label>
        <SelectInput
          disabled={isWorking}
          readonly={
            !userCtx.user.role.permissions.includes("reservations.edit")
          }
          items={areas}
          nameExtractor={(a) => a.name}
          valueExtractor={(a) => a._id}
          value={newReservation.areaId}
          onChange={(value) =>
            setNewReservation({ ...newReservation, areaId: value })
          }
        />
      </div>
      <div className="mb-2">
        <label className="form-label">Titulo</label>
        <TextInput
          disabled={isWorking}
          readonly={
            !userCtx.user.role.permissions.includes("reservations.edit")
          }
          value={newReservation.title}
          onChange={(value) =>
            setNewReservation({ ...newReservation, title: value })
          }
        />
      </div>
      <div className="mb-2">
        <label className="form-label">Empieza</label>

        <DateTimeInput
          disabled={isWorking}
          readonly={
            !userCtx.user.role.permissions.includes("reservations.edit")
          }
          value={newReservation.start}
          onChange={(value) =>
            setNewReservation({ ...newReservation, start: value })
          }
        />
      </div>
      <div className="mb-2">
        <label className="form-label">Termina</label>

        <DateTimeInput
          disabled={isWorking}
          readonly={
            !userCtx.user.role.permissions.includes("reservations.edit")
          }
          value={newReservation.end}
          onChange={(value) =>
            setNewReservation({ ...newReservation, end: value })
          }
        />
      </div>
      <div className="mb-2">
        <label className="form-label">Descripción</label>

        <TextareaInput
          disabled={isWorking}
          readonly={
            !userCtx.user.role.permissions.includes("reservations.edit")
          }
          rows={5}
          value={newReservation.description}
          onChange={(value) =>
            setNewReservation({ ...newReservation, description: value })
          }
        />
      </div>
      {userCtx.user.role.permissions.includes("reservations.edit") && (
        <div className="mb-2">
          {!oldReservation && (
            <button
              className="btn btn-primary me-2"
              onClick={handleCreateClicked}
              disabled={!isFormOk() || isWorking}
            >
              Crear
            </button>
          )}
          {oldReservation && (
            <button
              className="btn btn-primary me-2"
              onClick={handleUpdateClicked}
              disabled={!isFormOk() || isWorking}
            >
              Guardar
            </button>
          )}
          {oldReservation && (
            <button
              className="btn btn-danger me-2"
              onClick={() => setShowConfirmDeleteModal(true)}
              disabled={isWorking}
            >
              Borrar
            </button>
          )}
        </div>
      )}

      <ConfirmModal
        cancelButtonText="No"
        confirmButtonText="Sí"
        titleText="Borrar reservación"
        questionText="Seguro que quieres borrar reservación?"
        show={showConfirmDeleteModal}
        onClose={handleConfirmDeleteModalClose}
      />
    </div>
  );
};
