import { useState } from "react";
import { useNavigate } from "react-router-dom";

import validateUtil from "../../../../utils/validate.util";

import { useException } from "../../../contexts/exception.context";
import { useUser } from "../../../contexts/user.context";
import TextInput from "../../../controls/text.input";

export default () => {
  const navigate = useNavigate();

  const exceptionCtx = useException();
  const userCtx = useUser();

  const [isWorking, setIsWorking] = useState(false);
  const [results, setResults] = useState([]);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const handleCreateClicked = async () => {
    try {
      setResults([]);
      setIsWorking(true);
      await userCtx.webserviceClient.colonies.insertDemo(name, email);
      navigate("./..");
    } catch (ex) {
      if (ex.data) {
        setResults(ex.data);
      }
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const isFormOk = () => {
    if (!validateUtil.email.isValid(email)) return false;

    return true;
  };

  return (
    <div>
      <h1>Nuevo demo</h1>

      <div>
        {results.length > 0 && (
          <table className="table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Mensaje</th>
              </tr>
            </thead>
            <tbody>
              {results.map((r) => (
                <tr
                  key={r.id}
                  className={
                    r.state === "error" ? "table-danger" : "table-success"
                  }
                >
                  <td>{r.id}</td>
                  <td>{r.message}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div className="mt-2">
          <div className="form-label">Nombre del presidente</div>
          <TextInput value={name} onChange={setName} disabled={isWorking} />
        </div>
        <div className="mt-2">
          <div className="form-label">Correo del presidente</div>
          <TextInput value={email} onChange={setEmail} disabled={isWorking} />
        </div>
        <div className="mt-2">
          <div className="form-label">Estatus</div>
          <TextInput value="Demo se borra despues de 14 dias" readonly={true} />
        </div>

        <div className="mt-2">
          <button
            className="btn btn-primary"
            onClick={handleCreateClicked}
            disabled={!isFormOk() || isWorking}
          >
            Crear
          </button>
        </div>
      </div>
    </div>
  );
};
