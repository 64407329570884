import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import { faFilter, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";

import localStorageUtil from "../../../../utils/localStorage.util";
import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import TextInput from "../../../controls/text.input";
import MultiselectInput from "../../../controls/multiselect.input";

const getDefaultFilter = (areas) => {
  return {
    month: DateTime.now().toFormat("yyyy-MM"),
    areaIds: areas.map((a) => a._id),
  };
};

export default () => {
  const navigate = useNavigate();

  const userCtx = useUser();
  const { handleException } = useException();

  const [reservations, setReservations] = useState([]);
  const [areas, setAreas] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState();
  const calenderRef = useRef();

  const refreshReservations = async () => {
    if (!filter) return null;

    setReservations([]);

    try {
      const calendarApi = calenderRef.current.getApi();
      calendarApi.gotoDate(filter.month + "-01"); // call a method on the Calendar object

      const reservations =
        await userCtx.webserviceClient.reservations.getByMonth(
          userCtx.user.colony._id,
          filter.month
        );
      reservations.forEach(
        (r) => (r.color = areas.find((a) => a._id === r.areaId).color)
      );

      setReservations(reservations);
    } catch (ex) {
      handleException(ex);
    }
  };

  const saveFilter = (filter) => {
    localStorageUtil.set("reservationsFilter", JSON.stringify(filter));
    setFilter(filter);
  };
  const loadFilter = (areas) => {
    try {
      const json = localStorageUtil.get("reservationsFilter");
      if (!json) throw "";
      return JSON.parse(json);
    } catch {
      return getDefaultFilter(areas);
    }
  };

  useEffect(() => {
    refreshReservations();
  }, [filter?.month]);

  const refresh = async () => {
    try {
      const areas = await userCtx.webserviceClient.areas.getAll(
        userCtx.user.colony._id
      );

      setAreas(areas);
      const filter = loadFilter(areas);
      setFilter(filter);
    } catch (ex) {
      handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleEventClick = (info) => {
    navigate(`./${info.event.extendedProps._id}`);
  };

  const handleToggleFilterClicked = () => {
    setShowFilter(!showFilter);
  };

  const handleResetFilterClicked = () => {
    saveFilter(getDefaultFilter(areas));
  };

  const handleNewClicked = () => {
    navigate("./nueva");
  };

  if (!filter) return null;

  const reservations2 = reservations.filter((r) =>
    filter.areaIds.includes(r.areaId)
  );

  return (
    <div>
      <h1>Calendario</h1>
      <div>
        <div className="d-flex justify-content-between">
          <div>
            {userCtx.user.role.permissions.includes("reservations.edit") && (
              <button className="btn btn-primary" onClick={handleNewClicked}>
                Nueva
              </button>
            )}
          </div>
          <div style={{ fontSize: 22, fontWeight: "bold" }}>{filter.month}</div>
          <div className="btn-group">
            <button
              className="btn btn-primary"
              onClick={handleToggleFilterClicked}
            >
              <FontAwesomeIcon icon={faFilter} />
            </button>
            {JSON.stringify(getDefaultFilter(areas)) !==
              JSON.stringify(filter) && (
              <button
                className="btn btn-danger"
                onClick={handleResetFilterClicked}
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            )}
          </div>
        </div>
        <div style={{ display: showFilter ? "block" : "none" }}>
          <div className="mb-2">
            <label className="form-label">Mes</label>
            <div>
              <TextInput
                type="month"
                value={filter.month}
                onChange={(value) => saveFilter({ ...filter, month: value })}
              />
            </div>
          </div>
          <div className="mb-2">
            <label className="form-label">Areas</label>
            <div>
              <MultiselectInput
                items={areas}
                nameExtractor={(a) => a.name}
                valueExtractor={(a) => a._id}
                values={filter.areaIds}
                onChange={(values) =>
                  saveFilter({ ...filter, areaIds: values })
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="mt-2"
        style={{
          display: "flex",
          flexDirection: "row",
          fontSize: ".9em",
        }}
      >
        {areas.map((a) => (
          <div
            key={a._id}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              className="fc-daygrid-event-dot"
              style={{
                borderColor: a.color,
              }}
            ></div>
            <div className="fc-event-title">{a.name}</div>
          </div>
        ))}
      </div>

      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        locale="es"
        ref={calenderRef}
        headerToolbar={false}
        displayEventEnd={true}
        // dateClick={handleDateClick}
        eventClick={handleEventClick}
        events={reservations2}
      />
    </div>
  );
};
