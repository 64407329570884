const util = {
  /**
   *
   * @param {Object} object
   * @param {String} path
   * @param {Any} value
   * @returns Any
   */
  setValue: (object, path, value) => {
    const segments = path.split(".");
    let current = object;

    for (var i = 0; i < segments.length; i++) {
      if (i === segments.length - 1) {
        current[segments[i]] = value;
      } else {
        current = current[segments[i]];
      }
    }

    return object;
  },
  /**
   *
   * @param {Object} object
   * @param {String} path
   * @returns Any
   */
  getValue: (object, path) => {
    if (!path) return undefined;

    const segments = path.split(".");

    let current = object;

    for (var segment of segments) {
      if (current === undefined) return undefined;
      current = current[segment];
    }

    return current;
  },
};

export default util;
