const environment = process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV;

const environments = {
  development: "development",
  production: "production",
  staging: "staging",
};

if (!environment) {
  throw new Error("REACT_APP_NODE_ENV/NODE_ENV not set");
}

const isEnvironment = (environment2) => {
  return environment === environment2;
};

const util = {
  environments,
  isEnvironment,
  environment,
  getValue: (productionValue, stagingValue, developmentValue) => {
    if (util.isEnvironment(environments.production)) return productionValue;
    if (util.isEnvironment(environments.staging)) return stagingValue;
    if (util.isEnvironment(environments.development)) return developmentValue;
  },
};

export default util;
