import { Fragment, useEffect, useState } from "react";

export default ({
  readonly,
  values,
  disabled,
  onChange,
  items,
  sectionValueExtractor,
  sectionNameExtractor,
  valueExtractor,
  nameExtractor,
}) => {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    const sections = [];
    let section = { value: undefined };

    items.sort((a, b) =>
      (sectionNameExtractor(a) + "|" + nameExtractor(a)).localeCompare(
        sectionNameExtractor(b) + "|" + b.nameExtractor
      )
    );

    for (var item of items) {
      const value = sectionValueExtractor(item);

      if (section.value !== value) {
        const name = sectionNameExtractor(item);
        section = { value, name, items: [] };
        sections.push(section);
      }
      section.items.push(item);
    }

    sections.sort((a, b) => a.name.localeCompare(b.name));

    setSections(sections);
  }, [items, readonly]);

  const toggle = (value, checked) => {
    if (checked) {
      values = [...values, value];
      onChange(values);
    } else {
      values = values.filter((v) => v !== value);
      onChange(values);
    }
  };

  if (readonly) {
    return (
      <label className="form-control-plaintext">
        {values.map((v) => {
          const item = items.find((i) => valueExtractor(i) === v);
          if (!item) return null;
          return (
            <div>{sectionNameExtractor(item) + ": " + nameExtractor(item)}</div>
          );
        })}
      </label>
    );
  } else {
    return (
      <div className="form-control">
        {sections.map((s) => {
          return (
            <Fragment key={s.name}>
              <label className="form-label">{s.name}</label>
              <div>
                {s.items.map((i) => (
                  <div className="form-check" key={valueExtractor(i)}>
                    <input
                      disabled={disabled}
                      className="form-check-input"
                      type="checkbox"
                      checked={values.includes(valueExtractor(i))}
                      onChange={(e) =>
                        toggle(valueExtractor(i), e.target.checked)
                      }
                    />
                    <label className="form-check-label">
                      {nameExtractor(i)}
                    </label>
                  </div>
                ))}
              </div>
            </Fragment>
          );
        })}
        {/* {items.map((i) => (
          <div className="form-check" key={valueExtractor(i)}>
            <input
              disabled={disabled}
              className="form-check-input"
              type="checkbox"
              checked={values.includes(valueExtractor(i))}
              onChange={(e) => toggle(valueExtractor(i), e.target.checked)}
            />
            <label className="form-check-label">{nameExtractor(i)}</label>
          </div>
        ))} */}
      </div>
    );
  }
};
