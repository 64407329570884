import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import arrayUtil from "../../../../utils/array.util";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import FileViewerModal from "../../../controls/fileViewer.modal";
import ConfirmModal from "../../../controls/confirm.modal";
import FileInput from "../../../controls/file.input";
import TextInput from "../../../controls/text.input";
import { useMessage } from "../../../contexts/message.context";

const TransactionPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const userCtx = useUser();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [colony, setColony] = useState();
  const [transaction, setTransaction] = useState();
  const [isWorking, setIsWorking] = useState(false);
  //const [confirmDelete, setConfirmDelete] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showImageViewerModal, setShowImageViewerModal] = useState(false);

  useEffect(() => {
    const refresh = async () => {
      try {
        const colony = await userCtx.webserviceClient.colonies.getById(
          userCtx.user.colony._id
        );
        const transaction =
          await userCtx.webserviceClient.colonyTransactions.getById(
            userCtx.user.colony._id,
            params.transactionId
          );
        setColony(colony);
        setTransaction(transaction);
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };

    refresh();
  }, [userCtx.user, navigate, params.transactionId]);

  const handleConfirmDeleteModalClose = async (confirm) => {
    setShowConfirmDeleteModal(false);
    if (!confirm) return;

    try {
      setIsWorking(true);
      await userCtx.webserviceClient.colonyTransactions.delete(
        userCtx.user.colony._id,
        params.transactionId
      );
      messageCtx.showSuccess("Movimiento borrado");
      navigate("./..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  if (!colony || !transaction) return null;

  const lastEvent = arrayUtil.last(colony.events);
  const minDate = lastEvent ? lastEvent.date : "";

  return (
    <div>
      <h1>Movimiento</h1>

      <div className="mb-2">
        <label className="form-label">Tipo</label>
        <TextInput value={transaction.type} readonly={true} />
      </div>

      <div className="mb-2">
        <label className="form-label">Fecha de pago</label>
        <TextInput value={transaction.payDate} readonly={true} />
      </div>

      <div className="mb-2">
        <label className="form-label">Fecha de proceso</label>
        <TextInput value={transaction.processDate} readonly={true} />
      </div>

      <div className="mb-2">
        <label className="form-label">Descripción</label>
        <TextInput value={transaction.description} readonly={true} />
      </div>

      <div className="mb-2">
        <label className="form-label">Cantidad</label>
        <TextInput value={transaction.amount.toFixed(2)} readonly={true} />
      </div>

      {transaction.receipt && (
        <div className="mb-2">
          <label className="form-label">Recibo</label>
          <FileInput
            value={transaction.receipt}
            readonly={true}
            showSeeImageButton={true}
          />
        </div>
      )}

      {userCtx.user.role.permissions.includes("colonyTransactions.edit") &&
        transaction.payDate >= minDate && (
          <div>
            <button
              className="btn btn-danger"
              disabled={isWorking}
              onClick={() => setShowConfirmDeleteModal(true)}
            >
              Borrar
            </button>
          </div>
        )}

      <FileViewerModal
        show={showImageViewerModal}
        setShow={setShowImageViewerModal}
        images={[transaction.receipt]}
      />

      <ConfirmModal
        show={showConfirmDeleteModal}
        onClose={handleConfirmDeleteModalClose}
        cancelButtonText="No"
        confirmButtonText="Sí"
        titleText="Borrar movimiento"
        questionText="Seguro que quieres borrar movimiento?"
      />
    </div>
  );
};

export default TransactionPage;
