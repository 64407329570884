import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";

import reportsUtil from "../../../../utils/reports.util";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import MonthInput from "../../../controls/month.input";
import SelectInput from "../../../controls/select.input";
import NumberInput from "../../../controls/number.input";
import DateInput from "../../../controls/date.input";
import { useMessage } from "../../../contexts/message.context";

const groupBy = (list, keyGetter) => {
  const o = {};
  list.forEach((item) => {
    const key = keyGetter(item);
    let collection = o[key];
    if (!collection) {
      collection = [];
      o[key] = collection;
    }
    collection.push(item);
  });
  return o;
};

const ReportsPage = () => {
  let { state } = useLocation();

  const userCtx = useUser();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [isLoading, setIsLoading] = useState(true);
  const [joinHouseTransactions, setJoinHouseTransactions] = useState(true);
  const [reportTypes, setReportTypes] = useState([]);
  const [reportTypeId, setReportTypeId] = useState();
  const [month, setMonth] = useState(DateTime.now().toFormat("yyyy-MM"));
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [accountStatusTypes, setAccountStatusTypes] = useState([]);
  const [accountStatusType, setAccountStatusType] = useState();
  const [accountTypes, setAccountTypes] = useState([]);
  const [accountType, setAccountType] = useState();
  const [accounts, setAccounts] = useState([]);
  const [accountId, setAccountId] = useState();
  const [houseAccountId, setHouseAccountId] = useState();
  const [startEvents, setStartEvents] = useState([]);
  const [endEvents, setEndEvents] = useState([]);
  const [startEventId, setStartEventId] = useState();
  const [intervalType, setIntervalType] = useState("Mes");
  const [endEventId, setEndEventId] = useState();
  const [startDate, setStartDate] = useState(
    DateTime.now().minus({ years: 1 }).toISODate()
  );
  const [endDate, setEndDate] = useState(DateTime.now().toISODate());
  const [report, setReport] = useState();
  const [showFilter, setShowFilter] = useState(true);

  useEffect(() => {
    const refresh = async () => {
      try {
        setIsLoading(true);
        let error;
        const promiseAccountTypes =
          userCtx.webserviceClient.colonyAccounts.getTypes();

        const promiseAccountStatusTypes =
          userCtx.webserviceClient.reports.getAccountStatusTypes();
        const promiseAccounts = userCtx.webserviceClient.reports.getAccounts(
          userCtx.user.colony._id
        );
        const promiseReportTypes = userCtx.webserviceClient.reports.getTypes();

        let [accountTypes, accountStatusTypes, accounts, reportTypes] =
          await Promise.all([
            promiseAccountTypes,
            promiseAccountStatusTypes,
            promiseAccounts,
            promiseReportTypes,
          ]);

        accounts = accounts.sort((a, b) => {
          const result = a.type.localeCompare(b.type);
          if (result !== 0) return result;

          return a.name.localeCompare(b.name);
        });

        let accountTypeId;
        setAccountTypes(accountTypes);
        if (accountTypes.length > 0) {
          accountTypeId = accountTypes[0].id;
        }
        setAccountType(accountTypeId);

        let accountStatusType;
        setAccountStatusTypes(accountStatusTypes);
        if (accountStatusTypes.length > 0) {
          accountStatusType = accountStatusTypes[0];
        }
        setAccountStatusType(accountStatusType);

        let accountId;
        setAccounts(accounts);
        if (accounts.length > 0) {
          accountId = accounts[0]._id;
        }
        setAccountId(accountId);

        reportTypes.sort((a, b) => a.name.localeCompare(b.name));
        let reportTypeId;
        setReportTypes(reportTypes);
        if (reportTypes.length > 0) {
          reportTypeId = reportTypes[0].id;
        }

        if (state?.intervalType) {
          setIntervalType(state.intervalType);
        }

        if (state?.reportTypeId) {
          if (reportTypes.some((t) => t.id === state.reportTypeId)) {
            reportTypeId = state.reportTypeId;
          } else {
            error = "Tipo de reporte no encontrado";
          }
        }
        setReportTypeId(reportTypeId);

        let houseAccountId;
        if (state?.houseAccountId) {
          houseAccountId = state.houseAccountId;
        } else if (userCtx.user.house) {
          houseAccountId = userCtx.user.house.accountId;
        } else {
          const houseAccounts = accounts.filter((a) => a.type === "Casa");
          if (houseAccounts.length) {
            houseAccountId = houseAccounts[0]._id;
          }
        }

        setHouseAccountId(houseAccountId);

        if (error) {
          messageCtx.showError(error);
        } else if (state?.generate) {
          await refreshReport(
            reportTypes,
            reportTypeId,
            accountStatusType,
            accountId,
            accountStatusType,
            houseAccountId,
            joinHouseTransactions
          );
        }
      } catch (ex) {
        exceptionCtx.handleException(ex);
      } finally {
        setIsLoading(false);
      }
    };

    refresh();
  }, [userCtx.user]);

  useEffect(() => {
    const refresh = async () => {
      setStartEvents([]);
      setEndEvents([]);
      setEndEventId("");
      setStartEventId("");
      if (!reportTypeId) return;
      if (!accountStatusType) return;

      const reportType = reportTypes.find((t) => t.id === reportTypeId);

      if (reportType.pickInterval) {
        if (accountStatusType === "Cuenta") {
          const events =
            await userCtx.webserviceClient.reports.getEventsByAccount(
              userCtx.user.colony._id,
              accountId
            );

          const todayEvent = {
            _id: "hoy",
            date: DateTime.now().toFormat("yyyy-MM-dd"),
            type: "Hoy",
          };

          const endEvents = [...events, todayEvent];
          setStartEvents(events);
          setEndEvents(endEvents);
          setEndEventId(todayEvent._id);

          if (events.length > 0) {
            setStartEventId(events[0]._id);
          }
        } else if (accountStatusType === "Tipo de cuenta (cuentas unidas)") {
          const events =
            await userCtx.webserviceClient.reports.getEventsByAccountType(
              userCtx.user.colony._id,
              accountType
            );
          const todayEvent = {
            _id: "hoy",
            date: DateTime.now().toFormat("yyyy-MM-dd"),
            type: "Hoy",
          };

          const endEvents = [...events, todayEvent];
          setStartEvents(events);
          setEndEvents(endEvents);
          setEndEventId(todayEvent._id);

          if (events.length > 0) {
            setStartEventId(events[0]._id);
          }
        }
      }
    };
    refresh();
  }, [reportTypeId, accountId, accountStatusType, accountType]);

  const getStartAndEndDate = (reportTypes, reportTypeId) => {
    if (reportTypeId) {
      const reportType = reportTypes.find((t) => t.id === reportTypeId);

      if (reportType.pickInterval) {
        if (intervalType === "Año") {
          return {
            startDate: DateTime.fromFormat(year, "yyyy").toISODate(),
            endDate: DateTime.fromFormat(year, "yyyy")
              .plus({ years: 1 })
              .toISODate(),
          };
        } else if (intervalType === "Mes") {
          return {
            startDate: DateTime.fromFormat(month, "yyyy-MM").toISODate(),
            endDate: DateTime.fromFormat(month, "yyyy-MM")
              .plus({ months: 1 })
              .toISODate(),
          };
        } else if (intervalType === "Evento") {
          const startEvent = startEvents.find((e) => e._id === startEventId);
          const endEvent = endEvents.find((e) => e._id === endEventId);
          return {
            startDate: startEvent.date,
            endDate: DateTime.fromISO(endEvent.date)
              .plus({ days: 1 })
              .toISODate(),
          };
        } else if (intervalType === "Fecha") {
          return {
            startDate,
            endDate: DateTime.fromISO(endDate).plus({ days: 1 }).toISODate(),
          };
        }
      }
    }
    return { startDate: "", endDate: "" };
  };

  const refreshReport = async (
    reportTypes,
    reportTypeId,
    accountStatusType,
    accountId,
    accountType,
    houseAccountId,
    joinHouseTransactions
  ) => {
    const { startDate, endDate } = getStartAndEndDate(
      reportTypes,
      reportTypeId
    );

    try {
      const report = await userCtx.webserviceClient.reports.generate(
        reportTypeId,
        userCtx.user.colony._id,
        accountStatusType,
        accountId,
        accountType,
        houseAccountId,
        startDate,
        endDate,
        joinHouseTransactions
      );
      setReport(report);
      messageCtx.showSuccess("Reporte generado");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleGeneratePressed = async () => {
    await refreshReport(
      reportTypes,
      reportTypeId,
      accountStatusType,
      accountId,
      accountType,
      houseAccountId,
      joinHouseTransactions
    );
  };

  const handlePDFPressed = async () => {
    try {
      const name = `${DateTime.now().toFormat("yyyy-MM-dd")} - ${report.name}`;
      const fileName = `${name}.pdf`;

      reportsUtil.generatePdf(report, fileName);

      messageCtx.showSuccess("Pdf generado");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleXLSX2Pressed = () => {
    try {
      const name = `${DateTime.now().toFormat("yyyy-MM-dd")} - ${report.name}`;
      const fileName = `${name}.xlsx`;

      reportsUtil.generateExcel(report, fileName);

      messageCtx.showSuccess("Xlsx generado");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const generateReport = () => {
    if (!report) return;

    const list = reportsUtil.generateHtml(report);

    return (
      <div>
        <h2>{report.name}</h2>
        {list}
      </div>
    );
  };

  const isFormOk = () => {
    if (!reportTypeId) return false;
    const reportType = reportTypes.find((t) => t.id === reportTypeId);

    if (reportType?.pickEvent) {
      if (!startEventId) return false;
      if (!endEventId) return false;

      const startEvent = startEvents.find((e) => e._id === startEventId);
      const endEvent = endEvents.find((e) => e._id === endEventId);
      if (startEvent.date >= endEvent.date) return false;
    }

    return true;
  };

  const handleFilterClicked = (e) => {
    setShowFilter(!showFilter);
    e.preventDefault();
  };

  const generateForm = () => {
    if (!reportTypeId) return null;

    const getEventText = (e) => {
      if (e.description) {
        return `${e.date} - ${e.type} (${e.description})`;
      } else {
        return `${e.date} - ${e.type}`;
      }
    };

    const selectedType = reportTypes.find((t) => t.id === reportTypeId);

    const accountTypesMap = groupBy(accounts, (a) => a.type);

    return (
      <div>
        <div
          className="my-2"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <button
              className="btn btn-primary"
              value="Generar"
              disabled={!isFormOk()}
              onClick={handleGeneratePressed}
            >
              Generar
            </button>
            {report && (
              <>
                <button
                  className="btn btn-success ms-2"
                  onClick={handlePDFPressed}
                >
                  PDF
                </button>
                <button
                  className="btn btn-success ms-2"
                  onClick={handleXLSX2Pressed}
                >
                  XLSX
                </button>
              </>
            )}
          </div>
          <div>
            <button className="btn btn-primary" onClick={handleFilterClicked}>
              <FontAwesomeIcon icon={faFilter} />
            </button>
          </div>
        </div>

        <div style={{ display: showFilter ? "block" : "none" }}>
          <div className="mb-2">
            <label className="form-label">Tipo de reporte</label>
            <select
              className="form-select"
              value={reportTypeId}
              onChange={(e) => setReportTypeId(e.target.value)}
            >
              {reportTypes.map((t) => (
                <option key={t.id} value={t.id}>
                  {t.name}
                </option>
              ))}
            </select>
          </div>

          {selectedType.pickHouseAccount && (
            <div className="mb-2">
              <label className="form-label">Casa</label>
              <select
                className="form-select"
                value={houseAccountId}
                onChange={(e) => setHouseAccountId(e.target.value)}
              >
                {accountTypesMap["Casa"].map((a) => (
                  <option key={a._id} value={a._id}>
                    {a.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {selectedType.pickAccount && (
            <div>
              <div className="mb-2">
                <label className="form-label">Tipo de estado de cuenta</label>
                <select
                  className="form-select"
                  value={accountStatusType}
                  onChange={(e) => setAccountStatusType(e.target.value)}
                >
                  {accountStatusTypes.map((b) => (
                    <option key={b} value={b}>
                      {b}
                    </option>
                  ))}
                </select>
              </div>

              {[
                "Tipo de cuenta (cuentas unidas)",
                "Tipo de cuenta (cuentas separadas)",
              ].includes(accountStatusType) && (
                <div className="mb-2">
                  <label className="form-label">Tipo de cuenta</label>
                  <select
                    className="form-select"
                    value={accountType}
                    onChange={(e) => setAccountType(e.target.value)}
                  >
                    {accountTypes.map((t) => (
                      <option key={t.id} value={t.id}>
                        {t.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {accountStatusType === "Cuenta" && (
                <div className="mb-2">
                  <label className="form-label">Cuenta</label>
                  <select
                    className="form-select"
                    value={accountId}
                    onChange={(e) => setAccountId(e.target.value)}
                  >
                    {Object.keys(accountTypesMap).map((t) => (
                      <optgroup label={t} key={t}>
                        {accountTypesMap[t].map((a) => (
                          <option key={a._id} value={a._id}>
                            {a.name}
                          </option>
                        ))}
                      </optgroup>
                    ))}
                  </select>
                </div>
              )}
            </div>
          )}

          {selectedType.pickMonth && (
            <div className="mb-2">
              <label className="form-label">Mes</label>
              <MonthInput value={month} onChange={setMonth} />
            </div>
          )}
          {selectedType.pickInterval && (
            <div>
              <div className="mb-2">
                <label className="form-label">Tipo de interval</label>
                <SelectInput
                  value={intervalType}
                  onChange={setIntervalType}
                  items={["Año", "Mes", "Evento", "Fecha"]}
                  nameExtractor={(i) => i}
                  valueExtractor={(i) => i}
                />
              </div>
              {intervalType === "Año" && (
                <div className="mb-2">
                  <label className="form-label">Año</label>
                  <NumberInput
                    value={year}
                    onChange={setYear}
                    min="2015"
                    max={DateTime.now().toFormat("yyyy")}
                  />
                </div>
              )}
              {intervalType === "Mes" && (
                <div className="mb-2">
                  <label className="form-label">Mes</label>
                  <MonthInput value={month} onChange={setMonth} />
                </div>
              )}
              {intervalType === "Evento" && (
                <>
                  <div className="mb-2">
                    <label className="form-label">Empieza</label>
                    <SelectInput
                      items={startEvents}
                      nameExtractor={(e) => getEventText(e)}
                      valueExtractor={(e) => e._id}
                      value={startEventId}
                      onChange={setStartEventId}
                    />
                  </div>
                  <div className="mb-2">
                    <label className="form-label">Termina</label>
                    <SelectInput
                      items={endEvents}
                      nameExtractor={(e) => getEventText(e)}
                      valueExtractor={(e) => e._id}
                      value={endEventId}
                      onChange={setEndEventId}
                    />
                  </div>
                </>
              )}
              {intervalType === "Fecha" && (
                <>
                  <div className="mb-2">
                    <label className="form-label">Empieza</label>
                    <DateInput value={startDate} onChange={setStartDate} />
                  </div>
                  <div className="mb-2">
                    <label className="form-label">Termina</label>
                    <DateInput value={endDate} onChange={setEndDate} />
                  </div>
                </>
              )}
            </div>
          )}
          {selectedType.pickJoinHouseTransactions && !userCtx.user.house && (
            <div className="mb-2">
              <label className="form-label">Unir movimientos de casa</label>
              <SelectInput
                items={[
                  { name: "Sí", value: "1" },
                  { name: "No", value: "0" },
                ]}
                nameExtractor={(e) => e.name}
                valueExtractor={(e) => e.value}
                value={joinHouseTransactions ? "1" : "0"}
                onChange={(value) => setJoinHouseTransactions(value === "1")}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  if (isLoading) return null;

  return (
    <div>
      <h1>Reportes</h1>
      {generateForm()}
      {generateReport()}
    </div>
  );
};

export default ReportsPage;
