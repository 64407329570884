import Modal from "react-bootstrap/Modal";

export default ({ show, setShow, onSelect, templates }) => {
  const handleTemplateClicked = (template) => {
    onSelect(template);
    setShow(false);
  };

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Cargar plantilla</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-striped table-clickable">
          <thead>
            <tr>
              <th>Nombre</th>
            </tr>
          </thead>
          <tbody>
            {templates.map((t) => (
              <tr onClick={() => handleTemplateClicked(t)} key={t._id}>
                <td>{t.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
};
