import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DateTime } from "luxon";

import DateInput from "../../../controls/date.input";
import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import SelectInput from "../../../controls/select.input";
import TextInput from "../../../controls/text.input";
import InformationButton from "../../../controls/information.button";
import { useMessage } from "../../../contexts/message.context";

export default () => {
  const navigate = useNavigate();
  const params = useParams();

  const userCtx = useUser();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [house, setHouse] = useState();
  const [types, setTypes] = useState([]);
  const [oldEvent, setOldEvent] = useState();
  const [newEvent, setNewEvent] = useState();
  const [isWorking, setIsWorking] = useState(false);
  const [balance, setBalance] = useState();

  const isReadonly = !userCtx.user.role.permissions.includes("events.edit");

  useEffect(() => {
    if (isReadonly) return;
    if (!newEvent) return;

    const refresh = async () => {
      try {
        setBalance();

        const { balance } =
          await userCtx.webserviceClient.colonyAccounts.getBalance(
            userCtx.user.colony._id,
            house.accountId,
            newEvent.date
          );

        setBalance(balance);
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };

    refresh();
  }, [newEvent?.date, userCtx.user]);

  const refresh = async () => {
    try {
      const types = await userCtx.webserviceClient.houses.getEventTypes();
      setTypes(types);

      const house = await userCtx.webserviceClient.houses.getById(
        userCtx.user.colony._id,
        params.houseId
      );
      setHouse(house);

      if (params.eventId) {
        const event = await userCtx.webserviceClient.houses.getEventById(
          userCtx.user.colony._id,
          params.houseId,
          params.eventId
        );

        //const event = house.events.find((e) => e._id === eventId);
        setOldEvent(event);
        setNewEvent({ ...event });
      } else {
        setNewEvent({
          type: types[0].name,
          date: DateTime.now().toISODate(),
          description: "",
        });
      }
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleCreateClicked = async () => {
    try {
      setIsWorking(true);
      await userCtx.webserviceClient.houses.insertEvent(
        userCtx.user.colony._id,
        params.houseId,
        newEvent.type,
        newEvent.date,
        newEvent.description
      );
      messageCtx.showSuccess("Evento guardado");
      navigate("./../..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const handleDeleteClicked = async () => {
    try {
      setIsWorking(true);
      await userCtx.webserviceClient.houses.deleteEvent(
        userCtx.user.colony._id,
        params.houseId,
        params.eventId
      );
      messageCtx.showSuccess("Evento borrado");
      navigate("./../..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const isFormOk = () => {
    if (!newEvent.type) return false;
    if (!newEvent.description) return false;
    if (!newEvent.date) return false;

    return true;
  };

  if (types.length === 0) return null;
  if (!newEvent) return null;

  const type2 = types.find((t) => t.name === newEvent.type);

  // Asegurase de registrar todos los movimientos antes de registrar un nuevo evento. No se puede agregar/borrar un movimiento de un evento anterior.
  return (
    <div>
      <h1>Evento de casa</h1>
      <div>
        <div>
          <label className="form-label">Casa direccíon</label>
          <TextInput value={house.address} readonly={true} />
        </div>
        {!isReadonly && !oldEvent && (
          <div className="mt-2 form-control-plaintext">
            <div className="fst-italic">
              Asegurase de registrar todos los movimientos de la casa antes de
              registrar un nuevo evento. No se puede agregar/borrar movimientos
              antes del ultimo evento registrado.
            </div>
            <div className="fst-italic">
              El saldo se traspasa al nuevo propietario/inquilino. Si el saldo
              no encaja, registre nuevos movimientos antes de crear un nuevo
              evento.
            </div>
          </div>
        )}
        <div className="mt-2">
          <label className="form-label">Tipo</label>
          <SelectInput
            value={newEvent.type}
            disabled={isWorking}
            items={types}
            nameExtractor={(i) => i.name}
            valueExtractor={(i) => i.name}
            readonly={
              !userCtx.user.role.permissions.includes("houses.edit") || oldEvent
            }
            onChange={(value) => setNewEvent({ ...newEvent, type: value })}
          />
        </div>

        <div className="mt-2">
          <label className="form-label">
            Fecha{" "}
            <InformationButton
              title="Fecha"
              subjects={[
                {
                  description: "Estado de cuentas inician en este fecha.",
                },
              ]}
            />
          </label>
          <DateInput
            value={newEvent.date}
            onChange={(value) => setNewEvent({ ...newEvent, date: value })}
            disabled={isWorking}
            min={DateTime.fromISO(newEvent.date)
              .minus({ days: type2.canChangeDays })
              .toISODate()}
            max={DateTime.now().toISODate()}
            readonly={
              !userCtx.user.role.permissions.includes("houses.edit") || oldEvent
            }
          />
        </div>
        {balance !== undefined && !isReadonly && !oldEvent && (
          <div className="mt-2 form-control-plaintext">
            <div>El saldo de este fecha es de {balance.toFixed(2)}</div>
          </div>
        )}

        <div className="mt-2">
          <label className="form-label">Descripción</label>
          <TextInput
            value={newEvent.description}
            disabled={isWorking}
            readonly={
              !userCtx.user.role.permissions.includes("houses.edit") || oldEvent
            }
            onChange={(value) =>
              setNewEvent({ ...newEvent, description: value })
            }
          />
        </div>

        <div className="mt-2">
          {!oldEvent && (
            <button
              className="btn btn-primary me-2"
              onClick={handleCreateClicked}
              disabled={!isFormOk() || isWorking}
            >
              Guardar
            </button>
          )}
          {oldEvent &&
            newEvent.canChangeUntilDate >= DateTime.now().toISODate() && (
              <button
                className="btn btn-danger me-2"
                disabled={isWorking}
                onClick={handleDeleteClicked}
              >
                Borrar
              </button>
            )}
        </div>
      </div>
    </div>
  );
};
