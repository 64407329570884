import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DateTime } from "luxon";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import SelectInput from "../../../controls/select.input";
import DateInput from "../../../controls/date.input";
import TextInput from "../../../controls/text.input";
import ConfirmModal from "../../../controls/confirm.modal";
import InformationButton from "../../../controls/information.button";
import { useMessage } from "../../../contexts/message.context";

export default () => {
  const navigate = useNavigate();
  const params = useParams();

  const userCtx = useUser();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [types, setTypes] = useState([]);
  const [oldEvent, setOldEvent] = useState();
  const [newEvent, setNewEvent] = useState();

  const [isWorking, setIsWorking] = useState(false);
  const [balance, setBalance] = useState();

  useEffect(() => {
    if (!newEvent) return;

    const refresh = async () => {
      try {
        setBalance();

        const { balance } =
          await userCtx.webserviceClient.colonyAccounts.getBalanceByTypeAndDate(
            userCtx.user.colony._id,
            "Colonia",
            newEvent.date
          );

        setBalance(balance);
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };

    refresh();
  }, [newEvent?.date, userCtx.user]);

  useEffect(() => {
    const refresh = async () => {
      const types = await userCtx.webserviceClient.colonies.getEventTypes();
      setTypes(types);

      if (params.eventId) {
        const event = await userCtx.webserviceClient.colonies.getEventById(
          userCtx.user.colony._id,
          params.eventId
        );

        setOldEvent(event);
        setNewEvent({ ...event });
      } else {
        setNewEvent({
          type: "Nueva mesa",
          description: "",
          date: DateTime.now().toISODate(),
          // we add 3 days to allow time to create new event
          canChangeUntilDate: DateTime.now().plus({ days: 3 }).toISODate(),
        });
      }
    };

    refresh();
  }, []);

  const isFormOk = () => {
    if (!newEvent.type) return false;
    if (!newEvent.description) return false;
    if (!newEvent.date) return false;

    return true;
  };

  const handleUpdateClicked = async () => {
    try {
      setIsWorking(true);
      await userCtx.webserviceClient.colonies.updateEvent(
        userCtx.user.colony._id,
        newEvent._id,
        newEvent.type,
        newEvent.date,
        newEvent.description
      );
      messageCtx.showSuccess("Evento guardado");
      navigate("./..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const handleCreateClicked = async () => {
    try {
      setIsWorking(true);
      await userCtx.webserviceClient.colonies.insertEvent(
        userCtx.user.colony._id,
        newEvent.type,
        newEvent.date,
        newEvent.description
      );
      messageCtx.showSuccess("Evento guardado");
      navigate("./..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const handleConfirmDeleteModalClose = async (confirm) => {
    setShowConfirmDeleteModal(false);
    if (!confirm) return;

    try {
      setIsWorking(true);
      await userCtx.webserviceClient.colonies.deleteEvent(
        userCtx.user.colony._id,
        params.eventId
      );
      messageCtx.showSuccess("Evento borrado");
      navigate("./..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  if (types.length === 0) return null;
  if (!newEvent) return null;

  const type2 = types.find((t) => t.name === newEvent.type);

  return (
    <div>
      <h1>{oldEvent ? "Evento" : "Nuevo evento"}</h1>
      <div>
        {userCtx.user.role.permissions.includes("events.edit") && !oldEvent && (
          <div className="mt-2 form-control-plaintext">
            <div className="fst-italic">
              Asegurase de registrar todos los movimientos de la colonia y de
              las casas antes de registrar un nuevo evento. No se puede
              agregar/borrar movimientos antes del ultimo evento registrado.
            </div>
            <div className="fst-italic">
              El saldo se traspasa a la nueva mesa. Si el saldo no encaja,
              registre nuevos movimientos antes de crear un nuevo evento.
            </div>
          </div>
        )}

        <div>
          <label className="form-label">Tipo</label>
          <SelectInput
            items={types}
            disabled={isWorking}
            nameExtractor={(e) => e.name}
            valueExtractor={(e) => e.name}
            value={newEvent.type}
            onChange={(value) => setNewEvent({ ...newEvent, type: value })}
            readonly={
              !userCtx.user.role.permissions.includes("events.edit") || oldEvent
            }
          />
        </div>
        <div className="mt-2">
          <label className="form-label">
            Fecha{" "}
            <InformationButton
              title="Fecha"
              subjects={[
                {
                  description: "Estado de cuentas inician en este fecha.",
                },
              ]}
            />
          </label>
          <DateInput
            value={newEvent.date}
            onChange={(value) => setNewEvent({ ...newEvent, date: value })}
            min={DateTime.fromISO(newEvent.date)
              .minus({ days: type2.canChangeDays })
              .toISODate()}
            max={DateTime.now().toISODate()}
            disabled={isWorking}
            readonly={
              !userCtx.user.role.permissions.includes("events.edit") || oldEvent
            }
          />
        </div>
        {newEvent.type === "Nueva mesa" &&
          balance !== undefined &&
          userCtx.user.role.permissions.includes("events.edit") &&
          !oldEvent && (
            <div className="mt-2 form-control-plaintext">
              <div>El saldo de este fecha es de {balance.toFixed(2)}</div>
            </div>
          )}
        <div className="mt-2">
          <label className="form-label">Descripción</label>
          <TextInput
            value={newEvent.description}
            onChange={(value) =>
              setNewEvent({ ...newEvent, description: value })
            }
            disabled={isWorking}
            readonly={
              !userCtx.user.role.permissions.includes("events.edit") || oldEvent
            }
          />
        </div>

        <div className="mt-2">
          {!oldEvent && (
            <button
              className="btn btn-primary me-2"
              onClick={handleCreateClicked}
              disabled={!isFormOk() || isWorking}
            >
              Guardar
            </button>
          )}
          {userCtx.user.role.permissions.includes("events.edit") &&
            DateTime.now().toISODate() <= newEvent.canChangeUntilDate &&
            oldEvent && (
              <button
                className="btn btn-danger"
                disabled={isWorking}
                onClick={() => setShowConfirmDeleteModal(true)}
              >
                Borrar
              </button>
            )}
        </div>
      </div>
      <ConfirmModal
        cancelButtonText="No"
        confirmButtonText="Sí"
        titleText="Borrar evento"
        questionText="Seguro que quieres borrar evento?"
        show={showConfirmDeleteModal}
        onClose={handleConfirmDeleteModalClose}
      />
    </div>
  );
};
