import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";

import settings from "../../../../utils/settings";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import { useMessage } from "../../../contexts/message.context";

const JobPage = () => {
  const params = useParams();

  const userCtx = useUser();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [job, setJob] = useState();

  const refresh = async () => {
    try {
      const job = await userCtx.webserviceClient.jobs.getById(params.jobId);
      job.results.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
      setJob(job);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleEnableClicked = async () => {
    try {
      await userCtx.webserviceClient.jobs.updateEnabled(job._id, true);

      messageCtx.showSuccess("Job enabled");

      await refresh();
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleDisableClicked = async () => {
    try {
      await userCtx.webserviceClient.jobs.updateEnabled(job._id, false);

      messageCtx.showSuccess("Job disabled");

      await refresh();
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleRunNowClicked = async () => {
    try {
      await userCtx.webserviceClient.jobs.runNowById(job._id);

      messageCtx.showSuccess("Job will run soon as possible");

      await refresh();
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const getResultIconColor = (result) => {
    if (result.result === "Fejl") return "red";
    else if (result.result === "Success") return "green";
    else return "white";
  };

  const getStatus = (job) => {
    if (!job.enabled) return "Deactive";
    else {
      if (job.processAt) {
        return `Corre - ${DateTime.fromISO(job.processAt).toFormat(
          settings.formats.datetime
        )}`;
      } else {
        return "Terminado";
      }
    }
  };

  const getJobIconColor = (job) => {
    if (job.results.length === 0) return "gray";

    const lastResult = job.results[job.results.length - 1];
    if (lastResult.result === "Fejl" && job.processAt) return "orange";
    else if (lastResult.result === "Fejl" && !job.processAt) return "red";
    else if (lastResult.result === "Success") return "green";
    else return "transparent";
  };

  const getJobIcon = (job) => {
    return (
      <span
        style={{
          width: 10,
          display: "inline-block",
          height: 10,
          marginRight: 10,
          backgroundColor: getJobIconColor(job),
          borderRadius: 10,
        }}
      ></span>
    );
  };

  if (!job) return null;

  return (
    <div className="container page">
      <h1>Trabajo</h1>
      <div className="form-group mt-3">
        <label className="form-label">Nombre</label>
        <div>
          {getJobIcon(job)} {job.name}
        </div>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Descripción</label>
        <div>{job.description}</div>
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Tipo</label>
        <div>{job.runAt ? `Servicio - ${job.runAtString}` : "Una vez"}</div>
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Estatus</label>
        <div>{getStatus(job)}</div>
      </div>

      {job.results.length > 0 && (
        <div className="form-group mt-3">
          <label className="form-label">Resultados</label>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Resultado</th>
              </tr>
            </thead>
            <tbody>
              {job.results.map((r) => (
                <tr>
                  <td>
                    <span
                      style={{
                        width: 10,
                        display: "inline-block",
                        height: 10,
                        marginRight: 10,
                        backgroundColor: getResultIconColor(r),
                        borderRadius: 10,
                      }}
                    ></span>
                    {DateTime.fromISO(r.createdAt).toFormat(
                      settings.formats.datetime
                    )}
                  </td>
                  <td>{r.note}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="mt-3 btn-toolbar">
        {userCtx.user.role.permissions.includes("jobs.edit") &&
          job.enabled &&
          job.processAt && (
            <button className="btn btn-primary" onClick={handleRunNowClicked}>
              Correr ahora
            </button>
          )}
        {userCtx.user.role.permissions.includes("jobs.edit") &&
          job.enabled &&
          job.runAt && (
            <button
              className="btn btn-primary ms-1"
              onClick={handleDisableClicked}
            >
              Disabilitar
            </button>
          )}
        {userCtx.user.role.permissions.includes("jobs.edit") &&
          !job.enabled &&
          job.runAt && (
            <button
              className="btn btn-primary ms-1"
              onClick={handleEnableClicked}
            >
              Habilitar
            </button>
          )}
      </div>
    </div>
  );
};

export default JobPage;
