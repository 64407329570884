import { forwardRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default forwardRef(
  ({ readonly, value, disabled, onChange, size, ...rest }, ref) => {
    const [viewPassword, setViewPassword] = useState(false);

    if (readonly) {
      return <label className="form-control-plaintext">{value}</label>;
    } else {
      return (
        <div className="input-group">
          <input
            ref={ref}
            type={viewPassword ? "text" : "password"}
            className={`form-control form-control-${size}`}
            value={value}
            {...rest}
            onChange={onChange}
          />
          <button
            className="btn btn-primary"
            onClick={() => setViewPassword(!viewPassword)}
          >
            <FontAwesomeIcon icon={viewPassword ? faEyeSlash : faEye} />
          </button>
        </div>
        // <input
        //   ref={ref}
        //   {...rest}
        //   type="password"
        //   className="form-control"
        //   value={value}
        //   disabled={disabled}
        //   onChange={(e) => onChange(e.target.value, e)}
        // />
      );
    }
  }
);
