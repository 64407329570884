import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import validateUtil from "../../../../utils/validate.util";

import { useUser } from "../../../contexts/user.context";
import ConfirmModal from "../../../controls/confirm.modal";
import { useException } from "../../../contexts/exception.context";
import { useMessage } from "../../../contexts/message.context";
import TextInput from "../../../controls/text.input";

export default () => {
  const navigate = useNavigate();

  const userCtx = useUser(); // user
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [oldClient, setOldClient] = useState();
  const [newClient, setNewClient] = useState();
  const [colony, setColony] = useState();
  const [isWorking, setIsWorking] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const isReadonly = !userCtx.user.role.permissions.includes(
    "clientConfiguration.edit"
  );

  const refresh = async () => {
    try {
      setIsLoading(true);

      if (!userCtx.user.colony) return;

      const client = await userCtx.webserviceClient.colonies.getClientById(
        userCtx.user.colony._id
      );
      client.price2 = client.price.toFixed(2);

      setOldClient(client);
      setNewClient({ ...client });

      const colony = await userCtx.webserviceClient.colonies.getById(
        userCtx.user.colony._id
      );

      setColony(colony);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    refresh();
  }, [userCtx.user]);

  const hasFormChanged = () => {
    return JSON.stringify(oldClient) !== JSON.stringify(newClient);
  };

  const handleDeleteClicked = async () => {
    setShowConfirmDeleteModal(true);
  };

  const handleUpdateClicked = async () => {
    try {
      await userCtx.webserviceClient.colonies.updateClient(
        userCtx.user.colony._id,
        newClient.price2
      );
      setOldClient({ ...newClient });
      messageCtx.showSuccess("VecinosMX guardado");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const isFormOk = () => {
    if (!validateUtil.number.isCurrency(newClient.price2, 2)) return false;

    return true;
  };

  const handleConfirmDeleteModalClose = async (confirm) => {
    setShowConfirmDeleteModal(false);
    if (!confirm) return;

    try {
      await userCtx.webserviceClient.colonies.delete(userCtx.user.colony._id);
      userCtx.setUser({ ...userCtx.user, colony: undefined });
      messageCtx.showSuccess("Colonia borrado");
      navigate("/colonias");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  if (isLoading) return null;

  return (
    <div>
      <h1>Configuración VecinosMX</h1>

      <div>
        <div className="mt-3">
          <label className="form-label">Cuota</label>
          <TextInput
            value={newClient.price2}
            onChange={(value) => setNewClient({ ...newClient, price2: value })}
            readonly={isReadonly}
          />
        </div>

        {!isReadonly && (
          <div className="mt-2">
            <button
              className="btn btn-primary"
              onClick={handleUpdateClicked}
              disabled={!isFormOk() || !hasFormChanged() || isWorking}
            >
              Guardar
            </button>
            {colony?.deleteAt && (
              <button
                className="btn btn-danger ms-1"
                onClick={handleDeleteClicked}
              >
                Borrar
              </button>
            )}
          </div>
        )}
      </div>
      <ConfirmModal
        cancelButtonText="No"
        confirmButtonText="Sí"
        titleText="Borrar colonia"
        questionText="Seguro que quieres borrar colonia?"
        show={showConfirmDeleteModal}
        onClose={handleConfirmDeleteModalClose}
      />
    </div>
  );
};
