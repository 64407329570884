const util = {
  number: {
    isCurrency: (val, decimals) => {
      const isNumber = !isNaN(val);
      if (!isNumber) return false;

      const regex = new RegExp(`^-?\\d+(\\.\\d{0,${decimals}})?$`);
      const isCurrencyNumber = regex.test(val);

      return isCurrencyNumber;
    },
    isValid: (val, minValue, maxValue) => {
      const isNumber = !isNaN(val);
      if (!isNumber) return false;
      if (val < minValue) return false;
      if (val > maxValue) return false;

      return true;
    },
  },
  email: {
    isValid: (email) => {
      if (!util.string.isString(email)) return false;
      var validate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      return email.match(validate);
    },
  },
  string: {
    isString: (str) => {
      const type = typeof str;
      if (type !== "string" && type !== "string") return false;
      return true;
    },
    isValid: (str, trim, minLength, maxLength) => {
      if (!util.string.isString(str)) return false;

      if (trim) {
        str = str.trim();
      }

      if (minLength !== undefined) {
        if (str.length < minLength) return false;
      }

      if (maxLength !== undefined) {
        if (str.length > maxLength) return false;
      }

      return true;
    },
  },
  array: {
    areUnique: (array) => {
      return new Set(array).size === array.length;
    },
  },
};

module.exports = util;
