import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import { useMessage } from "../../../contexts/message.context";
import ConfirmModal from "../../../controls/confirm.modal";
import FileInput from "../../../controls/file.input";
import TextInput from "../../../controls/text.input";

export default () => {
  const navigate = useNavigate();
  const params = useParams();

  const userCtx = useUser(); // user
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [transaction, setTransaction] = useState();
  const [isWorking, setIsWorking] = useState(false);

  useEffect(() => {
    const refresh = async () => {
      try {
        const transaction =
          await userCtx.webserviceClient.clientTransactions.getById(
            userCtx.user.colony._id,
            params.clientTransactionId
          );
        setTransaction(transaction);
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };

    refresh();
  }, [userCtx.user, navigate, params.clientTransactionId]);

  const handleConfirmDeleteModalClose = async (confirm) => {
    setShowConfirmDeleteModal(false);
    if (!confirm) return;

    try {
      setIsWorking(true);
      await userCtx.webserviceClient.clientTransactions.delete(
        userCtx.user.colony._id,
        params.clientTransactionId
      );
      messageCtx.showSuccess(`${transaction.type} borrada`);
      navigate("./..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const handleSendPaymentReceiptClicked = async () => {
    try {
      setIsWorking(true);
      await userCtx.webserviceClient.clientTransactions.sendPaymentReceipt(
        userCtx.user.colony._id,
        params.clientTransactionId
      );
      messageCtx.showSuccess(`Recibo de pago enviado`);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    } finally {
      setIsWorking(false);
    }
  };

  if (!transaction) return null;

  return (
    <div>
      <h1>Movimiento VecinosMX</h1>

      <div className="mb-2">
        <label className="form-label">Tipo</label>
        <TextInput value={transaction.type} readonly={true} />
      </div>

      <div className="mb-2">
        <label className="form-label">Fecha</label>
        <TextInput value={transaction.date} readonly={true} />
      </div>

      <div className="mb-2">
        <label className="form-label">Descripción</label>
        <TextInput value={transaction.description} readonly={true} />
      </div>

      <div className="mb-2">
        <label className="form-label">Cantidad</label>
        <TextInput value={transaction.amount.toFixed(2)} readonly={true} />
      </div>

      {transaction.receipt && (
        <div className="mb-2">
          <label className="form-label">Recibo</label>
          <FileInput
            value={transaction.receipt}
            readonly={true}
            showSeeImageButton={true}
          />
        </div>
      )}

      {userCtx.user.role.permissions.includes("clientTransactions.edit") && (
        <div>
          <button
            className="btn btn-primary"
            disabled={isWorking}
            onClick={handleSendPaymentReceiptClicked}
          >
            Enviar recibo
          </button>
          <button
            className="btn btn-danger ms-1"
            disabled={isWorking}
            onClick={() => setShowConfirmDeleteModal(true)}
          >
            Borrar
          </button>
        </div>
      )}

      <ConfirmModal
        cancelButtonText="No"
        confirmButtonText="Sí"
        questionText="Seguro que quieres borrar factura?"
        titleText="Borrar factura"
        show={showConfirmDeleteModal}
        onClose={handleConfirmDeleteModalClose}
      />
    </div>
  );
};
