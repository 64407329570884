import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import TextInput from "../../../controls/text.input";
import RadiosInput from "../../../controls/radios.input";
import ConfirmModal from "../../../controls/confirm.modal";
import { useMessage } from "../../../contexts/message.context";

export default () => {
  const navigate = useNavigate();
  const params = useParams();

  const userCtx = useUser();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [types, setTypes] = useState([]);
  const [oldAccount, setOldAccount] = useState();
  const [newAccount, setNewAccount] = useState();
  const [isWorking, setIsWorking] = useState(false);

  useEffect(() => {
    const refresh = async () => {
      try {
        const types = await userCtx.webserviceClient.colonyAccounts.getTypes();
        setTypes(types);

        if (params.accountId) {
          const account = await userCtx.webserviceClient.colonyAccounts.getById(
            userCtx.user.colony._id,
            params.accountId
          );
          setOldAccount(account);
          setNewAccount({ ...account });
        } else {
          setNewAccount({ name: "", type: types[0].name });
        }
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };

    refresh();
  }, [userCtx.user, params.accountId]);

  const isFormOk = () => {
    if (!newAccount.name) return false;
    if (!newAccount.type) return false;

    return true;
  };

  const hasFormChanged = () => {
    return JSON.stringify(oldAccount) !== JSON.stringify(newAccount);
  };

  const handleCreateClicked = async () => {
    try {
      setIsWorking(true);
      await userCtx.webserviceClient.colonyAccounts.insert(
        userCtx.user.colony._id,
        newAccount.type,
        newAccount.name
      );
      messageCtx.showSuccess("Cuenta creado");
      navigate("./..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const handleUpdateClicked = async () => {
    try {
      setIsWorking(true);
      await userCtx.webserviceClient.colonyAccounts.update(
        newAccount._id,
        newAccount.name
      );
      messageCtx.showSuccess("Cuenta actualizado");
      navigate("./..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const handleConfirmDeleteModalClose = async (confirm) => {
    setShowConfirmDeleteModal(false);
    if (!confirm) return;

    try {
      setIsWorking(true);
      await userCtx.webserviceClient.colonyAccounts.delete(
        userCtx.user.colony._id,
        params.accountId
      );
      messageCtx.showSuccess("Cuenta borrada");
      navigate("./..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  if (!newAccount) return null;

  return (
    <div>
      <h1>{oldAccount ? "Cuenta" : "Nueva cuenta"}</h1>

      <div className="mb-2">
        <label className="form-label">Nombre</label>
        <TextInput
          value={newAccount.name}
          disabled={isWorking}
          onChange={(value) => setNewAccount({ ...newAccount, name: value })}
          readonly={
            !userCtx.user.role.permissions.includes("accounts.edit") ||
            //newAccount.transactionCount > 0 ||
            newAccount.isSystem
          }
        />
      </div>

      <div className="mb-2">
        <label className="form-label">Tipo</label>
        <RadiosInput
          items={types}
          value={newAccount.type}
          disabled={isWorking}
          nameExtractor={(i) => i.name}
          valueExtractor={(i) => i.name}
          onChange={(value) => setNewAccount({ ...newAccount, type: value })}
          readonly={
            !userCtx.user.role.permissions.includes("accounts.edit") ||
            newAccount.transactionCount > 0 ||
            newAccount.isSystem
          }
        />
      </div>

      {oldAccount && (
        <div className="mb-2">
          <label className="form-label">Saldo</label>
          <TextInput
            value={newAccount.typeBalance.toFixed(2)}
            readonly={true}
          />
        </div>
      )}

      {oldAccount && (
        <div className="mb-2">
          <label className="form-label">Es de sistema</label>
          <TextInput
            value={newAccount.isSystem ? "Sí" : "No"}
            readonly={true}
          />
        </div>
      )}

      {oldAccount && (
        <div className="mb-2">
          <label className="form-label">Cantidad de movimientos</label>
          <TextInput value={newAccount.transactionCount} readonly={true} />
        </div>
      )}

      {userCtx.user.role.permissions.includes("accounts.edit") && (
        <div className="mb-2">
          {!oldAccount && (
            <button
              className="btn btn-primary"
              onClick={handleCreateClicked}
              disabled={!isFormOk() || isWorking}
            >
              Crear
            </button>
          )}
          {oldAccount && (
            <button
              className="btn btn-primary"
              disabled={!isFormOk() || !hasFormChanged() || isWorking}
              onClick={handleUpdateClicked}
            >
              Guardar
            </button>
          )}
          {oldAccount &&
            newAccount.transactionCount === 0 &&
            !newAccount.isSystem && (
              <button
                className="btn btn-danger"
                disabled={isWorking}
                onClick={() => setShowConfirmDeleteModal(true)}
              >
                Borrar
              </button>
            )}
        </div>
      )}
      <ConfirmModal
        confirmButtonText="Sí"
        cancelButtonText="No"
        titleText="Borrar cuenta"
        questionText="Seguro que quieres borrar cuenta?"
        show={showConfirmDeleteModal}
        onClose={handleConfirmDeleteModalClose}
      />
    </div>
  );
};
