import { Routes, Route, Navigate } from "react-router-dom";

import SigninPage from "./signin.page";

const getDefaultNavigate = () => {
  return <Navigate to="/" replace />;
};

const Nav = () => {
  return (
    <Routes>
      <Route path="" element={<SigninPage />} />
      <Route path="*" element={getDefaultNavigate()} />
    </Routes>
  );
};

export default Nav;
