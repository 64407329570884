import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

import validateUtil from "../../../../utils/validate.util";
import arrayUtil from "../../../../utils/array.util";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import { useMessage } from "../../../contexts/message.context";
import DateInput from "../../../controls/date.input";
import SelectInput from "../../../controls/select.input";
import TextInput from "../../../controls/text.input";
import NumberInput from "../../../controls/number.input";

const NewTransactionLoanControl = ({
  colony,
  accounts,
  isWorking,
  setIsWorking,
}) => {
  const navigate = useNavigate();

  const userCtx = useUser();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [houseAccounts, setHouseAccounts] = useState([]);
  const [houseAccountId, setHouseAccountId] = useState();
  const [colonyAccounts, setColonyAccounts] = useState([]);
  const [colonyAccountId, setColonyAccountId] = useState();
  const [description, setDescription] = useState("");
  const [date, setDate] = useState(DateTime.now().toISODate());
  const [amount, setAmount] = useState("0");

  useEffect(() => {
    const refresh = async () => {
      try {
        const houseAccounts = accounts
          .filter((a) => a.type === "Casa")
          .sort((a, b) => a.name.localeCompare(b.name));

        const colonyAccounts = accounts
          .filter((a) => a.type === "Colonia")
          .sort((a, b) => a.name.localeCompare(b.name));

        setHouseAccounts(houseAccounts);
        setColonyAccounts(colonyAccounts);

        if (houseAccounts.length > 0) setHouseAccountId(houseAccounts[0]._id);
        if (colonyAccounts.length > 0) {
          var latest = colonyAccounts.reduce((l, e) =>
            e.transactionCount > l.transactionCount ? e : l
          );
          setColonyAccountId(latest._id);
        }
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };

    refresh();
  }, [accounts]);

  const handleCreateClicked = async () => {
    const houseAddress =
      houseAccountId && houseAccounts
        ? houseAccounts.find((h) => h._id === houseAccountId).name
        : "";

    try {
      setIsWorking(true);
      await userCtx.webserviceClient.colonyTransactions.insertLoan(
        userCtx.user.colony._id,
        houseAccountId,
        colonyAccountId,
        date,
        `${houseAddress} (${description})`,
        amount
      );
      messageCtx.showSuccess("Movimento guardado");
      navigate("./..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const isFormOk = () => {
    if (!description) return false;
    if (!validateUtil.number.isCurrency(amount, 2)) return false;
    if (parseFloat(amount) <= 0) return false;
    if (!houseAccountId) return false;
    if (!colonyAccountId) return false;

    return true;
  };

  const houseAddress =
    houseAccountId && houseAccounts
      ? houseAccounts.find((h) => h._id === houseAccountId).name
      : "";

  const lastEvent = arrayUtil.last(colony.events);
  const minDate = lastEvent ? lastEvent.date : "";

  return (
    <div>
      <div className="mb-2">
        <div className="form-label">Fecha</div>
        <DateInput
          max={DateTime.now().toISODate()}
          min={minDate}
          value={date}
          disabled={isWorking}
          onChange={setDate}
        />
      </div>

      <div className="mb-2">
        <div className="form-label">Cuenta de casa</div>
        <SelectInput
          items={houseAccounts}
          nameExtractor={(a) => a.name}
          valueExtractor={(a) => a._id}
          value={houseAccountId}
          disabled={isWorking}
          onChange={setHouseAccountId}
        />
      </div>

      <div className="mb-2">
        <div className="form-label">Cuenta de colonia</div>
        <SelectInput
          items={colonyAccounts}
          nameExtractor={(a) => a.name}
          valueExtractor={(a) => a._id}
          value={colonyAccountId}
          disabled={isWorking}
          onChange={setColonyAccountId}
        />
      </div>

      <div className="mb-2">
        <div className="form-label">Concepto</div>

        <div className="input-group">
          <span className="input-group-text">{houseAddress} (</span>
          <TextInput
            value={description}
            disabled={isWorking}
            onChange={setDescription}
          />
          <span className="input-group-text">)</span>
        </div>
      </div>

      <div className="mb-2">
        <div className="form-label">Cantidad</div>
        <NumberInput value={amount} onChange={setAmount} disabled={isWorking} />
      </div>

      <div className="mb-2">
        <button
          className="btn btn-primary"
          onClick={handleCreateClicked}
          disabled={!isFormOk() || isWorking}
        >
          Crear
        </button>
      </div>
    </div>
  );
};

export default NewTransactionLoanControl;
