import { useEffect, useState } from "react";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";

import NewTransactionPaymentControl from "./newTransaction.payment.control";
import NewTransactionChargeControl from "./newTransaction.charge.control";

// resize image: https://stackoverflow.com/questions/23945494/use-html5-to-resize-an-image-before-upload
const NewTransactionPage = () => {
  const userCtx = useUser();
  const expcetionCtx = useException();

  const [type, setType] = useState("Pago");
  const [types, setTypes] = useState([]);
  const [isWorking, setIsWorking] = useState(false);

  const refresh = async () => {
    try {
      const types =
        await userCtx.webserviceClient.clientTransactions.getTypes();
      setTypes(types);
    } catch (ex) {
      expcetionCtx.handleException(ex);
    }
  };
  useEffect(() => {
    refresh();
  }, []);

  return (
    <div>
      <h1>Nuevo movimiento VecinosMX</h1>

      <div className="mb-2">
        <div className="form-label">Tipo</div>
        <select
          className="form-select"
          value={type}
          disabled={isWorking}
          onChange={(e) => setType(e.target.value)}
        >
          {types.map((t) => (
            <option key={t.name}>{t.name}</option>
          ))}
        </select>
      </div>
      {type === "Pago" && (
        <NewTransactionPaymentControl
          isWorking={isWorking}
          setIsWorking={setIsWorking}
        />
      )}
      {type === "Cargo" && (
        <NewTransactionChargeControl
          isWorking={isWorking}
          setIsWorking={setIsWorking}
        />
      )}
    </div>
  );
};

export default NewTransactionPage;
