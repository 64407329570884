import * as React from "react";

import { Toaster, ToasterType } from "../controls/toaster";

const messageContext = React.createContext();

export const useMessage = () => {
  return React.useContext(messageContext);
};

export const MessageContext = ({ children }) => {
  const showSuccess = (text) => {
    const simpleToaster = new Toaster();
    simpleToaster.create(text, { type: ToasterType.SUCCESS });
  };

  const showWarning = (text) => {
    const simpleToaster = new Toaster();
    simpleToaster.create(text, { type: ToasterType.WARNING });
  };

  const showError = (text) => {
    const simpleToaster = new Toaster();
    simpleToaster.create(text, { type: ToasterType.DANGER });
  };

  const showMessagesFromResults = (results) => {
    const errorCount = results.filter((r) => r.state === "error").length;
    const warningCount = results.filter((r) => r.state === "warning").length;
    const successCount = results.filter((r) => r.state === "success").length;

    if (errorCount > 0) {
      if (errorCount === 1) showError(`${errorCount} error`);
      else showError(`${errorCount} errores`);
    }

    if (warningCount > 0) {
      if (warningCount === 1) showWarning(`${warningCount} advertencia`);
      else showWarning(`${warningCount} advertencias`);
    }

    if (successCount > 0) {
      if (successCount === 1) showSuccess(`${successCount} exito`);
      else showSuccess(`${successCount} exitos`);
    }
  };

  return (
    <messageContext.Provider
      value={{
        showSuccess,
        showError,
        showWarning,
        showMessagesFromResults,
      }}
    >
      {children}
    </messageContext.Provider>
  );
};
