import { useEffect, useState } from "react";

import objectUtil from "../../../../utils/object.util";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import { useMessage } from "../../../contexts/message.context";
import FileInput from "../../../controls/file.input";
import TextInput from "../../../controls/text.input";
import InformationButton from "../../../controls/information.button";

export default () => {
  const userCtx = useUser(); // user
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [oldColony, setOldColony] = useState();
  const [newColony, setNewColony] = useState();
  const [isWorking, setIsWorking] = useState(false);

  const isReadonly =
    !userCtx.user.role.permissions.includes("configuration.edit");

  const refresh = async () => {
    try {
      const colony = await userCtx.webserviceClient.colonies.getById(
        userCtx.user.colony._id
      );

      setOldColony(colony);
      setNewColony(JSON.parse(JSON.stringify(colony)));
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, [userCtx.user]);

  const hasFormChanged = () => {
    return JSON.stringify(oldColony) !== JSON.stringify(newColony);
  };

  const isFormOk = () => {
    return true;
  };

  const handleUpdateClicked = async () => {
    try {
      setIsWorking(true);
      await userCtx.webserviceClient.colonies.updateConfiguration(
        newColony._id,
        newColony.email,
        newColony.configuration.general.allowHouseLogin,
        newColony.configuration.reports.bannerFile,
        newColony.configuration.reports.accountStatusFormat
      );

      messageCtx.showSuccess("Configuración actualizada");
      setIsWorking(false);
      refresh();
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const setBannerFileAdapter = (file) => {
    if (file) {
      file.url = URL.createObjectURL(file);
    }
    setNewColony(
      objectUtil.setValue(
        { ...newColony },
        "configuration.reports.bannerFile",
        file
      )
    );
  };

  if (!newColony) return null;

  return (
    <div>
      <h1>Configuración</h1>

      <div>
        <div className="mt-4">
          <h5>General</h5>
        </div>

        <div className="mt-3">
          <label className="form-label">
            Correo{" "}
            <InformationButton
              title="Correo"
              subjects={[
                {
                  description:
                    "El correo electrónico de la colonia. Se usa para la comunicacion entre colonia y casas y entre colonia y VecinosMX.",
                },
              ]}
            />
          </label>
          <TextInput
            disabled={isWorking}
            readonly={isReadonly}
            value={newColony.email}
            onChange={(value) => setNewColony({ ...newColony, email: value })}
          />
        </div>

        <div className="mt-4">
          <h5>Reportes</h5>
        </div>
        <div className="mt-3">
          <label className="form-label">
            Encabezado{" "}
            <InformationButton
              title="v"
              subjects={[
                {
                  description:
                    "El logotipo utilizado en el encabezado de los reportes.",
                },
              ]}
            />
          </label>
          <FileInput
            value={newColony.configuration.reports.bannerFile}
            onChange={setBannerFileAdapter}
            accept=".jpg"
            disabled={isWorking}
            readonly={isReadonly}
            allowEmpty
            showSeeImageButton
          />
        </div>
        {!isReadonly && (
          <div className="mt-2">
            <button
              className="btn btn-primary"
              onClick={handleUpdateClicked}
              disabled={!isFormOk() || !hasFormChanged() || isWorking}
            >
              Guardar
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
