import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { faFilter, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import localStorageUtil from "../../../../utils/localStorage.util";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import TableControl from "../../../controls/table.control";
import CheckboxesInput from "../../../controls/checkboxes.input";

const getDefaultFilter = (types) => {
  return { types: types.map((t) => t.name) };
};

const AccountsPage = () => {
  const navigate = useNavigate();

  const userCtx = useUser();
  const exceptionCtx = useException();

  const [accounts, setAccounts] = useState([]);
  const [types, setTypes] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState();

  const saveFilter = (filter) => {
    localStorageUtil.set("accountsFilter", JSON.stringify(filter));
    setFilter(filter);
  };

  const loadFilter = (types) => {
    try {
      const json = localStorageUtil.get("accountsFilter");
      if (!json) throw "";
      return JSON.parse(json);
    } catch {
      return getDefaultFilter(types);
    }
  };

  const refresh = async () => {
    try {
      const types = await userCtx.webserviceClient.colonyAccounts.getTypes();
      setTypes(types);

      const filter = loadFilter(types);
      setFilter(filter);

      const accounts2 =
        await userCtx.webserviceClient.colonyAccounts.getByColony(
          userCtx.user.colony._id
        );

      accounts2.sort((a, b) => {
        const result = a.type.localeCompare(b.type);

        if (result !== 0) return result;

        return a.name.localeCompare(b.name);
      });

      setFilter(filter);
      setAccounts(accounts2);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleToggleFilterClicked = () => {
    setShowFilter(!showFilter);
  };

  const handleResetFilterClicked = () => {
    saveFilter(getDefaultFilter(types));
  };

  const handleAccountPressed = (account) => {
    navigate(`./${account._id}`);
  };

  const filteredAccounts = accounts.filter((a) =>
    filter.types.includes(a.type)
  );

  const handleNewClicked = () => {
    navigate("./nuevo");
  };

  if (!filter) return null;

  return (
    <div>
      <h1>Cuentas</h1>
      <div className="d-flex justify-content-between">
        <div>
          {userCtx.user.role.permissions.includes("accounts.edit") && (
            <button className="btn btn-primary" onClick={handleNewClicked}>
              Nueva
            </button>
          )}
        </div>

        <div className="btn-group">
          <button
            className="btn btn-primary"
            onClick={handleToggleFilterClicked}
          >
            <FontAwesomeIcon icon={faFilter} />
          </button>
          {JSON.stringify(getDefaultFilter(types)) !==
            JSON.stringify(filter) && (
            <button
              className="btn btn-danger"
              onClick={handleResetFilterClicked}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          )}
        </div>
      </div>

      <div style={{ display: showFilter ? "block" : "none" }}>
        <div className="mb-2">
          <label className="form-label">Tipos</label>
          <CheckboxesInput
            items={types}
            nameExtractor={(e) => e.name}
            valueExtractor={(e) => e.name}
            values={filter.types}
            onChange={(values) => saveFilter({ ...filter, types: values })}
          />
        </div>
      </div>

      <TableControl
        columns={[
          { header: "Tipo", valueExtractor: (a) => a.type },
          { header: "Nombre", valueExtractor: (a) => a.name },
        ]}
        rows={filteredAccounts}
        onRowClicked={handleAccountPressed}
        keyExtractor={(a) => a._id}
      />
    </div>
  );
};

export default AccountsPage;
