import { useEffect, useState } from "react";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import InformationButton from "../../../controls/information.button";

import NewTransactionPaymentControl from "./newTransaction.payment.control";
import NewTransactionChargeControl from "./newTransaction.charge.control";
import NewTransactionChargesControl from "./newTransaction.charges.control";
import NewTransactionPaymentsControl from "./newTransaction.payments.control";
import NewTransactionMaintenancesControl from "./newTransaction.maintenances.control";
import NewTransactionTransferControl from "./newTransaction.transfer.control";
import NewTransactionExpenseControl from "./newTransaction.expense.control";
import NewTransactionIncomeControl from "./newTransaction.income.control";
import NewTransactionHouseAdjustmentControl from "./newTransaction.houseAdjustment.control";
import NewTransactionColonyAdjustmentControl from "./newTransaction.coloniaAdjustment.control";
import NewTransactionLoanControl from "./newTransaction.loan.control";

// resize image: https://stackoverflow.com/questions/23945494/use-html5-to-resize-an-image-before-upload
const NewTransactionPage = () => {
  const userCtx = useUser(); // user
  const exceptionCtx = useException();

  const houseTypes = ["Pago", "Cargo", "Prestamo", "Ajuste casa"];
  const colonyTypes = ["Gasto", "Ingreso", "Transfer", "Ajuste colonia"];
  const multiHouseTypes = ["Pagos", "Mantenimientos", "Cargos"];

  const [type, setType] = useState("Pago");
  const [isWorking, setIsWorking] = useState(false);
  const [colony, setColony] = useState();
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    const refresh = async () => {
      try {
        const colony = await userCtx.webserviceClient.colonies.getById(
          userCtx.user.colony._id
        );
        const accounts =
          await userCtx.webserviceClient.colonyAccounts.getByColony(
            userCtx.user.colony._id
          );
        setColony(colony);
        setAccounts(accounts);
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };

    refresh();
  }, []);

  if (!colony) return null;

  return (
    <div>
      <h1>Nuevo movimiento</h1>

      <div className="mb-2">
        <div className="form-label">
          Tipo{" "}
          <InformationButton
            title="Tipo"
            subjects={[
              {
                title: "Pago",
                description:
                  "Casa hace un pago a la colonia. Se agrega saldo a cuenta de casa y cuenta de colonia. Se usa para cuando alguien hace un pago a la colonia en efectivo, transfer o SPEI.",
              },
              {
                title: "Cargo",
                description:
                  "Se hace un cargo a casa. Se quita saldo a cuenta de casa. Se usa por ejemplo para cuando alguien choca propiedad de la colonia y se tiene que pagar.",
              },
              {
                title: "Prestamo",
                description:
                  "Colonia presta dinero a casa. Se quita saldo a cuenta de casa y a cuenta de colonia. Se usa para cuando una casa 'quita' dinero de la colonia y se tiene que regresar.",
              },
              {
                title: "Mantenimiento",
                description:
                  "Se hace un cargo a casa. Se quita saldo a cuenta de casa. Se usa para cuando se hice un cargo automatico erroneo de mantenimiento y se tiene que corregir.",
              },
              {
                title: "Ingreso",
                description:
                  "Se hace un ingreso a la colonia. Se agrega saldo a cuenta de colonia. Se usa por ejemplo para cuando se genera dinero en un evento en la colonia o la maquina de coca se genera dinero.",
              },
              {
                title: "Gasto",
                description:
                  "Se hace un gasto a la colonia. Se quita saldo a cuenta de colonia. Se usa para todos los gastos de la colonia.",
              },
              {
                title: "Transfer",
                description:
                  "Se mueve dinero entre cuentas de colonia. Se quita saldo a una cuenta de colonia y se agrega a otra cuenta de colonia. Se usa por ejemplo para transferir dinero de la cuenta del banco a caja chica.",
              },
              {
                title: "Ajuste de casa",
                description:
                  "Casa se ajusta. Se quita/agrega saldo a cuenta de casa. Usualmente se usa para 'perdonar' una deuda parcial/total.",
              },
              {
                title: "Ajuste de colonia",
                description:
                  "Colonia se ajusta. Se quita/agrega saldo a cuenta de colonia. Usualmente se usa para cuando hay un cambio de mesa y hay diferencia en el dinero fisico comparado de la sistema.",
              },
            ]}
          />
        </div>
        <select
          className="form-select"
          value={type}
          disabled={isWorking}
          onChange={(e) => setType(e.target.value)}
        >
          <optgroup label="Casa">
            {houseTypes.map((t) => (
              <option key={t}>{t}</option>
            ))}
          </optgroup>
          <optgroup label="Colonia">
            {colonyTypes.map((t) => (
              <option key={t}>{t}</option>
            ))}
          </optgroup>
          <optgroup label="Casas">
            {multiHouseTypes.map((t) => (
              <option key={t}>{t}</option>
            ))}
          </optgroup>
        </select>
      </div>
      {type === "Pago" && (
        <NewTransactionPaymentControl
          accounts={accounts}
          isWorking={isWorking}
          setIsWorking={setIsWorking}
          colony={colony}
        />
      )}
      {type === "Cargo" && (
        <NewTransactionChargeControl
          accounts={accounts}
          isWorking={isWorking}
          setIsWorking={setIsWorking}
          colony={colony}
        />
      )}
      {type === "Pagos" && (
        <NewTransactionPaymentsControl
          accounts={accounts}
          isWorking={isWorking}
          setIsWorking={setIsWorking}
          colony={colony}
        />
      )}
      {type === "Mantenimientos" && (
        <NewTransactionMaintenancesControl
          accounts={accounts}
          isWorking={isWorking}
          setIsWorking={setIsWorking}
          colony={colony}
        />
      )}
      {type === "Ajuste casa" && (
        <NewTransactionHouseAdjustmentControl
          accounts={accounts}
          isWorking={isWorking}
          setIsWorking={setIsWorking}
          colony={colony}
        />
      )}
      {type === "Prestamo" && (
        <NewTransactionLoanControl
          accounts={accounts}
          isWorking={isWorking}
          setIsWorking={setIsWorking}
          colony={colony}
        />
      )}
      {type === "Transfer" && (
        <NewTransactionTransferControl
          accounts={accounts}
          isWorking={isWorking}
          setIsWorking={setIsWorking}
          colony={colony}
        />
      )}
      {type === "Gasto" && (
        <NewTransactionExpenseControl
          accounts={accounts}
          isWorking={isWorking}
          setIsWorking={setIsWorking}
          colony={colony}
        />
      )}
      {type === "Ingreso" && (
        <NewTransactionIncomeControl
          accounts={accounts}
          isWorking={isWorking}
          setIsWorking={setIsWorking}
          colony={colony}
        />
      )}
      {type === "Ajuste colonia" && (
        <NewTransactionColonyAdjustmentControl
          accounts={accounts}
          isWorking={isWorking}
          setIsWorking={setIsWorking}
          colony={colony}
        />
      )}
      {type === "Cargos" && (
        <NewTransactionChargesControl
          accounts={accounts}
          isWorking={isWorking}
          setIsWorking={setIsWorking}
          colony={colony}
        />
      )}
    </div>
  );
};

export default NewTransactionPage;
