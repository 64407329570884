import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import TextInput from "../../../controls/text.input";
import FileViewerModal from "../../../controls/fileViewer.modal";

export default () => {
  const userCtx = useUser();
  const exceptionCtx = useException();

  const [colony, setColony] = useState();
  const [colonyBalance, setColonyBalance] = useState();
  const [client, setClient] = useState();
  const [clientBalance, setClientBalance] = useState();
  const [house, setHouse] = useState();
  const [showFileModal, setShowFileModal] = useState(false);
  const [files, setFiles] = useState([]);

  const refresh = async () => {
    try {
      if (userCtx.user.role.permissions.includes("overview.viewclient")) {
        const client = await userCtx.webserviceClient.colonies.getClientById(
          userCtx.user.colony._id
        );
        client.price2 = client.price.toFixed(2);

        const clientBalance =
          await userCtx.webserviceClient.clientAccounts.getBalanceByType(
            userCtx.user.colony._id,
            "Colonia"
          );

        setClient(client);
        setClientBalance(clientBalance);
      }

      if (userCtx.user.colony) {
        if (userCtx.user.role.permissions.includes("overview.viewcolony")) {
          const colony = await userCtx.webserviceClient.colonies.getById(
            userCtx.user.colony._id
          );
          colony.documents.sort((a, b) => a.name.localeCompare(b.name));

          const colonyBalance =
            await userCtx.webserviceClient.colonyAccounts.getBalanceByType(
              userCtx.user.colony._id,
              "Colonia"
            );
          setColony(colony);
          setColonyBalance(colonyBalance);
        }

        if (userCtx.user.house) {
          if (userCtx.user.role.permissions.includes("overview.viewhouse")) {
            const house = await userCtx.webserviceClient.houses.getById(
              userCtx.user.colony._id,
              userCtx.user.house._id
            );

            house.maintenance = house.maintenance.toFixed(2);
            setHouse(house);
          }
        }
      }
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleDocumentClicked = (document) => {
    setFiles([document.file]);
    setShowFileModal(true);
  };

  useEffect(() => {
    refresh();
  }, [userCtx.user]);

  return (
    <div>
      {house && (
        <div>
          <h1>Casa</h1>

          <div>
            <div className="mb-2">
              <label className="form-label">Dirección</label>
              <TextInput readonly={true} value={house.address} />
            </div>

            <div className="mb-2">
              <label className="form-label">Saldo</label>
              <div className="form-control-plaintext">
                {userCtx.user.role.permissions.includes(
                  "reports.accountstatus_house_breakdown"
                ) ? (
                  <Link
                    to="/reportes"
                    state={{
                      reportTypeId: "reports.accountstatus_house_breakdown",
                      intervalType: "Año",
                      houseAccountId: userCtx.user.house.accountId,
                      generate: true,
                    }}
                  >
                    {house.account.balance.toFixed(2)}
                  </Link>
                ) : (
                  house.account.balance.toFixed(2)
                )}
              </div>
            </div>

            <div className="mb-2">
              <label className="form-label">Mantenimiento</label>
              <TextInput
                type="number"
                readonly={true}
                value={house.maintenance}
              />
            </div>

            <div className="mb-2">
              <label className="form-label">Referencia bancario</label>
              <TextInput readonly={true} value={house.bankReference} />
            </div>
          </div>
        </div>
      )}

      {colony && (
        <div>
          <h1>Colonia</h1>

          <div className="mb-2">
            <label className="form-label">Nombre</label>
            <TextInput value={colony.name} readonly={true} />
          </div>

          <div className="mb-2">
            <label className="form-label">Correo</label>
            <TextInput value={colony.email} readonly={true} />
          </div>

          {colonyBalance && (
            <div className="mb-2">
              <label className="form-label">Saldo</label>

              <div className="form-control-plaintext">
                {userCtx.user.role.permissions.includes(
                  "reports.accountstatus_colony_breakdown"
                ) ? (
                  <Link
                    to="/reportes"
                    state={{
                      reportTypeId: "reports.accountstatus_colony_breakdown",
                      generate: true,
                    }}
                  >
                    {colonyBalance.balance.toFixed(2)}
                  </Link>
                ) : (
                  colonyBalance.balance.toFixed(2)
                )}
              </div>
            </div>
          )}

          {colony.documents.length > 0 && (
            <div className="mb-2">
              <label className="form-label">Documentos</label>
              <div className="form-control-plaintext">
                {colony.documents.map((d) => (
                  <div key={d._id}>
                    <button
                      className="btn btn-link p-0"
                      onClick={() => handleDocumentClicked(d)}
                    >
                      {d.name}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}

          {colony.deleteAt && (
            <div className="mb-2">
              <label className="form-label">Estatus</label>
              <TextInput
                value={`Demo se borra el ${DateTime.fromISO(
                  colony.deleteAt
                ).toFormat("dd 'de' MMMM", { locale: "es" })}`}
                readonly={true}
              />
            </div>
          )}
        </div>
      )}
      {client && (
        <div>
          <h1>VecinosMX</h1>

          <div className="mb-2">
            <label className="form-label">Cuota</label>
            <TextInput value={client.price2} readonly={true} />
          </div>

          {clientBalance && (
            <div className="mb-2">
              <label className="form-label">Saldo</label>

              <label className="form-control-plaintext">
                {userCtx.user.role.permissions.includes(
                  "reports.accountstatus_client_breakdown"
                ) ? (
                  <Link
                    to="/reportes"
                    state={{
                      reportTypeId: "reports.accountstatus_client_breakdown",
                      intervalType: "Año",
                      generate: true,
                    }}
                  >
                    {clientBalance.balance.toFixed(2)}
                  </Link>
                ) : (
                  clientBalance.balance.toFixed(2)
                )}
              </label>
            </div>
          )}
        </div>
      )}
      <FileViewerModal
        images={files}
        setShow={setShowFileModal}
        show={showFileModal}
        index={0}
      />
    </div>
  );
};
