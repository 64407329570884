import { useState, useEffect } from "react";
import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import SelectInput from "../../../controls/select.input";
import FileInput from "../../../controls/file.input";
import { useMessage } from "../../../contexts/message.context";

const NewTransactionPaymentsControl = ({
  colony,
  accounts,
  isWorking,
  setIsWorking,
}) => {
  const userCtx = useUser();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [colonyAccounts, setColonyAccounts] = useState([]);
  const [colonyAccountId, setColonyAccountId] = useState();
  const [results, setResults] = useState([]);
  const [file, setFile] = useState(null);
  const [typeId, setTypeId] = useState("Afirme");
  const [types] = useState([{ id: "Afirme" }]);

  useEffect(() => {
    const refresh = async () => {
      try {
        const colonyAccounts = accounts
          .filter((a) => a.type === "Colonia")
          .sort((a, b) => a.name.localeCompare(b.name));

        setColonyAccounts(colonyAccounts);

        if (colonyAccounts.length > 0) {
          var latest = colonyAccounts.reduce((l, e) =>
            e.transactionCount > l.transactionCount ? e : l
          );
          setColonyAccountId(latest._id);
        }
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };

    refresh();
  }, [accounts]);

  const handleInsertClicked = async () => {
    try {
      setResults([]);
      setIsWorking(true);

      const results =
        await userCtx.webserviceClient.colonyTransactions.insertPayments(
          userCtx.user.colony._id,
          colonyAccountId,
          typeId,
          file
        );

      messageCtx.showMessagesFromResults(results);
      setResults(results);
      setIsWorking(false);
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const isFormOk = () => {
    if (!file) return false;
    if (!typeId) return false;
    if (!colonyAccountId) return false;

    return true;
  };
  // https://stackoverflow.com/questions/18672452/left-align-and-right-align-within-div-in-bootstrap

  const getColor = (r) => {
    switch (r.state) {
      case "error":
        return "table-danger";
      case "warning":
        return "table-warning";
      case "success":
        return "table-success";
      default:
        return "";
    }
  };

  return (
    <div>
      <div className="mb-2">
        <div className="form-label">Fuente</div>
        <SelectInput
          items={types}
          nameExtractor={(a) => a.id}
          valueExtractor={(a) => a.id}
          value={typeId}
          disabled={isWorking}
          onChange={setTypeId}
        />
      </div>

      <div className="mb-2">
        <div className="form-label">Cuenta de colonia</div>
        <SelectInput
          items={colonyAccounts}
          nameExtractor={(a) => a.name}
          valueExtractor={(a) => a._id}
          value={colonyAccountId}
          disabled={isWorking}
          onChange={setColonyAccountId}
        />
      </div>
      <div className="mb-2">
        <div className="form-label">Archivo</div>
        <FileInput
          value={file}
          onChange={setFile}
          disabled={isWorking}
          accept=".csv"
          showSeeImageButton={false}
          allowEmpty={false}
        />
      </div>

      <div className="mb-2">
        <button
          className="btn btn-primary"
          onClick={handleInsertClicked}
          disabled={!isFormOk() || isWorking}
        >
          Crear
        </button>
      </div>
      {results.length > 0 && (
        <table className="table">
          <thead>
            <tr>
              <th>Fila</th>
              <th>Mensaje</th>
            </tr>
          </thead>
          <tbody>
            {results.map((r) => (
              <tr key={r.id} className={getColor(r)}>
                <td>{r.id}</td>
                <td>{r.message}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default NewTransactionPaymentsControl;
