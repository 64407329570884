import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import TableControl from "../../../controls/table.control";

const ColonyEventsPage = () => {
  const navigate = useNavigate();

  const userCtx = useUser();
  const exceptionCtx = useException();

  const [events, setEvents] = useState([]);

  const refresh = async () => {
    try {
      const events = await userCtx.webserviceClient.colonies.getEvents(
        userCtx.user.colony._id
      );
      setEvents(events);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleEventClicked = (event) => {
    navigate(`./${event._id}`);
  };

  const handleNewClicked = () => {
    navigate("./nuevo");
  };

  return (
    <div>
      <h1>Eventos</h1>
      <div>
        <div>
          {userCtx.user.role.permissions.includes("events.edit") && (
            <button className="btn btn-primary" onClick={handleNewClicked}>
              Nuevo
            </button>
          )}
        </div>
      </div>

      <TableControl
        columns={[
          { header: "Fecha", valueExtractor: (h) => h.date },
          { header: "Tipo", valueExtractor: (h) => h.type },
          { header: "Descripción", valueExtractor: (h) => h.description },
        ]}
        rows={events}
        onRowClicked={handleEventClicked}
        keyExtractor={(e) => e._id}
      />
    </div>
  );
};

export default ColonyEventsPage;
