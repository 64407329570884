import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

const Table = ({
  columns,
  rows,
  keyExtractor,
  footerColumns,
  footerRows = [],
  footerKeyExtractor = [],
  onColumnClicked,
  rowClassNameExtractor,
  onRowClicked,
  sorting,
  showColumns = true,
}) => {
  const getCell = (column, row, index) => {
    return (
      <td
        key={index}
        className={column.className}
        colSpan={column.colspan || 1}
      >
        {column.valueExtractor(row)}
      </td>
    );
  };

  const getFooterCell = (column, row, index) => {
    return (
      <th
        key={index}
        className={column.className}
        colSpan={column.colspan || 1}
      >
        {column.valueExtractor(row)}
      </th>
    );
  };

  return (
    <table className="table table-striped">
      {showColumns && (
        <thead>
          <tr>
            {columns.map((c, index) => (
              <th
                key={index}
                className={`${
                  c.type && sorting && onColumnClicked && "clickable"
                } ${c.className}`}
                onClick={() =>
                  c.type && sorting && onColumnClicked && onColumnClicked(c)
                }
              >
                {c.header}{" "}
                {sorting?.column === c && (
                  <FontAwesomeIcon
                    icon={sorting.ascending ? faCaretDown : faCaretUp}
                  />
                )}
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {rows.map((r, index) => (
          <tr
            key={keyExtractor(r, index)}
            onClick={() => onRowClicked && onRowClicked(r)}
            className={`${onRowClicked && "clickable"} ${
              rowClassNameExtractor && rowClassNameExtractor(r)
            }`}
          >
            {columns.map((c, index) => getCell(c, r, index))}
          </tr>
        ))}
      </tbody>
      {footerRows && footerColumns && (
        <tfoot>
          {footerRows.map((r) => (
            <tr key={footerKeyExtractor(r)}>
              {footerColumns.map((c, index) => getFooterCell(c, r, index))}
            </tr>
          ))}
        </tfoot>
      )}
    </table>
  );
};
export default Table;
