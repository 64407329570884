import { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisV,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";

import validateUtil from "../../../../utils/validate.util";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import TextInput from "../../../controls/text.input";
import SelectInput from "../../../controls/select.input";
import TextareaInput from "../../../controls/textarea.input";
import InformationButton from "../../../controls/information.button";
import TableControl from "../../../controls/table.control";
import { useMessage } from "../../../contexts/message.context";

export default () => {
  const navigate = useNavigate();
  const params = useParams();

  const userCtx = useUser();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [oldHouse, setOldHouse] = useState();
  const [newHouse, setNewHouse] = useState();
  const [habitantTypes, setHabitantTypes] = useState([]);
  const [isWorking, setIsWorking] = useState(false);

  const sortContacts = (contacts) => {
    contacts.sort((a, b) => a.name.localeCompare(b.name));
  };

  const refresh = async () => {
    try {
      const habitantTypes =
        await userCtx.webserviceClient.houses.getHabitantTypes();
      const house = await userCtx.webserviceClient.houses.getById(
        userCtx.user.colony._id,
        params.houseId
      );

      sortContacts(house.contacts);
      house.maintenance = house.maintenance.toFixed(2);
      setHabitantTypes(habitantTypes);
      setOldHouse(house);
      setNewHouse(JSON.parse(JSON.stringify(house)));
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, [userCtx.user, params.houseId]);

  if (!newHouse) return null;

  const hasFormChanged = () => {
    return JSON.stringify(oldHouse) !== JSON.stringify(newHouse);
  };

  const isFormOk = () => {
    if (!validateUtil.number.isCurrency(newHouse.maintenance, 2)) return false;

    return true;
  };

  const handleUpdateClicked = async () => {
    try {
      setIsWorking(true);
      await userCtx.webserviceClient.houses.update(
        userCtx.user.colony._id,
        params.houseId,
        newHouse.maintenance,
        newHouse.bankReference,
        newHouse.habitantType,
        newHouse.note
      );
      await refresh();
      setIsWorking(false);
      messageCtx.showSuccess("Casa guardado");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const handleAddContactClicked = () => {
    navigate("./contactos/nuevo");
  };

  const handleEditContactClicked = (contact) => {
    navigate(`./contactos/${contact._id}`);
  };

  const handleEventClicked = (event) => {
    navigate(`./eventos/${event._id}`);
  };

  const handleAddEventClicked = () => {
    navigate("./eventos/nuevo");
  };

  const isReadonly = !userCtx.user.role.permissions.includes("houses.edit");

  return (
    <div>
      <h1>Casa</h1>
      <div>
        <div className="mb-2">
          <label className="form-label">Dirección</label>
          <TextInput readonly={true} value={newHouse.address} />
        </div>

        <div className="mb-2">
          <label className="form-label">Saldo</label>
          <TextInput
            readonly={true}
            value={newHouse.account.balance.toFixed(2)}
          />
        </div>

        <div className="mb-2">
          <label className="form-label">
            Mantenimiento{" "}
            <InformationButton
              title="Mantenimiento"
              subjects={[
                {
                  description: "La cuota mensual que se carga a la casa.",
                },
              ]}
            />
          </label>
          <TextInput
            type="number"
            readonly={isReadonly}
            value={newHouse.maintenance}
            disabled={isWorking}
            onChange={(value) =>
              setNewHouse({ ...newHouse, maintenance: value })
            }
          />
        </div>

        <div className="mb-2">
          <label className="form-label">
            Referencia bancario{" "}
            <InformationButton
              title="Referencia bancario"
              subjects={[
                {
                  description:
                    "Referencia que la casa debe usar al pagar en el banco. Al usar otra, no se acredita la casa. 2 casas no pueden tener la misma referencia.",
                },
              ]}
            />
          </label>
          <TextInput
            readonly={isReadonly}
            value={newHouse.bankReference}
            disabled={isWorking}
            onChange={(value) =>
              setNewHouse({ ...newHouse, bankReference: value })
            }
          />
        </div>

        <div className="mb-2">
          <label className="form-label">Habitado por</label>
          <SelectInput
            readonly={isReadonly}
            value={newHouse.habitantType}
            disabled={isWorking}
            onChange={(value) =>
              setNewHouse({ ...newHouse, habitantType: value })
            }
            items={habitantTypes}
            valueExtractor={(i) => i.name}
            nameExtractor={(i) => i.name}
          />
        </div>

        {userCtx.user.role.permissions.includes("houses.view") && (
          <div className="mb-2">
            <label className="form-label">
              Nota{" "}
              <InformationButton
                title="Nota"
                subjects={[
                  {
                    description:
                      "Informacion extra sobre la casa. Solo se muestra al tener el permiso 'casas > ver'.",
                  },
                ]}
              />
            </label>
            <TextareaInput
              value={newHouse.note}
              rows="3"
              readonly={isReadonly}
              disabled={isWorking}
              onChange={(value) => setNewHouse({ ...newHouse, note: value })}
            ></TextareaInput>
          </div>
        )}

        <div className="mb-2">
          <div className="form-label">
            Contactos{" "}
            <InformationButton
              title="Contactos"
              subjects={[
                {
                  description:
                    "Los correos de cada usuario se pueden usar para ingresar al sistema. Cada contacto tiene su propia contraseña.",
                },
              ]}
            />
            {!isReadonly && (
              <span
                className="clickable ms-1"
                onClick={handleAddContactClicked}
              >
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  className="color-primary"
                />
              </span>
            )}
          </div>
          <TableControl
            columns={[
              { header: "Nombre", valueExtractor: (c) => c.name },
              {
                header: "Correos",
                valueExtractor: (c) =>
                  c.emails.map((e) => <div>{e.address}</div>),
              },
              {
                header: "Telefonos",
                valueExtractor: (c) =>
                  c.phones.map((p) => <div>{p.number}</div>),
              },
              { header: "Nota", valueExtractor: (c) => c.note },
            ]}
            rows={newHouse.contacts}
            onRowClicked={handleEditContactClicked}
            keyExtractor={(c) => c._id}
          />
        </div>

        {userCtx.user.role.permissions.includes("houses.view") && (
          <div className="mb-2">
            <div className="form-label">
              Eventos{" "}
              <InformationButton
                title="Eventos"
                subjects={[
                  {
                    description: "Cambios de inquilino y/o dueño",
                  },
                ]}
              />
              {!isReadonly && (
                <span
                  className="clickable ms-1"
                  onClick={handleAddEventClicked}
                >
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    className="color-primary"
                  />
                </span>
              )}
            </div>

            <TableControl
              columns={[
                { header: "Fecha", valueExtractor: (h) => h.date },
                { header: "Tipo", valueExtractor: (h) => h.type },
                { header: "Descripción", valueExtractor: (h) => h.description },
              ]}
              rows={newHouse.events}
              onRowClicked={handleEventClicked}
              keyExtractor={(e) => e._id}
            />
          </div>
        )}

        {!isReadonly && (
          <div>
            <button
              className="btn btn-primary me-2"
              onClick={handleUpdateClicked}
              disabled={!isFormOk() || !hasFormChanged() || isWorking}
            >
              Guardar
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
