import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { faFilter, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import localStorageUtil from "../../../../utils/localStorage.util";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import TextInput from "../../../controls/text.input";
import CheckboxesInput from "../../../controls/checkboxes.input";
import TableControl from "../../../controls/table.control";

const getDefaultFilter = (types) => {
  return {
    month: DateTime.now().toFormat("yyyy-MM"),
    types,
    description: "",
  };
};
// https://stackoverflow.com/questions/18672452/left-align-and-right-align-within-div-in-bootstrap
export default () => {
  const navigate = useNavigate();

  const userCtx = useUser();
  const { handleException } = useException();

  const [transactions, setTransactions] = useState([]);
  const [types, setTypes] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState();

  const refreshTransactions = async () => {
    if (!filter) return;

    try {
      const transactions =
        await userCtx.webserviceClient.colonyTransactions.getByColony(
          userCtx.user.colony._id,
          filter.month
        );
      setTransactions(transactions);
    } catch (ex) {
      handleException(ex);
    }
  };

  useEffect(() => {
    refreshTransactions();
  }, [filter?.month]);

  const saveFilter = (filter) => {
    localStorageUtil.set("transactionsFilter", JSON.stringify(filter));
    setFilter(filter);
  };

  const loadFilter = (types) => {
    try {
      const json = localStorageUtil.get("transactionsFilter");
      if (!json) throw "";
      const filter = JSON.parse(json);
      filter.description = filter.description || "";
      return filter;
    } catch {
      return getDefaultFilter(types);
    }
  };

  const refresh = async () => {
    try {
      const types = (
        await userCtx.webserviceClient.colonyTransactions.getTypes()
      ).map((t) => t.name);
      setTypes(types);

      const filter = loadFilter(types);
      setFilter(filter);

      refreshTransactions();
    } catch (ex) {
      handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleNewClicked = () => {
    navigate("./nuevo"); // , { replace: true }};
  };

  const handleToggleFilterClicked = () => {
    setShowFilter(!showFilter);
  };

  const handleResetFilterClicked = () => {
    saveFilter(getDefaultFilter(types));
  };

  const handleTransactionClicked = (transaction) => {
    navigate(`./${transaction._id}`);
  };

  const filteredTransactions = transactions.filter(
    (t) =>
      filter.types.includes(t.type) &&
      (filter.description.trim().length === 0 ||
        t.description.toLowerCase().includes(filter.description))
  );

  if (!filter) return null;

  return (
    <div>
      <h1>Movimientos</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          {userCtx.user.role.permissions.includes(
            "colonyTransactions.edit"
          ) && (
            <button className="btn btn-primary" onClick={handleNewClicked}>
              Nuevo
            </button>
          )}
        </div>
        <div className="btn-group">
          <button
            className="btn btn-primary"
            onClick={handleToggleFilterClicked}
          >
            <FontAwesomeIcon icon={faFilter} />
          </button>
          {JSON.stringify(getDefaultFilter(types)) !==
            JSON.stringify(filter) && (
            <button
              className="btn btn-danger"
              onClick={handleResetFilterClicked}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          )}
        </div>
      </div>
      <div style={{ display: showFilter ? "block" : "none" }}>
        <div className="mb-2">
          <label className="form-label">Mes</label>
          <TextInput
            type="month"
            value={filter.month}
            max={DateTime.now().toFormat("yyyy-MM")}
            onChange={(value) => saveFilter({ ...filter, month: value })}
          />
        </div>
        <div>
          <div className="mb-2">
            <label className="form-label">Tipos</label>
            <CheckboxesInput
              items={types}
              nameExtractor={(e) => e}
              valueExtractor={(e) => e}
              values={filter.types}
              onChange={(values) => saveFilter({ ...filter, types: values })}
            />
          </div>
        </div>
        <div>
          <div className="mb-2">
            <label className="form-label">Descripcíon</label>
            <TextInput
              value={filter.description}
              onChange={(value) =>
                saveFilter({ ...filter, description: value })
              }
            />
          </div>
        </div>
      </div>

      <TableControl
        columns={[
          { header: "Fecha", valueExtractor: (t) => t.payDate },
          { header: "Tipo", valueExtractor: (t) => t.type },
          { header: "Descripción", valueExtractor: (t) => t.description },
          {
            header: "Cantidad",
            valueExtractor: (t) => t.amount.toFixed(2),
            className: "text-end",
          },
        ]}
        rows={filteredTransactions}
        onRowClicked={handleTransactionClicked}
        keyExtractor={(t) => t._id}
      />
    </div>
  );
};
