import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import { useMessage } from "../../../contexts/message.context";
import FieldsButton from "../../../controls/fieldsButton.control";
import TextInput from "../../../controls/text.input";
import TextareaInput from "../../../controls/textarea.input";
import SelectInput from "../../../controls/select.input";
import ConfirmModal from "../../../controls/confirm.modal";

export default () => {
  const navigate = useNavigate();
  const params = useParams();

  const userCtx = useUser();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [oldTemplate, setOldTemplate] = useState();
  const [newTemplate, setNewTemplate] = useState();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [attachmentTypes, setAttachmentTypes] = useState([]);
  const subjectRef = useRef();
  const bodyRef = useRef();
  const [isWorking, setIsWorking] = useState(false);

  const refresh = async () => {
    try {
      setIsWorking(true);
      const attachmentTypes =
        await userCtx.webserviceClient.templates.getAttachmentTypes();

      setAttachmentTypes(attachmentTypes);

      if (params.templateId) {
        const template = await userCtx.webserviceClient.templates.getById(
          userCtx.user.colony._id,
          params.templateId
        );

        setOldTemplate(template);
        setNewTemplate({ ...template });
      } else {
        setNewTemplate({
          name: "",
          subject: "",
          body: "",
          attachmentTypeId:
            attachmentTypes.length > 0 ? attachmentTypes[0].id : undefined,
        });
      }
    } catch (ex) {
      exceptionCtx.handleException(ex);
    } finally {
      setIsWorking(false);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleConfirmDeleteModalClose = async (confirm) => {
    setShowConfirmDeleteModal(false);
    if (!confirm) return;

    try {
      setIsWorking(true);
      await userCtx.webserviceClient.templates.delete(
        userCtx.user.colony._id,
        params.templateId
      );
      messageCtx.showSuccess("Plantilla borrada");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
      setIsWorking(false);
    }
  };

  const handleCreateClicked = async () => {
    try {
      setIsWorking(true);
      await userCtx.webserviceClient.templates.insert(
        userCtx.user.colony._id,
        newTemplate.name,
        newTemplate.subject,
        newTemplate.body,
        newTemplate.attachmentTypeId
      );
      messageCtx.showSuccess("Plantilla creado");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
      setIsWorking(false);
    }
  };

  const handleUpdateClicked = async () => {
    try {
      setIsWorking(true);
      await userCtx.webserviceClient.templates.update(
        userCtx.user.colony._id,
        params.templateId,
        newTemplate.name,
        newTemplate.subject,
        newTemplate.body,
        newTemplate.attachmentTypeId
      );
      messageCtx.showSuccess("Plantilla guardado");
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
      setIsWorking(false);
    }
  };

  const isFormOk = () => {
    if (!newTemplate.name) return false;
    if (!newTemplate.subject) return false;
    if (!newTemplate.body) return false;
    if (!newTemplate.attachmentTypeId) return false;
    return true;
  };

  if (!newTemplate) return null;

  return (
    <div>
      <h1>Plantilla</h1>
      <div>
        <div className="mb-2">
          <label className="form-label">Nombre</label>
          <TextInput
            disabled={isWorking}
            readonly={!userCtx.user.role.permissions.includes("templates.edit")}
            value={newTemplate.name}
            onChange={(value) =>
              setNewTemplate({ ...newTemplate, name: value })
            }
          />
        </div>
        <div className="mb-2">
          <label className="form-label">
            Asunto
            {userCtx.user.role.permissions.includes("templates.edit") && (
              <FieldsButton
                element={subjectRef}
                setter={(value) =>
                  setNewTemplate({ ...newTemplate, subject: value })
                }
                disabled={isWorking}
              />
            )}
          </label>
          <TextInput
            ref={subjectRef}
            value={newTemplate.subject}
            readonly={!userCtx.user.role.permissions.includes("templates.edit")}
            onChange={(value) =>
              setNewTemplate({ ...newTemplate, subject: value })
            }
            disabled={isWorking}
          />
        </div>
        <div className="mb-2">
          <label className="form-label">
            Mensaje
            {userCtx.user.role.permissions.includes("templates.edit") && (
              <FieldsButton
                element={bodyRef}
                setter={(value) =>
                  setNewTemplate({ ...newTemplate, body: value })
                }
                disabled={isWorking}
              />
            )}
          </label>

          <TextareaInput
            ref={bodyRef}
            value={newTemplate.body}
            onChange={(value) =>
              setNewTemplate({ ...newTemplate, body: value })
            }
            rows={6}
            disabled={isWorking}
            readonly={!userCtx.user.role.permissions.includes("templates.edit")}
          />
        </div>
        <div className="mb-2">
          <label className="form-label">Adjuntar archivo</label>
          <SelectInput
            value={newTemplate.attachmentTypeId}
            onChange={(value) =>
              setNewTemplate({ ...newTemplate, attachmentTypeId: value })
            }
            items={attachmentTypes}
            nameExtractor={(i) => i.name}
            valueExtractor={(i) => i.id}
            disabled={isWorking}
            readonly={!userCtx.user.role.permissions.includes("templates.edit")}
          />
        </div>

        {userCtx.user.role.permissions.includes("templates.edit") && (
          <div className="mb-2">
            {!params.templateId && (
              <button
                className="btn btn-primary me-2"
                disabled={!isFormOk()}
                onClick={handleCreateClicked}
              >
                Crear
              </button>
            )}
            {params.templateId && (
              <button
                className="btn btn-primary me-2"
                disabled={!isFormOk()}
                onClick={handleUpdateClicked}
              >
                Guardar
              </button>
            )}
            {params.templateId && (
              <button
                className="btn btn-danger me-2"
                onClick={() => setShowConfirmDeleteModal(true)}
              >
                Borrar
              </button>
            )}
          </div>
        )}
      </div>
      <ConfirmModal
        cancelButtonText="No"
        confirmButtonText="Sí"
        titleText="Borrar plantilla"
        questionText="Seguro que quieres borrar plantilla?"
        show={showConfirmDeleteModal}
        onClose={handleConfirmDeleteModalClose}
      />
    </div>
  );
};
