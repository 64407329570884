import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import { useMessage } from "../../../contexts/message.context";
import TableControl from "../../../controls/table.control";

const ColoniesPage = () => {
  const navigate = useNavigate();

  const userCtx = useUser();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [colonies, setColonies] = useState([]);

  const refresh = async () => {
    try {
      const colonies = await userCtx.webserviceClient.colonies.getAll();
      setColonies(colonies);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleColonyClicked = (colony) => {
    userCtx.setUser({ ...userCtx.user, colony });
    messageCtx.showSuccess("Colonia seleccionada");
    navigate(`/inicio`);
  };

  const handleDeselectClicked = () => {
    userCtx.setUser({ ...userCtx.user, colony: undefined });
  };

  const handleNewClicked = () => {
    navigate("./nueva");
  };

  const handleNewDemoClicked = () => {
    navigate("./nuevodemo");
  };
  return (
    <div>
      <h1>Colonias</h1>
      <div>
        <div>
          {userCtx.user.role.permissions.includes("colonies.edit") && (
            <button className="btn btn-primary me-1" onClick={handleNewClicked}>
              Nueva
            </button>
          )}
          {userCtx.user.role.permissions.includes("colonies.edit") && (
            <button
              className="btn btn-primary me-1"
              onClick={handleNewDemoClicked}
            >
              Nuevo demo
            </button>
          )}
          {userCtx.user.colony && (
            <button className="btn btn-primary" onClick={handleDeselectClicked}>
              Deseleccionar
            </button>
          )}
        </div>
      </div>

      <TableControl
        columns={[
          {
            header: "Nombre",
            valueExtractor: (c) => c.name,
          },
          {
            header: "Se borra",
            valueExtractor: (c) =>
              c.deleteAt ? DateTime.fromISO(c.deleteAt).toISODate() : undefined,
          },
          {
            header: "Cuota",
            className: "text-end",
            valueExtractor: (c) => c.client.price.toFixed(2),
          },
        ]}
        rows={colonies}
        onRowClicked={handleColonyClicked}
        keyExtractor={(c) => c._id}
      />
    </div>
  );
};

export default ColoniesPage;
