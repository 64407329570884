import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import validateUtil from "../../../../utils/validate.util";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import { useMessage } from "../../../contexts/message.context";
import TextareaInput from "../../../controls/textarea.input";
import TextInput from "../../../controls/text.input";
import InformationButton from "../../../controls/information.button";
import ConfirmModal from "../../../controls/confirm.modal";

export default () => {
  const navigate = useNavigate();
  const params = useParams();

  const userCtx = useUser();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [house, setHouse] = useState();
  const [oldContact, setOldContact] = useState();
  const [newContact, setNewContact] = useState();
  const [isWorking, setIsWorking] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [
    showConfirmGeneratePasswordModal,
    setShowConfirmGeneratePasswordModal,
  ] = useState(false);

  const refresh = async () => {
    try {
      const house = await userCtx.webserviceClient.houses.getById(
        userCtx.user.colony._id,
        params.houseId
      );
      setHouse(house);

      if (params.contactId) {
        const contact = await userCtx.webserviceClient.houses.getContactById(
          userCtx.user.colony._id,
          params.houseId,
          params.contactId
        );

        setOldContact(contact);
        setNewContact({ ...contact });
      } else {
        setNewContact({ emails: [], name: "", phones: [], note: "" });
      }
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleCreateClicked = async () => {
    try {
      setIsWorking(true);
      await userCtx.webserviceClient.houses.insertContact(
        userCtx.user.colony._id,
        params.houseId,
        newContact.name,
        newContact.emails.filter((e) => e.address),
        newContact.phones.filter((p) => p.number),
        newContact.note
      );
      messageCtx.showSuccess("Contacto guardado");
      navigate("./../..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const handleUpdateClicked = async () => {
    try {
      setIsWorking(true);
      await userCtx.webserviceClient.houses.updateContact(
        userCtx.user.colony._id,
        params.houseId,
        params.contactId,
        newContact.name,
        newContact.emails.filter((e) => e.address),
        newContact.phones.filter((p) => p.number),
        newContact.note
      );
      messageCtx.showSuccess("Contacto guardado");
      navigate("./../..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const handleConfirmGeneratePasswordClose = async (confirm) => {
    setShowConfirmGeneratePasswordModal(false);
    if (!confirm) return;

    try {
      await userCtx.webserviceClient.houses.updateContactPassword(
        userCtx.user.colony._id,
        params.houseId,
        newContact._id
      );

      messageCtx.showSuccess(
        "Se generó una nueva contraseña y se envió por correo a los correos registrados"
      );
      setIsWorking(false);
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const handleUpdatePasswordClicked = async () => {
    setShowConfirmGeneratePasswordModal(true);
  };

  const handleConfirmDeleteModalClose = async (confirm) => {
    setShowConfirmDeleteModal(false);
    if (!confirm) return;

    try {
      setIsWorking(true);
      await userCtx.webserviceClient.houses.deleteContact(
        userCtx.user.colony._id,
        params.houseId,
        params.contactId
      );
      messageCtx.showSuccess("Contacto borrado");
      navigate("./../..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const handleDeleteClicked = async () => {
    setShowConfirmDeleteModal(true);
  };

  const isFormOk = () => {
    if (!validateUtil.string.isValid(newContact.name, true, 1)) return false;

    const addresses = newContact.emails
      .map((e) => e.address.trim())
      .filter((address) => !!address);

    if (!addresses.every((address) => validateUtil.email.isValid(address))) {
      return false;
    }

    if (addresses.distinct().length !== addresses.length) return false;

    const numbers = newContact.phones
      .map((p) => p.number.trim())
      .filter((number) => !!number);

    if (numbers.distinct().length !== numbers.length) return false;

    if (
      !numbers.every((number) =>
        validateUtil.string.isValid(number, true, 10, 10)
      )
    ) {
      return false;
    }

    return true;
  };

  const hasFormChanged = () => {
    return JSON.stringify(oldContact) !== JSON.stringify(newContact);
  };

  if (!newContact) return null;

  const isReadonly = !userCtx.user.role.permissions.includes("houses.edit");

  return (
    <div>
      <h1>Contacto</h1>
      <div>
        <div className="form-label">Casa direccíon</div>
        <div>
          <TextInput value={house.address} readonly={true} />
        </div>
        <div className="form-label mt-2">
          Nombre{" "}
          <InformationButton
            title="Nombre"
            subjects={[
              {
                description:
                  "Nombre del contacto. Visible para el usuario al ingresar al sistema.",
              },
            ]}
          />
        </div>
        <div>
          <TextInput
            value={newContact.name}
            onChange={(value) => setNewContact({ ...newContact, name: value })}
            readonly={isReadonly}
          />
        </div>
        <div className="form-label mt-2">
          Correos{" "}
          <InformationButton
            title="Correos"
            subjects={[
              {
                description:
                  "Un correo por linea, correos no se pueden repitir",
              },
            ]}
          />
        </div>
        <div>
          <TextareaInput
            value={newContact.emails.map((e) => e.address).join("\n")}
            rows="3"
            onChange={(value) =>
              setNewContact({
                ...newContact,
                emails: value.split("\n").map((a) => ({ address: a })),
              })
            }
            readonly={isReadonly}
          />
        </div>
        <div className="form-label mt-2">
          Telefonos{" "}
          <InformationButton
            title="Telefonos"
            subjects={[
              {
                description:
                  "Un número por linea, números no se pueden repitir",
              },
            ]}
          />
        </div>
        <div>
          <TextareaInput
            value={newContact.phones.map((p) => p.number).join("\n")}
            rows="3"
            onChange={(value) =>
              setNewContact({
                ...newContact,
                phones: value.split("\n").map((n) => ({ number: n })),
              })
            }
            readonly={isReadonly}
          />
        </div>
        <div className="form-label mt-2">
          Nota{" "}
          <InformationButton
            title="Nota"
            subjects={[
              {
                description:
                  "Informacion extra del contacto, por ejemplo, rentero, dueño etc.",
              },
            ]}
          />
        </div>
        <div>
          <TextInput
            value={newContact.note}
            onChange={(value) => setNewContact({ ...newContact, note: value })}
            readonly={isReadonly}
          />
        </div>
      </div>
      {!isReadonly && (
        <div className="mt-2">
          {oldContact && (
            <button
              className="btn btn-primary me-2"
              disabled={isWorking || !hasFormChanged() || !isFormOk()}
              onClick={handleUpdateClicked}
            >
              Guardar
            </button>
          )}
          {!oldContact && (
            <button
              className="btn btn-primary me-2"
              onClick={handleCreateClicked}
              disabled={!isFormOk() || isWorking}
            >
              Guardar
            </button>
          )}
          {oldContact && (
            <button
              className="btn btn-danger me-2"
              disabled={isWorking}
              onClick={handleDeleteClicked}
            >
              Borrar
            </button>
          )}
          {oldContact && (
            <button
              className="btn btn-primary me-2"
              disabled={isWorking || hasFormChanged()}
              onClick={handleUpdatePasswordClicked}
            >
              Mandar nueva contraseña
            </button>
          )}
        </div>
      )}
      <ConfirmModal
        cancelButtonText="No"
        confirmButtonText="Sí"
        titleText="Borrar contacto"
        questionText="Seguro que quieres borrar contacto?"
        show={showConfirmDeleteModal}
        onClose={handleConfirmDeleteModalClose}
      />
      <ConfirmModal
        cancelButtonText="Cerrar"
        confirmButtonText="Generar"
        questionText="Quieres enviar credenciales a los correos registrados?"
        titleText="Generar nueva contraseña"
        show={showConfirmGeneratePasswordModal}
        onClose={handleConfirmGeneratePasswordClose}
      />
    </div>
  );
};
