import { DateTime } from "luxon";
import { useRef } from "react";

export const isDateTime = (datetime) => {
  const datas = datetime.split("T");
  return datas.length === 2;
};

export const getDate = (datetime) => {
  if (datetime === "") return "";
  const datas = datetime.split("T");
  if (datas.length > 0) return datas[0];
  return "";
};

export const getTime = (datetime) => {
  if (datetime === "") return "";
  const datas = datetime.split("T");
  if (datas.length > 1) return datas[1];
  return "";
};

export default ({ readonly, disabled, value, onChange }) => {
  const dateRef = useRef();
  const timeRef = useRef();

  const handleChange = () => {
    const value = `${dateRef.current.value}T${timeRef.current.value}`;
    onChange(value);
  };

  if (readonly) {
    return <label className="form-control-plaintext">{value}</label>;
  } else {
    return (
      <div className="input-group">
        <input
          ref={dateRef}
          disabled={disabled}
          type="date"
          className="form-control"
          value={getDate(value)}
          min={DateTime.now().toISODate()}
          onChange={handleChange}
        ></input>
        <input
          ref={timeRef}
          disabled={disabled}
          type="time"
          className="form-control"
          value={getTime(value)}
          onChange={handleChange}
        ></input>
      </div>
    );
  }
};
