import { forwardRef } from "react";

export default forwardRef(
  ({ readonly, value, disabled, onChange, min, max, ...rest }, ref) => {
    if (readonly) {
      return <label className="form-control-plaintext">{value}</label>;
    } else {
      return (
        <input
          ref={ref}
          type="number"
          className="form-control"
          value={value}
          min={min}
          max={max}
          disabled={disabled}
          onChange={(e) => onChange(e.target.value, e)}
          {...rest}
        />
      );
    }
  }
);
