import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import TableControl from "../../../controls/table.control";

const Page = () => {
  const navigate = useNavigate();

  const userCtx = useUser();
  const exceptionCtx = useException();

  const [areas, setAreas] = useState([]);

  const refresh = async () => {
    try {
      const areas = await userCtx.webserviceClient.areas.getAll(
        userCtx.user.colony._id
      );
      setAreas(areas);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleAreaClicked = (area) => {
    navigate(`./${area._id}`);
  };

  const handleNewClicked = () => {
    navigate("./nueva");
  };

  useEffect(() => {
    refresh();
  }, []);

  return (
    <div>
      <h1>Areas</h1>
      {userCtx.user.role.permissions.includes("areas.edit") && (
        <button onClick={handleNewClicked} className="btn btn-primary">
          Nueva
        </button>
      )}

      <TableControl
        columns={[
          { header: "Nombre", valueExtractor: (a) => a.name },
          {
            header: "Color",
            valueExtractor: (a) => (
              <div
                style={{
                  backgroundColor: a.color,
                  borderRadius: 5,
                  width: 20,
                  height: 20,
                }}
              ></div>
            ),
          },
        ]}
        rows={areas}
        onRowClicked={handleAreaClicked}
        keyExtractor={(a) => a._id}
      />
    </div>
  );
};

export default Page;
