import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import TextInput from "../../../controls/text.input";
import SectioncheckboxesInput from "../../../controls/sectioncheckboxes.input";
import ConfirmModal from "../../../controls/confirm.modal";
import { useMessage } from "../../../contexts/message.context";

export default () => {
  const navigate = useNavigate();
  const params = useParams();

  const userCtx = useUser();
  const exceptionCtx = useException();
  const messgeCtx = useMessage();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [isWorking, setIsWorking] = useState(false);
  const [oldRole, setOldRole] = useState();
  const [newRole, setNewRole] = useState();

  useEffect(() => {
    const refresh = async () => {
      const permissions = await userCtx.webserviceClient.roles.getPermissions();
      permissions.sort((a, b) => a.name.localeCompare(b.name));
      setPermissions(permissions);

      if (params.roleId) {
        const role2 = await userCtx.webserviceClient.roles.getById(
          userCtx.user.colony._id,
          params.roleId
        );

        setOldRole(role2);
        setNewRole({ ...role2 });
      } else {
        setNewRole({ name: "", permissions: [] });
      }
    };
    refresh();
  }, [userCtx.user, params]);

  const handleCreateClicked = async () => {
    try {
      setIsWorking(true);
      await userCtx.webserviceClient.roles.insert(
        userCtx.user.colony._id,
        newRole.name,
        newRole.permissions
      );
      messgeCtx.showSuccess("Rol guardado");
      //window.document.location.href = "/roles";
      navigate("./..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const handleConfirmDeleteModalClose = async (confirm) => {
    setShowConfirmDeleteModal(false);
    if (!confirm) return;

    try {
      setIsWorking(true);
      await userCtx.webserviceClient.roles.delete(
        userCtx.user.colony._id,
        params.roleId
      );
      messgeCtx.showSuccess("Rol borrado");

      navigate("./..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const handleUpdateClicked = async () => {
    try {
      setIsWorking(true);

      await userCtx.webserviceClient.roles.update(
        userCtx.user.colony._id,
        newRole._id,
        newRole.name,
        newRole.permissions
      );
      messgeCtx.showSuccess("Rol guardado");
      //window.document.location.href = "/roles";
      navigate("./..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const isFormOk = () => {
    if (!newRole.name) return false;

    return true;
  };

  const hasFormChanged = () => {
    return JSON.stringify(oldRole) !== JSON.stringify(newRole);
  };

  if (!newRole) return null;

  return (
    <div>
      <h1>{oldRole ? "Rol" : "Nueva rol"}</h1>
      <div className="mb-2">
        <label className="form-label">Nombre</label>
        <TextInput
          value={newRole.name}
          disabled={isWorking}
          readonly={!userCtx.user.role.permissions.includes("roles.edit")}
          onChange={(value) => setNewRole({ ...newRole, name: value })}
        />
      </div>
      <div className="mb-2">
        <label className="form-label">Permisos</label>
        <SectioncheckboxesInput
          readonly={!userCtx.user.role.permissions.includes("roles.edit")}
          items={permissions}
          disabled={isWorking}
          values={newRole.permissions}
          onChange={(values) => setNewRole({ ...newRole, permissions: values })}
          sectionNameExtractor={(i) => i.category}
          sectionValueExtractor={(i) => i.category}
          nameExtractor={(i) => i.name}
          valueExtractor={(i) => i.id}
        />
      </div>
      {userCtx.user.role.permissions.includes("roles.edit") && (
        <div className="mb-2">
          {!oldRole && (
            <button
              className="btn btn-primary"
              onClick={handleCreateClicked}
              disabled={!isFormOk() || isWorking}
            >
              Crear
            </button>
          )}
          {oldRole && (
            <button
              className="btn btn-primary me-2"
              onClick={handleUpdateClicked}
              disabled={!isFormOk() || !hasFormChanged() || isWorking}
            >
              Guardar
            </button>
          )}
          {oldRole && !oldRole.isSystem && (
            <button
              className="btn btn-danger me-2"
              disabled={isWorking}
              onClick={() => setShowConfirmDeleteModal(true)}
            >
              Borrar
            </button>
          )}
        </div>
      )}
      <ConfirmModal
        cancelButtonText="No"
        confirmButtonText="Sí"
        titleText="Borrar rol"
        questionText="Seguro que quieres borrar rol?"
        show={showConfirmDeleteModal}
        onClose={handleConfirmDeleteModalClose}
      />
    </div>
  );
};
