import { Routes, Route, Navigate } from "react-router-dom";

import ClientTransactionsPage from "./clientTransactions/transactions.page";
import NewClientTransactionPage from "./clientTransactions/newTransaction.page";
import ClientTransactionPage from "./clientTransactions/transaction.page";
import JobsPage from "./jobs/jobs.page";
import JobPage from "./jobs/job.page";
import RolesPage from "./roles/roles.page";
import RolePage from "./roles/role.page";
import ColoniesPage from "./colonies/colonies.page";
import NewColonyPage from "./colonies/newColony.page";
import NewColonyDemoPage from "./colonies/newColonyDemo.page";
import EventsPage from "./events/events.page";
import EventPage from "./events/event.page";
import ColonyConfigurationPage from "./colonyConfiguration/configuration.page";
import ClientConfigurationPage from "./clientConfiguration/configuration.page";

import StartPage from "./start/start.page";
import HousesPage from "./houses/houses.page";
import HousePage from "./houses/house.page";
import HouseEventPage from "./houses/houseEvent.page";
import HouseContactPage from "./houses/houseContact.page";
import ColonyTransactionsPage from "./colonyTransactions/transactions.page";
import NewColonyTransactionPage from "./colonyTransactions/newTransaction.page";
import ColonyTransactionPage from "./colonyTransactions/transaction.page";
import ReportsPage from "./reports/reports.page";
import ColonyAccountsPage from "./colonyAccounts/accounts.page";
import ColonyAccountPage from "./colonyAccounts/account.page";
import UsersPage from "./users/users.page";
import UserPage from "./users/user.page";
import DocumentsPage from "./documents/documents.page";
import DocumentPage from "./documents/document.page";
import EmailsPage from "./emails/emails.page";
import ReservationsPage from "./reservations/reservations.page";
import ReservationPage from "./reservations/reservation.page";
import AreasPage from "./areas/areas.page";
import AreaPage from "./areas/area.page";
import TemplatesPage from "./templates/templates.page";
import TemplatePage from "./templates/template.page";

import { useUser } from "../../contexts/user.context";

import NavBar from "./navbar";

const Nav = () => {
  const userCtx = useUser();

  const getDefaultNavigate = () => {
    if (userCtx.user.role.permissions.includes("colonies.view")) {
      return <Navigate to="/colonias" replace />;
    } else if (
      userCtx.user.role.permissions.some((p) => p.startsWith("overview.view"))
    ) {
      return <Navigate to="/inicio" replace />;
    } else if (userCtx.user.role.permissions.includes("transactions.view")) {
      return <Navigate to="/movimientos" replace />;
    } else if (userCtx.user.role.permissions.includes("houses.view")) {
      return <Navigate to="/casas" replace />;
    } else if (
      userCtx.user.role.permissions.some((p) => p.startsWith("reports."))
    ) {
      return <Navigate to="/reportes" replace />;
    } else if (userCtx.user.role.permissions.includes("reservations.view")) {
      return <Navigate to="/calendario" replace />;
    } else if (
      userCtx.user.role.permissions.includes("clientTransactions.view")
    ) {
      return <Navigate to="/facturacion" replace />;
    }

    return null;
  };

  return (
    <div>
      <NavBar />
      <div className="container my-2">
        <Routes>
          <Route path="facturacion">
            <Route index element={<ClientTransactionsPage />} />
            <Route path="nueva" element={<NewClientTransactionPage />} />
            <Route
              path=":clientTransactionId"
              element={<ClientTransactionPage />}
            />
          </Route>
          <Route path="colonias">
            <Route index element={<ColoniesPage />} />
            <Route path="nueva" element={<NewColonyPage />} />
            <Route path="nuevodemo" element={<NewColonyDemoPage />} />
          </Route>
          <Route path="trabajos">
            <Route index element={<JobsPage />} />
            <Route path=":jobId" element={<JobPage />} />
          </Route>
          <Route path="inicio" element={<StartPage />} />
          <Route path="eventos">
            <Route index element={<EventsPage />} />
            <Route path="nuevo" element={<EventPage />} />
            <Route path=":eventId" element={<EventPage />} />
          </Route>
          <Route path="configuracion" element={<ColonyConfigurationPage />} />
          <Route
            path="clienteconfiguracion"
            element={<ClientConfigurationPage />}
          />
          <Route path="casas">
            <Route index element={<HousesPage />} />
            <Route path=":houseId">
              <Route index element={<HousePage />} />
              <Route path="eventos">
                <Route path="nuevo" element={<HouseEventPage />} />
                <Route path=":eventId" element={<HouseEventPage />} />
              </Route>
              <Route path="contactos">
                <Route path="nuevo" element={<HouseContactPage />} />
                <Route path=":contactId" element={<HouseContactPage />} />
              </Route>
            </Route>
          </Route>
          <Route path="documentos">
            <Route index element={<DocumentsPage />} />
            <Route path="nuevo" element={<DocumentPage />} />
            <Route path=":documentId" element={<DocumentPage />} />
          </Route>
          <Route path="cuentas">
            <Route index element={<ColonyAccountsPage />} />
            <Route path="nuevo" element={<ColonyAccountPage />} />
            <Route path=":accountId" element={<ColonyAccountPage />} />
          </Route>
          <Route path="movimientos">
            <Route index element={<ColonyTransactionsPage />} />
            <Route path="nuevo" element={<NewColonyTransactionPage />} />
            <Route path=":transactionId" element={<ColonyTransactionPage />} />
          </Route>
          <Route path="reportes" element={<ReportsPage />} />
          <Route path="usuarios">
            <Route index element={<UsersPage />} />
            <Route path="nuevo" element={<UserPage />} />
            <Route path=":userId" element={<UserPage />} />
          </Route>
          <Route path="roles">
            <Route index element={<RolesPage />} />
            <Route path="nuevo" element={<RolePage />} />
            <Route path=":roleId" element={<RolePage />} />
          </Route>
          <Route path="correos">
            <Route index element={<EmailsPage />} />
          </Route>
          <Route path="calendario">
            <Route index element={<ReservationsPage />} />
            <Route path="nueva" element={<ReservationPage />} />
            <Route path=":reservationId" element={<ReservationPage />} />
          </Route>
          <Route path="areas">
            <Route index element={<AreasPage />} />
            <Route path="nueva" element={<AreaPage />} />
            <Route path=":areaId" element={<AreaPage />} />
          </Route>
          <Route path="plantillas">
            <Route index element={<TemplatesPage />} />
            <Route path="nueva" element={<TemplatePage />} />
            <Route path=":templateId" element={<TemplatePage />} />
          </Route>
          <Route path="*" element={getDefaultNavigate()} />
        </Routes>
      </div>
    </div>
  );
};

export default Nav;
