import { useState, useEffect } from "react";
import { DateTime } from "luxon";

import arrayUtil from "../../../../utils/array.util";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import { useMessage } from "../../../contexts/message.context";
import MonthInput from "../../../controls/month.input";
import MultiSelectInput from "../../../controls/multiselect.input";

const NewTransactionMaintenancesControl = ({
  colony,
  accounts,
  isWorking,
  setIsWorking,
}) => {
  const userCtx = useUser();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [month, setMonth] = useState(DateTime.now().toFormat("yyyy-MM"));
  const [houseAccountIds, setHouseAccountIds] = useState([]);
  const [houseAccounts, setHouseAccounts] = useState([]);
  const [results, setResults] = useState([]);

  useEffect(() => {
    const refresh = async () => {
      try {
        const houseAccounts = accounts
          .filter((a) => a.type === "Casa")
          .sort((a, b) => a.name.localeCompare(b.name));

        setHouseAccounts(houseAccounts);
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };

    refresh();
  }, [accounts]);

  const handleInsertClicked = async () => {
    try {
      setIsWorking(true);
      const results =
        await userCtx.webserviceClient.colonyTransactions.insertMaintenances(
          userCtx.user.colony._id,
          houseAccountIds,
          month
        );

      messageCtx.showMessagesFromResults(results);
      setResults(results);
      setIsWorking(false);
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const isFormOk = () => {
    if (!month) return false;
    if (houseAccountIds.length === 0) return false;

    return true;
  };

  const lastEvent = arrayUtil.last(colony.events);
  const minDate = lastEvent
    ? DateTime.fromISO(lastEvent.date).toFormat("yyyy-MM")
    : "";

  const getColor = (r) => {
    switch (r.state) {
      case "error":
        return "table-danger";
      case "warning":
        return "table-warning";
      case "success":
        return "table-success";
      default:
        return "";
    }
  };

  return (
    <div>
      <div className="mb-2">
        <div className="form-label">Mes</div>
        <MonthInput
          max={DateTime.now().toFormat("yyyy-MM")}
          value={month}
          min={minDate}
          disabled={isWorking}
          onChange={setMonth}
        />
      </div>

      <div className="mb-2">
        <div className="form-label">
          Casas ({houseAccountIds.length} / {houseAccounts.length})
        </div>
        <MultiSelectInput
          items={houseAccounts}
          nameExtractor={(a) => a.name}
          valueExtractor={(a) => a._id}
          values={houseAccountIds}
          disabled={isWorking}
          onChange={setHouseAccountIds}
        />
      </div>

      <div className="mb-2">
        <button
          className="btn btn-primary"
          onClick={handleInsertClicked}
          disabled={!isFormOk() || isWorking}
        >
          Crear
        </button>
      </div>
      {results.length > 0 && (
        <table className="table">
          <thead>
            <tr>
              <th>Número</th>
              <th>Mensaje</th>
            </tr>
          </thead>
          <tbody>
            {results.map((r) => (
              <tr key={r.id} className={getColor(r)}>
                <td>{r.id}</td>
                <td>{r.message}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default NewTransactionMaintenancesControl;
