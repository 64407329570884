export default ({
  readonly,
  value,
  disabled,
  onChange,
  items,
  valueExtractor,
  nameExtractor,
}) => {
  if (readonly) {
    const item = items.find((i) => valueExtractor(i) === value);
    return (
      <label className="form-control-plaintext">
        {item ? nameExtractor(item) : value}
      </label>
    );
  } else {
    return (
      <select
        className="form-select"
        value={value}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
      >
        {items.map((i) => (
          <option key={valueExtractor(i)} value={valueExtractor(i)}>
            {nameExtractor(i)}
          </option>
        ))}
      </select>
    );
  }
};
