import { Link, useNavigate } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import NavDropdown from "react-bootstrap/NavDropdown";

import logo from "../../../assets/ICON7.svg";

import { useUser } from "../../contexts/user.context";
import { useState } from "react";

const items = [
  {
    name: "Trabajos",
    url: "/trabajos",
    isVisible: (user) => user.role.permissions.includes("jobs.view"),
  },
  {
    name: "Colonias",
    url: "/colonias",
    isVisible: (user) => user.role.permissions.includes("colonies.view"),
  },
  {
    name: "Inicio",
    url: "/inicio",
    isVisible: (user) =>
      (user.colony && user.role.permissions.includes("overview.viewclient")) ||
      (user.colony && user.role.permissions.includes("overview.viewcolony")) ||
      (user.colony && user.role.permissions.includes("overview.viewhouse")),
  },
  {
    name: "Casas",
    url: "/casas",
    isVisible: (user) =>
      user.colony && user.role.permissions.includes("houses.view"),
  },
  {
    name: "Movimientos",
    url: "/movimientos",
    isVisible: (user) =>
      user.colony && user.role.permissions.includes("colonyTransactions.view"),
  },
  {
    name: "Calendario",
    url: "/calendario",
    isVisible: (user) =>
      user.colony && user.role.permissions.includes("reservations.view"),
  },
  {
    name: "Reportes",
    url: "/reportes",
    isVisible: (user) =>
      user.colony &&
      user.role.permissions.some((p) => p.startsWith("reports.")),
  },
];

const moreItems = [
  {
    name: "Correos",
    url: "/correos",
    isVisible: (user) =>
      user.colony && user.role.permissions.includes("emails.send"),
  },
  {
    name: "Cuentas",
    url: "/cuentas",
    isVisible: (user) =>
      user.colony && user.role.permissions.includes("colonyAccounts.view"),
  },
  {
    name: "Plantillas",
    url: "/plantillas",
    isVisible: (user) =>
      user.colony && user.role.permissions.includes("templates.view"),
  },
  {
    name: "Areas",
    url: "/areas",
    isVisible: (user) =>
      user.colony && user.role.permissions.includes("areas.view"),
  },
  {
    name: "Eventos",
    url: "/eventos",
    isVisible: (user) =>
      user.colony && user.role.permissions.includes("events.view"),
  },
  {
    name: "Documentos",
    url: "/documentos",
    isVisible: (user) =>
      user.colony && user.role.permissions.includes("documents.view"),
  },
  {
    name: "Usuarios",
    url: "/usuarios",
    isVisible: (user) =>
      user.colony && user.role.permissions.includes("users.view"),
  },
  {
    name: "Roles",
    url: "/roles",
    isVisible: (user) =>
      user.colony && user.role.permissions.includes("roles.view"),
  },
  {
    name: "Movimientos VecinosMX",
    url: "/facturacion",
    isVisible: (user) =>
      user.colony && user.role.permissions.includes("clientTransactions.view"),
  },
  {
    name: "Configuración",
    url: "/configuracion",
    isVisible: (user) =>
      user.colony && user.role.permissions.includes("configuration.view"),
  },
  {
    name: "Configuración VecinosMX",
    url: "/clienteconfiguracion",
    isVisible: (user) =>
      user.colony && user.role.permissions.includes("clientConfiguration.view"),
  },
];

export default () => {
  const navigate = useNavigate();

  const userCtx = useUser();

  const [isExpanded, setIsExpanded] = useState(false);

  const closeNavBar = () => {
    setIsExpanded(false);
  };

  const handleSignoutClicked = () => {
    closeNavBar();
    userCtx.logout();
    navigate("/");
  };

  return (
    <Navbar
      expand="lg"
      className="navbar-light bg-light"
      onToggle={() => setIsExpanded(!isExpanded)}
      expanded={isExpanded}
    >
      <Container>
        <Navbar.Brand>
          <img src={logo} alt="" height="24" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {items.map((i) => {
              if (!i.isVisible(userCtx.user)) return null;

              return (
                <Nav.Link
                  as={Link}
                  to={i.url}
                  onClick={closeNavBar}
                  key={i.url}
                  className={
                    window.location.pathname.startsWith(i.url) ? "active" : ""
                  }
                >
                  {i.name}
                </Nav.Link>
              );
            })}
            {moreItems.some((i) => i.isVisible(userCtx.user)) && (
              <NavDropdown
                title="Mas"
                id="basic-nav-dropdown"
                className={`${
                  moreItems.some((i) =>
                    window.location.pathname.startsWith(i.url)
                  )
                    ? "active"
                    : ""
                }`}
              >
                {moreItems.map((i) => {
                  if (!i.isVisible(userCtx.user)) return null;

                  return (
                    <NavDropdown.Item
                      as={Link}
                      to={i.url}
                      onClick={closeNavBar}
                      key={i.url}
                      className={
                        window.location.pathname.startsWith(i.url)
                          ? "active"
                          : ""
                      }
                    >
                      {i.name}
                    </NavDropdown.Item>
                  );
                })}
              </NavDropdown>
            )}
          </Nav>
          <button
            className="btn btn-outline-danger my-2 my-sm-0"
            onClick={handleSignoutClicked}
          >
            Salir
          </button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
