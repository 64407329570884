import { useEffect, useState, useRef } from "react";
import { DateTime } from "luxon";

import validateUtil from "../../../../utils/validate.util";
import arrayUtil from "../../../../utils/array.util";

import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import { useMessage } from "../../../contexts/message.context";
import DateInput from "../../../controls/date.input";
import SelectInput from "../../../controls/select.input";
import TextInput from "../../../controls/text.input";
import NumberInput from "../../../controls/number.input";
import FileInput from "../../../controls/file.input";
import MultiSelectInput from "../../../controls/multiselect.input";

const NewTransactionChargesControl = ({
  colony,
  accounts,
  isWorking,
  setIsWorking,
}) => {
  const userCtx = useUser();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [chargeAccountId, setChargeAccountId] = useState();
  const [chargeAccounts, setChargeAccounts] = useState([]);
  const [houseAccountIds, setHouseAccountIds] = useState([]);
  const [houseAccounts, setHouseAccounts] = useState([]);
  const [description, setDescription] = useState("");
  const [results, setResults] = useState([]);
  const [mode, setMode] = useState("gui");
  const [file, setFile] = useState();
  const [date, setDate] = useState(DateTime.now().toISODate());
  const [amount, setAmount] = useState("0");

  useEffect(() => {
    const refresh = async () => {
      try {
        const houseAccounts = accounts
          .filter((a) => a.type === "Casa")
          .sort((a, b) => a.name.localeCompare(b.name));

        const chargeAccounts = accounts
          .filter((a) => a.type === "Cargo" && !a.isSystem)
          .sort((a, b) => a.name.localeCompare(b.name));

        setHouseAccounts(houseAccounts);
        setChargeAccounts(chargeAccounts);
        if (chargeAccounts.length > 0) {
          const latest = chargeAccounts.reduce((l, e) =>
            e.transactionCount > l.transactionCount ? e : l
          );
          setChargeAccountId(latest._id);
        }
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };

    refresh();
  }, [accounts]);

  const handleCreateClicked = async () => {
    try {
      setIsWorking(true);
      setResults([]);
      let results;
      if (mode === "gui") {
        results =
          await userCtx.webserviceClient.colonyTransactions.insertChargesByGui(
            userCtx.user.colony._id,
            houseAccountIds,
            chargeAccountId,
            date,
            description,
            amount
          );
      } else if (mode === "archive") {
        results =
          await userCtx.webserviceClient.colonyTransactions.insertChargesByArchive(
            userCtx.user.colony._id,
            chargeAccountId,
            date,
            description,
            file
          );
      }

      messageCtx.showMessagesFromResults(results);
      setResults(results);
      setIsWorking(false);
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const isFormOk = () => {
    if (!chargeAccountId) return false;

    if (mode === "gui") {
      if (!description) return false;
      if (houseAccountIds.length === 0) return false;
      if (!validateUtil.number.isCurrency(amount, 2)) return false;
      if (parseFloat(amount) < 0) return false;
    } else if (mode === "archive") {
      if (!description) return false;
      if (!file) return false;
    }

    return true;
  };

  const lastEvent = arrayUtil.last(colony.events);
  const minDate = lastEvent ? lastEvent.date : "";

  const getColor = (r) => {
    switch (r.state) {
      case "error":
        return "table-danger";
      case "warning":
        return "table-warning";
      case "success":
        return "table-success";
      default:
        return "";
    }
  };

  return (
    <div>
      <div className="mb-2">
        <div className="form-label">Fecha</div>
        <DateInput
          max={DateTime.now().toISODate()}
          min={minDate}
          value={date}
          disabled={isWorking}
          onChange={setDate}
        />
      </div>

      <div className="mb-2">
        <div className="form-label">Cuenta de cargo</div>
        <SelectInput
          items={chargeAccounts}
          nameExtractor={(a) => a.name}
          valueExtractor={(a) => a._id}
          value={chargeAccountId}
          disabled={isWorking}
          onChange={setChargeAccountId}
        />
      </div>

      <div className="mb-2">
        <div className="form-label">Fuente</div>
        <SelectInput
          items={[
            { value: "gui", name: "Texto" },
            { value: "archive", name: "Archivo" },
          ]}
          nameExtractor={(a) => a.name}
          valueExtractor={(a) => a.value}
          value={mode}
          disabled={isWorking}
          onChange={setMode}
        />
      </div>

      {["archive"].includes(mode) && (
        <div className="mb-2">
          <div className="form-label">
            Archivo (<a href="/assets/Cargos.xlsx">Ejemplo</a>)
          </div>
          <FileInput
            value={file}
            onChange={setFile}
            disabled={isWorking}
            accept=".xlsx"
            showSeeImageButton={false}
            allowEmpty={false}
          />
        </div>
      )}

      {["gui"].includes(mode) && (
        <div className="mb-2">
          <div className="form-label">
            Cuenta de casas ({houseAccountIds.length} / {houseAccounts.length})
          </div>
          <MultiSelectInput
            items={houseAccounts}
            nameExtractor={(a) => a.name}
            valueExtractor={(a) => a._id}
            values={houseAccountIds}
            disabled={isWorking}
            onChange={setHouseAccountIds}
          />
        </div>
      )}

      <div className="mb-2">
        <div className="form-label">Concepto</div>

        <div className="input-group">
          <span className="input-group-text">Casa (</span>
          <TextInput
            value={description}
            onChange={setDescription}
            disabled={isWorking}
          />
          <span className="input-group-text">)</span>
        </div>
      </div>

      {["gui"].includes(mode) && (
        <div className="mb-2">
          <div className="form-label">Cantidad</div>
          <NumberInput
            value={amount}
            onChange={setAmount}
            disabled={isWorking}
          />
        </div>
      )}

      <div className="mb-2">
        <button
          className="btn btn-primary"
          onClick={handleCreateClicked}
          disabled={!isFormOk() || isWorking}
        >
          Crear
        </button>
      </div>
      {results.length > 0 && (
        <table className="table">
          <thead>
            <tr>
              <th>Id</th>
              <th>Mensaje</th>
            </tr>
          </thead>
          <tbody>
            {results.map((r) => (
              <tr key={r.id} className={getColor(r)}>
                <td>{r.id}</td>
                <td>{r.message}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default NewTransactionChargesControl;
