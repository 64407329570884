import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import validateUtil from "../../../../utils/validate.util";
import { useUser } from "../../../contexts/user.context";
import { useException } from "../../../contexts/exception.context";
import TextInput from "../../../controls/text.input";
import RadiosInput from "../../../controls/radios.input";
import ConfirmModal from "../../../controls/confirm.modal";
import { useMessage } from "../../../contexts/message.context";

const UserPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const userCtx = useUser();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [roles, setRoles] = useState([]);
  const [isWorking, setIsWorking] = useState(false);
  const [oldUser, setOldUser] = useState();
  const [newUser, setNewUser] = useState();
  const [
    showConfirmGeneratePasswordModal,
    setShowConfirmGeneratePasswordModal,
  ] = useState(false);

  const isReadonly = !userCtx.user.role.permissions.includes("users.edit");

  useEffect(() => {
    const refresh = async () => {
      const roles = await userCtx.webserviceClient.roles.getByColonyAndLevel(
        userCtx.user.colony._id,
        2
      );
      setRoles(roles);

      if (params.userId) {
        const user2 = await userCtx.webserviceClient.colonies.getUserById(
          userCtx.user.colony._id,
          params.userId
        );
        setOldUser(user2);
        setNewUser({ ...user2 });
      } else {
        setNewUser({ name: "", roleId: roles[0]._id, email: "" });
      }
    };
    refresh();
  }, [userCtx.user, params]);

  const handleConfirmGeneratePasswordClose = async (confirm) => {
    setShowConfirmGeneratePasswordModal(false);
    if (!confirm) return;

    // try {
    //   setIsWorking(true);
    //   await userCtx.webserviceClient.colonies.updateUserPassword(
    //     userCtx.user.colony._id,
    //     params.userId,
    //   );
    //   messageCtx.showSuccess(
    //     "Se generó una nueva contraseña y se envió por correo a los correos registrados"
    //   );
    //   setIsWorking(false);
    // } catch (ex) {
    //   setIsWorking(false);
    //   exceptionCtx.handleException(ex);
    // }
    try {
      await userCtx.webserviceClient.colonies.updateUserPassword(
        userCtx.user.colony._id,
        newUser._id
      );

      messageCtx.showSuccess(
        "Se generó una nueva contraseña y se envió por correo al correo registrado"
      );
      setIsWorking(false);
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const handleUpdateClicked = async () => {
    try {
      setIsWorking(true);

      await userCtx.webserviceClient.colonies.updateUser(
        userCtx.user.colony._id,
        newUser._id,
        newUser.name,
        newUser.email,
        newUser.roleId
      );
      messageCtx.showSuccess("Usario guardado");
      navigate("./..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const handleCreateClicked = async () => {
    try {
      setIsWorking(true);
      await userCtx.webserviceClient.colonies.insertUser(
        userCtx.user.colony._id,
        newUser.name,
        newUser.email,
        newUser.roleId
      );
      messageCtx.showSuccess(
        "Usuario guardado y los credenciales fueron enviados por correo"
      );
      navigate("./..");
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const handleConfirmDeleteModalClose = async (confirm) => {
    setShowConfirmDeleteModal(false);
    if (!confirm) return;

    try {
      setIsWorking(true);
      await userCtx.webserviceClient.colonies.deleteUser(
        userCtx.user.colony._id,
        newUser._id
      );
      messageCtx.showSuccess("Usuario borrado");
      if (newUser._id === userCtx.user._id) {
        // we deleted ourselves
        userCtx.logout();
        navigate("/");
      } else {
        navigate("./..");
      }
    } catch (ex) {
      setIsWorking(false);
      exceptionCtx.handleException(ex);
    }
  };

  const handleUpdatePasswordClicked = async () => {
    setShowConfirmGeneratePasswordModal(true);
  };

  const isFormOk = () => {
    if (!newUser.name) return false;
    if (!validateUtil.email.isValid(newUser.email)) return false;
    if (!newUser.roleId) return false;

    return true;
  };

  const hasFormChanged = () => {
    return JSON.stringify(oldUser) !== JSON.stringify(newUser);
  };

  if (!newUser) return null;

  return (
    <div>
      <h1>{oldUser ? "Usuario" : "Nueva usuario"}</h1>

      {/* {userCtx.user.role.permissions.includes("users.edit") && oldUser && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <button
            className="btn btn-primary"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            disabled={isWorking}
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </button>
          <ul
            className="dropdown-menu dropdown-menu-end"
            aria-labelledby="dropdownMenuButton1"
          >
            <li>
              <button
                className="dropdown-item"
                disabled={isWorking}
                onClick={handleUpdatePasswordClicked}
              >
                Mandar nueva contraseña
              </button>
            </li>
          </ul>
        </div>
      )} */}

      <div className="mb-2">
        <label className="form-label">Nombre</label>
        <TextInput
          value={newUser.name}
          disabled={isWorking}
          readonly={isReadonly}
          onChange={(value) => setNewUser({ ...newUser, name: value })}
        />
      </div>

      <div className="mb-2">
        <label className="form-label">Correo</label>
        <TextInput
          value={newUser.email}
          disabled={isWorking}
          readonly={isReadonly}
          onChange={(value) => setNewUser({ ...newUser, email: value })}
        />
      </div>

      <div className="mb-2">
        <label className="form-label">Rol</label>
        <RadiosInput
          disabled={isWorking}
          readonly={isReadonly}
          value={newUser.roleId}
          onChange={(value) => setNewUser({ ...newUser, roleId: value })}
          items={roles}
          valueExtractor={(i) => i._id}
          nameExtractor={(i) => i.name}
        />
      </div>

      {!isReadonly && (
        <div className="mb-2">
          {!oldUser && (
            <button
              className="btn btn-primary"
              onClick={handleCreateClicked}
              disabled={!isFormOk() || isWorking}
            >
              Crear
            </button>
          )}
          {oldUser && (
            <button
              className="btn btn-primary me-2"
              onClick={handleUpdateClicked}
              disabled={!isFormOk() || !hasFormChanged() || isWorking}
            >
              Guardar
            </button>
          )}
          {oldUser && (
            <button
              className="btn btn-danger me-2"
              disabled={isWorking}
              onClick={() => setShowConfirmDeleteModal(true)}
            >
              Borrar
            </button>
          )}
          {oldUser && (
            <button
              className="btn btn-primary me-2"
              disabled={isWorking || hasFormChanged()}
              onClick={handleUpdatePasswordClicked}
            >
              Mandar nueva contraseña
            </button>
          )}
        </div>
      )}
      <ConfirmModal
        cancelButtonText="No"
        confirmButtonText="Sí"
        titleText="Borrar usuario"
        questionText="Seguro que quieres borrar usuario?"
        show={showConfirmDeleteModal}
        onClose={handleConfirmDeleteModalClose}
      />

      <ConfirmModal
        cancelButtonText="Cerrar"
        confirmButtonText="Generar"
        questionText="Quieres enviar credenciales a los correos registrados?"
        titleText="Generar nueva contraseña"
        show={showConfirmGeneratePasswordModal}
        onClose={handleConfirmGeneratePasswordClose}
      />
    </div>
  );
};

export default UserPage;
