import * as React from "react";

import { useUser } from "../contexts/user.context";

import AppNav from "./app/nav";
import AuthNav from "./auth/nav";

const RootNav = () => {
  const userCtx = useUser();

  return userCtx.user ? <AppNav /> : <AuthNav />;
};

export default RootNav;
