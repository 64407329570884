import { useState, useEffect, useRef } from "react";

import logo from "../../../assets/LOGO3.svg";

import { useUser } from "../../contexts/user.context";
import { useException } from "../../contexts/exception.context";
import PasswordInput from "../../controls/password.input";
import TextInput from "../../controls/text.input";

const enterCodes = ["NumpadEnter", "Enter"];

const SigninPage = () => {
  const userCtx = useUser();
  const exceptionCtx = useException();

  const passwordRef = useRef();
  const emailRef = useRef();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //const [remember, setRemember] = useState(false);

  useEffect(() => {
    document.body.style.backgroundColor = "#508bfc";

    emailRef.current.focus();

    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, []);

  const handleLoginClicked = async () => {
    try {
      await userCtx.loginByCredentials(email.trim(), password.trim());
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  return (
    <div>
      <div style={{ position: "absolute", right: 5, top: 5, color: "white" }}>
        Ver: 1.1.2
      </div>
      <section className="vh-100" style={{ backgroundColor: "#508bfc" }}>
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div
                className="card shadow-2-strong"
                style={{ borderRadius: "1rem" }}
              >
                <div className="card-body p-5 text-center">
                  <img
                    src={logo}
                    alt=""
                    style={{ maxWidth: "70%", marginBottom: "20px" }}
                  />
                  <div className="mb-2">
                    <label className="form-label">Correo</label>
                    <TextInput
                      size="lg"
                      onKeyDown={(e) => {
                        if (!e.repeat) {
                          if (enterCodes.includes(e.code)) {
                            passwordRef.current.focus();
                          }
                        }
                      }}
                      value={email}
                      onChange={(value) => setEmail(value)}
                      ref={emailRef}
                    />
                  </div>
                  <div className="mb-2">
                    <label className="form-label">Contraseña</label>
                    <PasswordInput
                      ref={passwordRef}
                      size="lg"
                      value={password}
                      onKeyDown={(e) => {
                        if (!e.repeat) {
                          if (enterCodes.includes(e.code)) {
                            handleLoginClicked();
                          }
                        }
                      }}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  {/* <div className="form-check d-flex justify-content-start mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={remember}
                      onChange={(e) => setRemember(e.target.checked)}
                    />
                    <label className="form-check-label ms-1">Recuerdame</label>
                  </div> */}
                  <button
                    className="btn btn-primary btn-lg btn-block"
                    onClick={handleLoginClicked}
                  >
                    Entrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SigninPage;
