export default ({
  readonly,
  value,
  disabled,
  onChange,
  items,
  valueExtractor,
  nameExtractor,
}) => {
  if (readonly) {
    return (
      <label className="form-control-plaintext">
        {nameExtractor(items.find((i) => valueExtractor(i) === value))}
      </label>
    );
  } else {
    return (
      <div className="form-control">
        {items.map((i) => (
          <div className="form-check" key={valueExtractor(i)}>
            <input
              disabled={disabled}
              className="form-check-input"
              type="radio"
              checked={valueExtractor(i) === value}
              onChange={(e) => onChange(valueExtractor(i))}
            />
            <label className="form-check-label">{nameExtractor(i)}</label>
          </div>
        ))}
      </div>
    );
  }
};
